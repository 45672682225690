/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { IconButton, InputBase, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { searchFieldInputBase, searchFieldStyles } from './search-field-styles'
import * as React from 'react'

type Props = {
    defaultValue?: string
    label: string
    onTextChanged: (value: string) => any
    onSubmit?: () => void
    wait?: number
}

export const SearchField: React.FC<Props> = ({ onTextChanged, label, onSubmit, defaultValue, wait = 250 }) => {
    const [text, setText] = useState(defaultValue ?? '')
    const theme = useTheme()
    const [timeoutHandle, setTimeoutHandle] = useState<any>(null)
    const handleSetText = (t: string) => {
        setText(t)
        if (timeoutHandle) {
            clearTimeout(timeoutHandle)
            setTimeoutHandle(null)
        }
        setTimeoutHandle(
            setTimeout(() => {
                onTextChanged(t)
            }, wait),
        )
    }

    const handleKeyDown = (e: any) => {
        if (e.code === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

    return (
        <div css={searchFieldStyles.searchFieldContainer}>
            <InputBase
                sx={searchFieldInputBase}
                value={text}
                onChange={(e) => handleSetText(e.target.value)}
                placeholder={label}
                onKeyDown={handleKeyDown}
                inputProps={{ 'aria-label': label }}
            />
            <IconButton
                sx={{
                    p: '10px',
                    backgroundColor: theme.palette.accent.main,
                    color: theme.palette.common.white,
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                    borderBottomLeftRadius: '0',
                    borderTopLeftRadius: '0',
                    boxShadow: ' 0 0 2px 0 rgba(0, 0, 0, 0.25)',
                    '&:hover': {
                        backgroundColor: theme.palette.accent.dark,
                    },
                }}
                onClick={onSubmit}
                type="button"
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
        </div>
    )
}
