import { appApi } from '../../redux/api'
import { CompanyUserWithPid } from '../users/types/company-user'
import { PaginationParams } from '../general/models/pagination-params'
import { RealtorUsersFilters } from '../ine-admin-page/realtors-users/realtor-users-filters'
import { OrderFilters } from '../orders/order.slice'
import { Order } from '../orders/models/order'
const downloadFile = async (response: Response, fileName: string) => {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    link.click()
}
const exportsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getRealtorListExport: builder.query<void, PaginationParams<CompanyUserWithPid, RealtorUsersFilters>>({
            query: (params) => ({
                url: `/v1/exports/realtors`,
                method: 'GET',
                responseHandler: async (response) => {
                    await downloadFile(response, 'realtors.csv')
                },
                cache: 'no-cache',
                params: params,
            }),
            providesTags: [],
        }),
        getOrdersExport: builder.query<void, PaginationParams<Order, OrderFilters>>({
            query: (params) => ({
                url: `/v1/exports/orders`,
                method: 'GET',
                responseHandler: async (response) => {
                    await downloadFile(response, 'orders.csv')
                },
                cache: 'no-cache',
                params: params,
            }),
            providesTags: [],
        }),
    }),
})

export const { useLazyGetRealtorListExportQuery, useLazyGetOrdersExportQuery } = exportsApi
