/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Button from '@mui/material/Button'
import { Edit } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { CompanyForm } from '../../companies/company-form'
import { dispatchSubmit } from '../../../utils/forms-utils'
import { BaseCompany, Company } from '../../companies/models/company'
import { css } from '@emotion/react'
import { useUpdateCompanyMutation } from '../../../redux/apis/companies-api'
import { QueryStatusView } from '../../general/query-status.view'
import StyledContainedButton from '../../styled/styled-contained-button'

type EditCompanyProps = {
    company: Company
}
const styles = {
    editButton: css`
        padding: 16px;
    `,
}
export const EDIT_COMPANY_FORM_ID = 'EDIT_COMPANY'
export const CompanyEdit: React.FC<EditCompanyProps> = ({ company }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [create, { isLoading, isError, error }] = useUpdateCompanyMutation()
    const onSubmit = (values: any) => {
        if (!isLoading) {
            create({
                id: company.id,
                update: values as BaseCompany,
            })
                .unwrap()
                .then(() => setOpen(false))
        }
    }

    return (
        <>
            <div css={styles.editButton}>
                <StyledContainedButton
                    startIcon={<Edit />}
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => setOpen(true)}
                >
                    {t('TEXT_EDIT', 'Rediger')}
                </StyledContainedButton>
            </div>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{t('TEXT_EDIT_COMPANY', 'Edit company')}</DialogTitle>
                <DialogContent>
                    <CompanyForm id={EDIT_COMPANY_FORM_ID} onSubmit={onSubmit} initialValues={company} />
                    <QueryStatusView error={error} isError={isError} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        disabled={isLoading}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(EDIT_COMPANY_FORM_ID)}
                    >
                        {t('TEXT_SAVE', 'LAGRE')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
