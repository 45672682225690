import { PropertyProductViewPropsBase } from './ProductDisplay'
import React from 'react'
import { ProductMainInfo } from './common/ProductMainInfo'
import { Collapse } from '@mui/material'
import { ProductSecondaryInfo } from './common/ProductSecondaryInfo'
import { ProductContainer } from './common/shared'

export const FireProduct: React.FC<PropertyProductViewPropsBase> = ({
    product,
    onCartButtonClick,
    isInCart,
    isLoading,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false)

    return (
        <ProductContainer className="product-container">
            <ProductMainInfo
                onCartButtonClick={() => onCartButtonClick()}
                product={product}
                isExpanded={isExpanded}
                onToggleExpand={() => setIsExpanded((prev) => !prev)}
                isInCart={isInCart}
                isCartButtonDisabled={isLoading}
            />

            <Collapse in={isExpanded}>
                <ProductSecondaryInfo product={product} />
            </Collapse>
        </ProductContainer>
    )
}
