import { StyledComponentProps, Typography, styled } from '@mui/material'
import FONT from '../../app/material-ui/font-families'

interface StyledTypographyProps extends StyledComponentProps {
    bold?: boolean
    component?: React.ElementType
    fontFamily?: keyof typeof FONT | undefined
}
const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'bold',
})<StyledTypographyProps>(({ theme, variant, bold = false, fontFamily = undefined }) => ({
    fontWeight: bold ? theme.typography.fontWeightBold : undefined,
    fontFamily: fontFamily
        ? FONT[fontFamily]
        : variant && variant !== 'inherit'
        ? theme.typography[variant].fontFamily
        : theme.typography.fontFamily,
}))

export default StyledTypography
