import React from 'react'
import { Box, Grid } from '@mui/material'
import { PropertyResponseItem, PropertyType } from 'components/search/models/property-search-response'
import { getAllOwners, getMainProperty, getMainPropertyCadastreNumber, isMainProperty } from 'utils/cadastre-utils'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import { SectionedParentPropertyUnitTable } from './SectionedParentPropertyUnitTable'
import { useTranslation } from 'react-i18next'

type SectionedPropertyParentViewProps = {
    units: PropertyResponseItem[]
    type: PropertyType
}

export const SectionedPropertyParentView: React.FC<SectionedPropertyParentViewProps> = ({ units, type }) => {
    const { t } = useTranslation()

    const mainProperty = getMainProperty(units) || units[0]
    const sanitizedMainPropertyCadastreNr = getMainPropertyCadastreNumber(mainProperty?.cadastreNumber || null)
    const owners = getAllOwners(units)
    const filteredUnits = units.filter((u) => !isMainProperty(u.cadastreNumber))

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={units}
                    isPropertySectioned={true}
                    sanitizedMainPropertyCadastreNr={sanitizedMainPropertyCadastreNr}
                    mainProperty={mainProperty}
                    allOwners={owners}
                />
                <Box mt={2}>
                    <SectionedParentPropertyUnitTable
                        units={filteredUnits}
                        title={t('SECTIONS', 'Seksjoner ({{huCount}})', {
                            huCount: units.filter((u) => !isMainProperty(u.cadastreNumber)).length,
                        })}
                        type={type}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}></Grid>
        </>
    )
}
