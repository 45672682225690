import { Checkbox, FormControlLabel, Grid, LinearProgress, Pagination, Paper, Typography, styled } from '@mui/material'
import { CreateEditNotificationDialog } from 'components/general/userNotifications/CreateEditNotificationDialog'
import { NotificationsList } from 'components/general/userNotifications/NotificationsList'
import { useGetNotificationsQuery } from 'components/general/userNotifications/userNotifications.service'
import StyledContainedButton from 'components/styled/styled-contained-button'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ITEMS_PER_PAGE = 10

export const CubitAdminNotificationsView: React.FC = () => {
    const { t } = useTranslation()
    const [isCreateNotificationDialogOpen, setIsCreateNotificationDialogOpen] = React.useState(false)
    const [showActiveOnly, setShowActiveOnly] = React.useState(true)
    const [activePage, setActivePage] = React.useState(1)

    const {
        data: notifications,
        isFetching,
        isLoading,
        isSuccess,
    } = useGetNotificationsQuery({
        activeOnly: showActiveOnly,
        page: activePage - 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sortBy: 'updated',
        direction: 'desc',
    })

    const handleActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveOnly(event.target.checked)
        setActivePage(1)
    }

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StyledContainedButton
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() => setIsCreateNotificationDialogOpen(true)}
                        >
                            {t('ADMIN_NOTIFICATION_CREATE_BTN', 'Legg til ny statusmelding')}
                        </StyledContainedButton>
                    </Grid>

                    <Grid item xs={12}>
                        {isFetching && <LinearProgress />}
                        <StyledPaper elevation={0}>
                            <NotificationHeader>
                                <Typography variant="h6">
                                    {t('ADMIN_NOTIFICATION_TITLE', 'Aktive statusmeldinger')}
                                </Typography>
                            </NotificationHeader>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked onChange={handleActiveOnlyChange} />}
                                    label={t('ADMIN_NOTIFICATIONS_ACTIVE_CHECKBOX', 'Show active only')}
                                />
                            </div>

                            {!isLoading && isSuccess && (
                                <>
                                    <NotificationsList notifications={notifications.data} count={notifications.total} />
                                    <Pagination
                                        count={Math.ceil(notifications.total / ITEMS_PER_PAGE)}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={(e, page) => setActivePage(page)}
                                        page={activePage}
                                    />
                                </>
                            )}
                        </StyledPaper>
                    </Grid>
                </Grid>
            </div>
            <CreateEditNotificationDialog
                isOpen={isCreateNotificationDialogOpen}
                onClose={() => setIsCreateNotificationDialogOpen(false)}
            />
        </>
    )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2, 4, 6, 4),
}))

const NotificationHeader = styled('div')({
    display: 'flex',
})
