import { Grid } from '@mui/material'
import { RealtorReportTable } from '../realtors-report/realtor-report-table'
import { useState } from 'react'
import dayjs from 'dayjs'
import { OrderFilters } from '../../orders/order.slice'
import { RealtorReportFilters } from '../realtors-report/realtor-report-filters'

export const RealtorReportView: React.FC = () => {
    const [filters, setFilters] = useState<OrderFilters>({})
    const onSubmit = (values: any) => {
        setFilters({
            dateFrom: values.from ? dayjs(values.from).toISOString() : undefined,
            dateTo: values.to ? dayjs(values.to).add(1, 'day').toISOString() : undefined,
            company: values.company ? values.company.id : undefined,
            q: values.q,
        })
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RealtorReportFilters onSubmit={onSubmit} filters={filters} />
                </Grid>
                <Grid item xs={12}>
                    <RealtorReportTable filters={filters} />
                </Grid>
            </Grid>
        </div>
    )
}
