import { Button, Grid, Stack } from '@mui/material'
import { Config } from '../../redux/config'
import { FakeLoginView } from '../auth/fake-login'
import { TopBar } from '../topbar/top-bar'
import { LoginButton } from './LoginButton'
import { styled } from '@mui/system'

export const LoginPage: React.FC = () => {
    return (
        <>
            <TopBar rightActions={[<LoginButton key={'login'} />]} />
            <Container container spacing={2}>
                <ActionContainer item xs={12}>
                    <LoginHeader>Logg inn </LoginHeader>
                </ActionContainer>
                <ActionContainer item xs={12}>
                    <Button
                        onClick={() => {
                            window.location.replace(`${Config.backendUrl}/auth/login`)
                        }}
                    >
                        ID PORTEN
                    </Button>
                </ActionContainer>
                <ActionContainer item xs={12} border="1px solid red">
                    <Stack>
                        <p>force login with any pid</p>
                        <p>
                            <FakeLoginView />
                        </p>
                    </Stack>
                </ActionContainer>
            </Container>
        </>
    )
}

const Container = styled(Grid)({
    maxWidth: '1124px',
    margin: 'auto',
})

const LoginHeader = styled('div')(({ theme }) => ({
    maxWidth: '250px',
    width: '250px',
    paddingBottom: theme.spacing(3),
    borderBottom: '1px solid #000',
}))

const ActionContainer = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    margin: '106px 0 0 0',
    fontSize: '1.25rem',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
})
