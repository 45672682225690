/** @jsxImportSource @emotion/react */
import { FileCopy, Search, Settings, ShoppingBasket } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    ROUTE_ADMIN,
    ROUTE_CART,
    ROUTE_COMPANY_ADMIN,
    ROUTE_INE_ADMIN,
    ROUTE_ORDERS,
    ROUTE_SEARCH,
} from '../../utils/routes'
import { useAuthContextRole, useIsAdmin, useIsCompanyAdmin, useIsOsloGuy } from '../auth/auth.slice'
import { useGetCartQuery } from '../cart/cart.service'
import { KotiButton1 } from '../general/buttons/koti-button-1'
import { topNavigationStyles } from './top-bar-styles'
import { Role } from '../auth/role'
import { useMediaQuery, useTheme } from '@mui/material'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'

export const TopNavigation: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()
    const companyAccess = useIsCompanyAdmin()
    const role = useAuthContextRole()
    const theme = useTheme()
    const isOsloGuy = useIsOsloGuy()
    const cart = useGetCartQuery()
    const isMobile = useMediaQuery(getMobileMediaQuery())
    const color = isMobile ? theme.palette.primary.main : ''
    if (role === Role.User) {
        return null
    }
    return (
        <div css={topNavigationStyles.topNavigation}>
            <div css={topNavigationStyles.item}>
                <KotiButton1
                    startIcon={<Search />}
                    onClick={() => navigate(ROUTE_SEARCH)}
                    text={t('TEXT_SEARCH', 'Søk')}
                    color={color}
                />
            </div>

            {!isAdmin && !isOsloGuy && (
                <div css={topNavigationStyles.item}>
                    <KotiButton1
                        startIcon={<FileCopy />}
                        onClick={() => navigate(ROUTE_ORDERS)}
                        text={t('TEXT_ORDERS', 'Bestillinger')}
                        color={color}
                    />
                </div>
            )}
            {!isOsloGuy && (
                <div css={topNavigationStyles.item}>
                    <KotiButton1
                        startIcon={<ShoppingBasket />}
                        onClick={() => navigate(ROUTE_CART)}
                        text={t('TEXT_CART_WITH_COUNT', 'Handlekurv ({{itemsCount}})', {
                            itemsCount: cart.data ? cart.data.items.length : 0,
                        })}
                        color={color}
                    />
                </div>
            )}

            {isAdmin && (
                <div css={topNavigationStyles.item}>
                    <KotiButton1
                        startIcon={<Settings />}
                        onClick={() => navigate(ROUTE_ADMIN)}
                        text={t('TEXT_ADMIN', 'Admin')}
                        color={color}
                    />
                </div>
            )}
            {role === Role.OsloUser && (
                <div css={topNavigationStyles.item}>
                    <KotiButton1
                        startIcon={<Settings />}
                        onClick={() => navigate(ROUTE_INE_ADMIN)}
                        text={t('TEXT_ADMIN', 'Admin')}
                        color={color}
                    />
                </div>
            )}
            {companyAccess && (
                <div css={topNavigationStyles.item}>
                    <KotiButton1
                        startIcon={<Settings />}
                        onClick={() => navigate(ROUTE_COMPANY_ADMIN)}
                        text={t('TEXT_ADMIN', 'Admin')}
                        color={color}
                    />
                </div>
            )}
        </div>
    )
}
