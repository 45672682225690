import { UserStorageNotification } from 'components/general/userNotifications/notification.types'

export const getStoredNotifications = (): UserStorageNotification[] | null => {
    const value = localStorage.getItem('notifications')

    return value ? JSON.parse(value) : null
}

export const setStoredNotifications = (value: UserStorageNotification[] | null) => {
    const stringifiedNotifications = value ? JSON.stringify(value) : ''
    localStorage.setItem('notifications', stringifiedNotifications)
}
