import { BaseEntity } from '../general/models/base-entity'
import { ProductPlant } from './models/plants'

export enum CubitPriceType {
    Flat = 'flat',
    Percentage = 'percentage',
}

export const ProductTypes = {
    Taxes: 'Taxes',
    Fire: 'Fire',
    Electricity: 'Electricity',
} as const
export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes]

export type ProductBase = {
    name: string
    description: string
    vendorId: string
    vendorName: string
    tenantId: string
    tenantName: string
    price: number
    vat: number
    disclaimer?: string
    type: ProductType
} & BaseEntity

export type Product = {
    basePrice: number
    cubitPrice: number
    cubitPriceType: CubitPriceType
} & ProductBase

export type ProductWithAttributes = {
    attributes: ProductAttribute[]
    electricityAttributes: ProductPlant[] | null
} & ProductBase

export type ProductAttribute = {
    matrikeklNr: string
    invoiceReceivers: InvoiceReceiver[]
}

export type InvoiceReceiver = {
    name: string
    partNumber: number
    agreementNumber: number
}
