/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from '@mui/material'
import { navigateToLogin } from '../auth/auth.service'
export const LoginButton: React.FC = () => {
    const styles = {
        loginBtn: css`
            display: flex;
            justify-self: flex-end;
            background-color: #ffddc3;
            box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
            padding: 8px 20px;
            max-height: 35px;
            min-width: 95px;
        `,
    }

    return (
        <Button onClick={() => navigateToLogin(`/auth`)} css={styles.loginBtn}>
            Logg inn
        </Button>
    )
}
