/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material'
import { changeLanguage, getLang } from '../../translation/translation-helper'
import { Flag } from '../general/flags/flags'
import React, { useState } from 'react'
import { css } from '@emotion/react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
export const LanguagePicker: React.FC = () => {
    const [currentLang, setCurrentLang] = useState(getLang())
    const theme = useTheme()
    const isMobile = useMediaQuery(getMobileMediaQuery())
    const color = isMobile ? theme.palette.primary.main : theme.palette.primary.contrastText
    const styles = {
        languageLine: css`
            display: flex;
            align-items: center;
        `,
        languageLabel: css`
            padding-left: 10px;
        `,
    }
    return (
        <div>
            <FormControl
                sx={{
                    '& .MuiInputBase-root': {
                        marginTop: 0,
                    },
                }}
                aria-label={' Select Language'}
            >
                <InputLabel style={{ display: 'none', height: 0 }} id="select-language-label">
                    Select Language
                </InputLabel>
                <Select
                    sx={{
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            color: color,
                            pl: theme.spacing(0.5),
                        },
                        '& .MuiSelect-icon': {
                            color: color,
                        },
                    }}
                    labelId="select-language-label"
                    value={currentLang}
                    disableUnderline={true}
                    IconComponent={KeyboardArrowDown}
                    renderValue={(value) => {
                        return <Flag country={value} />
                    }}
                    variant={'standard'}
                    onChange={(event) => {
                        changeLanguage(event.target.value)
                        setCurrentLang(event.target.value)
                    }}
                    displayEmpty
                    name="language"
                    title="language"
                >
                    <MenuItem aria-label="Velg Norsk" key={'nb'} value="nb">
                        <div css={styles.languageLine}>
                            <Flag country={'nb'} /> <span css={styles.languageLabel}>Norsk</span>
                        </div>
                    </MenuItem>
                    <MenuItem aria-label="Select English" key={'en'} value="en">
                        <div css={styles.languageLine}>
                            <Flag country={'en'} /> <span css={styles.languageLabel}>English</span>
                        </div>
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}
