import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const DataGridNoRows: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Box width="100%" display="flex" height="100%" justifyContent="center" alignItems="center">
            <Typography variant="body1">{t('TABLE_NO_ROWS_TEXT', 'No results found')}</Typography>
        </Box>
    )
}
