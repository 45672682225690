import { TFunction } from 'i18next'
import { User, UserStatus } from './user'
import { CompanyUserRole } from '../companies/models/company'
import { AuthContext } from '../auth/auth-context.type'
import { Nullable } from '../../utils/types/nullable.type'
import { Role } from '../auth/role'

export const getCurrentContext = (user: User): AuthContext => {
    return user.authContext
}

export const canUserChangeStatus = (user: User): boolean => {
    return user.status === UserStatus.Active || user.status === UserStatus.Inactive
}
export const getCompanyName = (user: User, companyId: Nullable<string>) => {
    const company = user.companies.find((c) => c.companyId === companyId)
    if (!company) return '-'
    return company.companyName ?? company.companyId
}
export const getContextName = (context: AuthContext, user: User) => {
    if (!context) {
        return ''
    }
    if (context.role === Role.OsloUser) {
        return `${user.name ?? user.pid} (Innkrevingsetaten Oslo kommune)`
    }
    if (context.role === Role.OsloReadOnly) {
        return `${user.name ?? user.pid} (Oslo kommune innsynsbruker)`
    }
    if (context.role === Role.OsloReadOnlyLimited) {
        return `${user.name ?? user.pid} (Oslo kommune __INE__)`
    }
    if (!user.name) {
        return user.pid
    }
    switch (context.role) {
        case Role.User:
            return user.name
        case Role.CubitAdmin:
            return user.name + ' (Cubit Admin)'
        case Role.RealtorAdmin:
        case Role.RealtorUser:
            return user.name + ` (${getCompanyName(user, context.entityId)})`
    }
}
export const getStatusText = (t: TFunction, status: UserStatus): string => {
    switch (status) {
        case UserStatus.Active:
            return t('TEXT_ACTIVE', 'Aktiv')
        case UserStatus.Inactive:
            return t('TEXT_INACTIVE', 'Inaktiv')
        case UserStatus.Pending:
            return t('TEXT_PENDING', 'Pending')
        case UserStatus.WaitingForInvite:
            return t('TEXT_NOT_INVITED', 'Ikke invitert')
        default:
            return t('TEXT_UNKNOWN', 'Unknown')
    }
}

export const getUserTypeText = (t: TFunction, type?: CompanyUserRole): string => {
    switch (type) {
        case CompanyUserRole.User:
            return t('REALTOR_COMPANY_USER', 'Megler')
        case CompanyUserRole.Admin:
            return t('REALTOR_COMPANY_ADMIN', 'Faglig ansvarlig')
        default:
            return t('TEXT_UNKNOWN', 'Unknown')
    }
}

export const getStatusOptions = (t: TFunction) => {
    return [
        { label: getStatusText(t, UserStatus.Active), value: UserStatus.Active },
        { label: getStatusText(t, UserStatus.Inactive), value: UserStatus.Inactive },
    ]
}

export const getUserTypeOptions = (t: TFunction) => {
    return [
        { label: getUserTypeText(t, CompanyUserRole.User), value: CompanyUserRole.User },
        { label: getUserTypeText(t, CompanyUserRole.Admin), value: CompanyUserRole.Admin },
    ]
}
