import { LinearProgress, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAuthRedirect } from '../../utils/routes'
import { TopBar } from '../topbar/top-bar'
import { navigateToLogin, useLoginMutation, usePasswordlessLoginMutation } from './auth.service'
import { authActions } from './auth.slice'
import { styled } from '@mui/system'

export const AuthPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const token = searchParams.get('token')
    const userId = searchParams.get('userId')
    const secretKey = searchParams.get('secretKey')
    const userSecretKey = searchParams.get('userSecretKey')
    const redirect = searchParams.get('redirect')

    const [login, { isError, isLoading }] = useLoginMutation()
    const [passwordlessLogin, { isError: isPasswordlessError, isLoading: isLoading2 }] = usePasswordlessLoginMutation()

    useEffect(() => {
        if (secretKey != null) {
            navigateToLogin(`/auth-company?secretKey=${secretKey}`)
            return
        }
        if (userSecretKey != null) {
            navigateToLogin(`/auth-company?userSecretKey=${userSecretKey}`)
            return
        }
        if (token != null) {
            if (userId == null) {
                login({ accessToken: token })
                    .unwrap()
                    .then((response) => {
                        dispatch(authActions.setCredentials(response))
                        navigate(getAuthRedirect(response.authContext.role, redirect))
                    })
            } else {
                passwordlessLogin({ userId, token })
                    .unwrap()
                    .then((response) => {
                        dispatch(authActions.setCredentials(response))
                        navigate(getAuthRedirect(response.authContext.role, redirect))
                    })
            }
        }
    }, [navigate, userId, token, login, dispatch, passwordlessLogin, secretKey, redirect, userSecretKey])

    return (
        <div>
            <TopBar />
            <Wrapper>
                {isLoading || isLoading2 ? (
                    <ProgressContainer>
                        <span>{t('TEXT_LOGGING_IN', 'Logger deg inn...')}</span>
                        <LinearProgress color="inherit" />
                    </ProgressContainer>
                ) : null}
                {isError || isPasswordlessError ? (
                    <Typography>{t('TEXT_LOGGING_IN_FAILED', 'Login failed')}</Typography>
                ) : null}
            </Wrapper>
        </div>
    )
}

const Wrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
})

const ProgressContainer = styled('div')({
    maxWidth: 500,
    textAlign: 'center',
    fontSize: '1.25rem',
    width: '100%',
})
