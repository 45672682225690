import { useTranslation } from 'react-i18next'
import { ProductContainer } from './common/shared'
import { Typography } from '@mui/material'

export const OsloTaxesProductNoData: React.FC = () => {
    const { t } = useTranslation()

    return (
        <ProductContainer className="product-container">
            <Typography variant="h6" textAlign="center" sx={(theme) => ({ color: theme.palette.error.light })}>
                {t('INE_PRODUCTS_FAILED_FETCH', 'Failed to load ine product')}
            </Typography>
        </ProductContainer>
    )
}
