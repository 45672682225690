/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ArrowDropDown, Person } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux/hooks'
import { User } from '../users/user'
import { getContextName } from '../users/user-helper'
import { useGetProfileQuery, useUpdateUserContextMutation } from '../users/users.service'
import { AuthContext, ContextType } from './auth-context.type'
import { selectId } from './auth.slice'
import { Role } from './role'
import StyledTypography from '../styled/styled-typography'

const getStyles = (theme: Theme, primary = false) => ({
    switcher: css`
        color: ${primary ? theme.palette.primary.main : theme.palette.primary.contrastText};
        display: flex;
        align-items: center;
        cursor: pointer;
    `,
    icon: css`
        margin-right: 10px;
        color: ${primary ? theme.palette.primary.main : theme.palette.primary.contrastText};
    `,
    triangle: css`
        margin-left: 10px;
        color: ${primary ? theme.palette.primary.main : theme.palette.primary.contrastText};
    `,
})
type DialogProps = {
    user: User
    handleClose: any
    open: boolean
}
type AuthContextSwitcherProps = {
    primary?: boolean
}
const getContexts = (user: User, roles: Role[] | null) => {
    const companiesContexts: AuthContext[] = user.companies.map((c) => {
        return {
            type: ContextType.Company,
            entityId: c.companyId,
            role: c.role === 'admin' ? Role.RealtorAdmin : Role.RealtorUser,
        }
    })
    let contexts = [...companiesContexts]
    if (roles?.includes(Role.CubitAdmin)) {
        contexts = [
            ...contexts,
            {
                type: ContextType.User,
                entityId: user.id,
                role: Role.CubitAdmin,
            },
        ]
    }
    if (roles?.includes(Role.User)) {
        const userContext: AuthContext = {
            type: ContextType.User,
            entityId: user.id,
            role: Role.User,
        }
        contexts = [...contexts, userContext]
    }
    return contexts
}
const ContextDialog: React.FC<DialogProps> = ({ user, handleClose, open }) => {
    const { t } = useTranslation()
    const roles = useAppSelector((state) => state.auth.roles)
    const contexts = getContexts(user, roles)
    const [update] = useUpdateUserContextMutation()
    const handleListItemClick = (context: AuthContext) => {
        update({ id: user.id, update: context })
            .unwrap()
            .then(() => {
                handleClose()
            })
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t('TEXT_SELECT_CONTEXT', 'Select context')}</DialogTitle>
            <DialogContent>
                <List sx={{ pt: 0 }}>
                    {contexts
                        .filter((c) => c.role !== Role.User)
                        .map((context) => (
                            <ListItem
                                sx={{ fontFamily: 'Tomato Grotesk' }}
                                button
                                onClick={() => handleListItemClick(context)}
                                key={context.entityId}
                            >
                                <ListItemText primary={getContextName(context, user)} />
                            </ListItem>
                        ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}
export const AuthContextSwitcher: React.FC<AuthContextSwitcherProps> = ({ primary }) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const styles = getStyles(theme, primary)
    const userId = useAppSelector(selectId)
    const { data } = useGetProfileQuery(userId, {
        skip: userId === null,
    })
    const roles = useAppSelector((state) => state.auth.roles)
    if (!data) return null
    const contexts = getContexts(data, roles)

    return (
        <>
            {getContextName(data.authContext, data) && (
                <div css={styles.switcher} onClick={() => (contexts.length > 1 ? setOpen(true) : null)}>
                    <Person htmlColor={theme.palette.primary.contrastText} css={styles.icon} />
                    <StyledTypography fontFamily={'TOMATO'}>{getContextName(data.authContext, data)}</StyledTypography>
                    {contexts.length > 1 && (
                        <ArrowDropDown css={styles.triangle} htmlColor={theme.palette.primary.contrastText} />
                    )}
                </div>
            )}
            {open && <ContextDialog user={data} handleClose={() => setOpen(false)} open={open} />}
        </>
    )
}
