// @ts-ignore
import proj4 from 'proj4'
import { LatLong } from './lat-long.type'

export const PROJ4_PROJECTION_32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs'
export const PROJ4_PROJECTION_33 = '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs'

export const convertFromZone32to33 = (lat: number, long: number): LatLong => {
    const convertedCoords = proj4(PROJ4_PROJECTION_32, PROJ4_PROJECTION_33, [lat, long])
    return { long: convertedCoords[0], lat: convertedCoords[1] }
}
