export const PropertyTypes = {
    MainProperty: 'MainProperty',
    HousingCommunity: 'HousingCommunity',
    SectionedProperty: 'SectionedProperty',
    LeasedProperty: 'LeasedProperty',
    LeasedSectionedProperty: 'LeasedSectionedProperty',
} as const

export type PropertyType = (typeof PropertyTypes)[keyof typeof PropertyTypes]

export type Owner = {
    name: string
}

export type PropertyUnitAddress = {
    addressText: string | null
    postalCode: string | null
    postalCodeName: string | null
    residentialNumber: string | null
}
export type PropertyUnit = {
    cadastreId: number
    address: PropertyUnitAddress
    buildingCode: number
    buildingCodeName: string
    owners: Owner[]
    buildingNumber: number
}

export type PropertyResponseItem = {
    coordinateCRS?: string
    coordinateX?: number
    coordinateY?: number
    cadastreNumber: string
    housingUnits: PropertyUnit[]
    isCooperative?: boolean
    owners: Owner[]
}

export type PropertyResponse = {
    type: PropertyType
    properties: PropertyResponseItem[]
}

// property which is passed to product display to render product view and add to cart
export type CartProperty = {
    cadastreNumber: string
    cadastreId?: number
}
