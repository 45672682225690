import { appApi } from '../../redux/api'
import { PaginatedResults } from '../general/models/paginated-results'
import { PaginationParams } from '../general/models/pagination-params'
import { Order } from './models/order'
import { OrderFilters } from './order.slice'
import { OrderReportItem } from './models/order-report-item.type'
import { OrderStats } from './models/order-stats.type'

const cartsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query<PaginatedResults<Order>, PaginationParams<Order, OrderFilters>>({
            query: (params) => ({
                url: '/v1/orders',
                method: 'GET',
                params: params,
            }),

            providesTags: ['Orders'],
        }),
        regenerateOrder: builder.mutation<Order, { id: string }>({
            query: (params) => ({
                url: `/v1/orders/regenerate-order/${params.id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Orders'],
        }),
        getCompanyOrders: builder.query<
            PaginatedResults<Order>,
            { pagination: PaginationParams<Order, OrderFilters>; companyId: string }
        >({
            query: (params) => ({
                url: `/v1/orders/company/${params.companyId}`,
                method: 'GET',
                params: params.pagination,
            }),
            providesTags: ['Orders'],
        }),
        getAllOrders: builder.query<PaginatedResults<OrderReportItem>, PaginationParams<Order, OrderFilters>>({
            query: (params) => ({
                url: `/v1/orders/all`,
                method: 'GET',
                params: params,
            }),
            providesTags: ['Orders'],
        }),
        getOrderStats: builder.query<OrderStats, PaginationParams<Order, OrderFilters>>({
            query: (params) => ({
                url: `/v1/orders/orders-stats`,
                method: 'GET',
                params: params,
            }),
            providesTags: ['Orders'],
        }),
        getOrder: builder.query<Order, string>({
            query: (params) => ({
                url: `/v1/orders/${params}`,
                method: 'GET',
            }),
            providesTags: ['Orders'],
        }),
        changeOrderExemptionStatus: builder.mutation<Order, { id: string; exempted: boolean }>({
            query: (payload) => ({
                url: 'v1/orders/exempt',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result) => (result === null ? ['Orders'] : []),
        }),
    }),
})

export const {
    useGetOrdersQuery,
    useLazyGetOrderQuery,
    useLazyGetOrdersQuery,
    useRegenerateOrderMutation,
    useLazyGetCompanyOrdersQuery,
    useLazyGetAllOrdersQuery,
    useGetOrderQuery,
    useGetOrderStatsQuery,
    useChangeOrderExemptionStatusMutation,
} = cartsApi
