import { appApi } from 'redux/api'
import {
    CreateNotificationRequest,
    EditNotificationRequest,
    GetNotificationsRequest,
    UserNotification,
} from './notification.types'
import { PaginatedResults } from '../models/paginated-results'

const userNotificationsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        createNotification: builder.mutation<UserNotification, CreateNotificationRequest>({
            query: (payload) => ({
                url: '/v1/EventNotifications',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (!result ? [] : ['EventNotifications']),
        }),
        editNotification: builder.mutation<UserNotification, EditNotificationRequest>({
            query: (payload) => ({
                url: '/v1/EventNotifications',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result) => (!result ? [] : ['EventNotifications']),
        }),
        deleteNotification: builder.mutation<void, { id: string }>({
            query: (payload) => ({
                url: `/v1/EventNotifications/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['EventNotifications'] : []),
        }),
        // Should this be here or should this be in entirely different service?
        getNotifications: builder.query<PaginatedResults<UserNotification>, GetNotificationsRequest>({
            query: (payload) => ({
                url: '/v1/EventNotifications',
                method: 'GET',
                params: payload,
            }),
            providesTags: ['EventNotifications'],
        }),
        getNotification: builder.query<UserNotification, { id: string }>({
            query: (payload) => ({
                url: `/v1/EventNotifications/${payload.id}`,
                method: 'GET',
            }),
            providesTags: ['EventNotifications'],
        }),
    }),
})

export const {
    useCreateNotificationMutation,
    useEditNotificationMutation,
    useDeleteNotificationMutation,
    useGetNotificationQuery,
    useGetNotificationsQuery,
} = userNotificationsApi
