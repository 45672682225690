/** @jsxImportSource @emotion/react */
import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'

const CubitCheckboxAdapter: React.FC<any> = (props: any) => {
    const { label, ...rest } = props

    return (
        <FormControl css={cubitFormStyles.field}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.input.value === '1' || props.input.value === true}
                            {...props.input}
                            {...rest}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </FormControl>
    )
}
export { CubitCheckboxAdapter }
