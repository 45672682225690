/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Close } from '@mui/icons-material'
import { AppBar, Button, IconButton, Theme, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { Nullable } from '../../utils/types/nullable.type'
import { AuthContextSwitcher } from '../auth/auth-context-switcher'
import { LoginButton } from '../login/LoginButton'
import { LogoutButton } from '../login/logout-button'
import { LanguagePicker } from './language-picker'
import { MobileNavigationDrawer } from './mobile-navigation-drawer'
import { TopNavigation } from './top-navigation'
import StyledTypography from '../styled/styled-typography'

type Props = {
    rightActions?: any
    secondary?: SecondaryProps
}

type SecondaryProps = {
    onCancel?: () => void
    secondaryText: Nullable<string>
    rightActions?: React.ReactNode[]
}
const getStyles = (theme: Theme, isSecondary = false) => ({
    appBar: css`
        display: flex;
        padding: 0 20px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        background-color: ${isSecondary ? '#333333' : theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: none;
        height: 75px;
        @media ${getMobileMediaQuery()} {
            display: none;
        }
    `,
    appBarMobile: css`
        display: none;
        @media ${getMobileMediaQuery()} {
            display: flex;
            padding: 0 20px;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            background-color: ${isSecondary ? '#333333' : theme.palette.primary.main};
            box-shadow: none;
            height: 60px;
        }
    `,
    actionWrapper: css`
        display: flex;
        align-items: center;
    `,
    actionWrapperMobile: css`
        display: flex;
        align-items: center;
    `,
    secondaryText: css`
        color: #ffffff;
        margin-left: 20px;
        font-size: 18px;
    `,
    filler: css`
        flex-grow: 1;
    `,
    logo: css`
        cursor: pointer;
    `,
    rightAction: css`
        margin: 0 10px;
    `,
    contextSwitcher: css`
        margin-left: 24px;
    `,
})
export const getStandardRightActions = (): React.ReactNode[] => {
    return [<TopNavigation key={'nav'} />, <LanguagePicker key={'lang'} />, <LogoutButton key={'logout'} />]
}
export const getMinimalRightActions = (): React.ReactNode[] => {
    return [<LanguagePicker key={'lang'} />, <LogoutButton key={'logout'} />]
}
const SecondaryTopBar: React.FC<SecondaryProps> = ({ rightActions = [], onCancel, secondaryText }) => {
    const theme = useTheme()
    const styles = getStyles(theme, true)
    return (
        <AppBar css={styles.appBar}>
            {onCancel && (
                <IconButton
                    onClick={onCancel}
                    size="large"
                    edge="start"
                    sx={{ mr: 2, '.MuiSvgIcon-root': { fill: '#fff' } }}
                >
                    <Close />
                </IconButton>
            )}
            {secondaryText && <div css={styles.secondaryText}>{secondaryText}</div>}
            <div css={styles.filler} />
            <div css={styles.actionWrapper}>
                {rightActions.map((a: any, index: number) => (
                    <div key={`action_${index}`} css={styles.rightAction}>
                        {a}
                    </div>
                ))}
            </div>
        </AppBar>
    )
}
export const TopBar: React.FC<Props> = ({ rightActions = [], secondary }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const styles = getStyles(theme)
    const isMobile = useMediaQuery(getMobileMediaQuery())
    const location = useLocation()
    const isHomePage = location.pathname === '/' ? true : false

    if (secondary && secondary.rightActions && secondary.rightActions.length > 0) {
        return <SecondaryTopBar {...secondary} />
    }
    return (
        <>
            {!isMobile ? (
                <AppBar css={styles.appBar}>
                    <div css={styles.actionWrapper}>
                        <Button
                            sx={{ p: 0, color: theme.palette.primary.contrastText }}
                            onClick={() => navigate('/search')}
                        >
                            <StyledTypography bold variant="h5" css={styles.logo}>
                                Koti
                            </StyledTypography>
                        </Button>

                        <div css={styles.contextSwitcher}>
                            <AuthContextSwitcher />
                        </div>
                    </div>
                    <div css={styles.actionWrapper}>
                        {rightActions.map((a: any, index: number) => (
                            <div key={`action_${index}`} css={styles.rightAction}>
                                {a}
                            </div>
                        ))}
                    </div>
                </AppBar>
            ) : (
                <AppBar css={styles.appBarMobile}>
                    <div css={styles.actionWrapper}>
                        <Button
                            sx={{ p: 0, color: theme.palette.primary.contrastText }}
                            onClick={() => navigate('/search')}
                        >
                            <StyledTypography bold variant="h5" css={styles.logo}>
                                Koti
                            </StyledTypography>
                        </Button>
                    </div>
                    <div css={styles.actionWrapper}>
                        <div css={styles.rightAction}>
                            {isHomePage ? <LoginButton /> : <MobileNavigationDrawer rightActions={rightActions} />}
                        </div>
                    </div>
                </AppBar>
            )}
        </>
    )
}
