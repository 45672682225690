/** @jsxImportSource @emotion/react */
import { selectId, useAuthContextRole } from '../auth/auth.slice'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { Role } from '../auth/role'
import { Alert } from '@mui/material'
import { useAddUserFlagMutation, useGetProfileQuery } from '../users/users.service'
import { UserFlags } from '../users/user'
import { useAppSelector } from '../../redux/hooks'

const styles = {
    tutorialText: css`
        white-space: pre-line;
        color: #000;
    `,
}
export const SearchTutorial: React.FC = () => {
    const role = useAuthContextRole()
    const { t } = useTranslation()
    const userId = useAppSelector(selectId)
    const { data } = useGetProfileQuery(userId, {
        skip: userId === null,
    })
    const [addUserFlag] = useAddUserFlagMutation()
    if (data == null || data.uiFlags.includes(UserFlags.SearchTutorial)) {
        return null
    }
    let text = ''
    const welcomeText = t('WELCOME', 'Hei! Velkommen til Koti.')
    if (role === Role.RealtorAdmin || role === Role.CubitAdmin) {
        text = t(
            'REALTOR_SEARCH_TUTORIAL',
            'Her kan du bestille meglerrapporter. Søk opp adresse og legg til rapportprodukter i handlekurven. Før du bestiller legger du til oppdragsnummeret på adressene. Dermed blir bestillingen lagt til på faktura som sendes til din bedrift',
        )
    }
    if (role === Role.RealtorUser) {
        text = t(
            'REALTOR_USER_SEARCH_TUTORIAL',
            'Her kan du finne og bestille meglerrapporter. Søk etter adressen du jobber med og legg til rapportprodukter i handlekurven. \n' +
                'Når du er klar til å bestille, legger du til oppdragsnummeret på adressene. Faglig ansvarlig får dermed faktura på din bestilling.',
        )
    }
    if (role === Role.OsloReadOnly || role === Role.OsloReadOnlyLimited) {
        text = t(
            'OSLO_VIEW_ONLY_SEARCH_TUTORIAL',
            'Søk med adresse eller matrikkel for å finne eiendommer. Åpne eiendommen (og eventuelt seksjonen) for å se informasjon om kommunale avgifter.',
        )
    }
    if (role === Role.OsloUser) {
        text = t(
            'INE_SEARCH_TUTORIAL',
            'Søk med adresse eller matrikkel for å finne eiendommer. Åpne eiendommen (og eventuelt seksjonen) for å se informasjon om kommunale avgifter.\n' +
                'På Adminsiden i menyen oppe til høyre får du oversikt over faktura, meglerkontorer, megleres oppslag og innsynsbrukeres aktivitet',
        )
    }
    return (
        <div>
            {text && (
                <Alert icon={false} severity={'info'} onClose={() => addUserFlag(UserFlags.SearchTutorial)}>
                    <div css={styles.tutorialText}>{welcomeText}</div>
                    <div css={styles.tutorialText}>{text}</div>
                </Alert>
            )}
        </div>
    )
}
