export type Vendor = {
    id: string
    name: string
    type: VendorType
}
export enum VendorType {
    Cubit = 'cubit',
    Oslo = 'oslo',
}

export enum VendorName {
    Cubit = 'Cubit',
    Oslo = 'Oslo kommune',
}
