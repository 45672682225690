/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import StyledContainedButton from '../styled/styled-contained-button'
type Props = {
    isDialogOpened: boolean
    handleCloseDialog: any
    handleTermsAgree: any
}
export const TermsDialog: React.FC<Props> = ({ isDialogOpened, handleCloseDialog, handleTermsAgree }) => {
    const handleClose = () => {
        handleCloseDialog(false)
    }
    const handleAgree = () => {
        handleTermsAgree(true)
        handleClose()
    }
    const styles = {
        dialogTitle: css`
            display: flex;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
            @media ${getMobileMediaQuery()} {
                font-size: 20px;
            }
        `,
        dialogInnerContainer: css`
            background-color: #fff;
            border-radius: 5px;
            border: solid 1px #000;
            margin: 30px auto;
            width: 80%;
            max-height: 600px;
            overflow-y: scroll;
            padding: 30px 40px;
            @media ${getMobileMediaQuery()} {
                margin: 0 0 10px 0;
                padding: 0;
            }
        `,
        dialogActions: css`
            display: flex;
            justify-content: center;
        `,
    }
    const { t } = useTranslation()
    return (
        <Dialog
            PaperProps={{
                style: { borderRadius: 0 },
            }}
            open={isDialogOpened}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <div style={{ backgroundColor: '#f4f9fd', padding: '20px' }}>
                <DialogTitle css={styles.dialogTitle}>{t('TERMS_AND_CONDITIONS', 'Vilkår og betingelser')}</DialogTitle>
                <div css={styles.dialogInnerContainer}>
                    <DialogContent>
                        <h3>BRUKERVILK&Aring;R FOR KOTI</h3>
                        <p>Sist oppdatert: 21.02.2023</p>
                        <p>
                            Cubit AS (&laquo;Cubit&raquo;) leverer en programvaretjeneste (software-as-a-service) i form
                            av en portal hvor det er mulig &aring; bestille ulike typer rapporter
                            (&laquo;Portalen&raquo;).
                        </p>
                        <p>
                            De f&oslash;lgende brukervilk&aring;rene gjelder for bruk av Portalen. &nbsp;Portalen er
                            levert av Cubit AS, org. nr. 919 062 223. Henvisninger til &laquo;vi&raquo; og
                            &laquo;oss&raquo; i det f&oslash;lgende viser til selskapet Cubit AS.
                        </p>
                        <p>
                            Fysiske personer som bruker Portalen omtales som &laquo;Brukeren&raquo;. Henvisninger til
                            &laquo;du&raquo; og &laquo;deg&raquo; viser til Brukeren.
                        </p>
                        <p>
                            Ved &aring; ta i bruk Portalen og/eller opprette en brukerkonto erkjenner og aksepterer du
                            disse brukevilk&aring;rene.
                        </p>
                        <ol>
                            <li>
                                <h3>Vilk&aring;r for tilgang til og bruk av Portalen</h3>
                            </li>
                            <ol start={1.1}>
                                <li>
                                    <h5>Om Portalen&nbsp;</h5>
                                    <p>
                                        Portalen gj&oslash;r det mulig &aring; logge seg inn og bestille ulike Rapporter
                                        (som definert nedenfor) mot betaling. Portalen leveres som en standardtjeneste
                                        til alle Brukere. Portalen kan endre innhold, utforming og funksjonalitet over
                                        tid. Det garanteres ikke at Portalen vil fungere feilfritt eller at den alltid
                                        vil v&aelig;re tilgjengelig.
                                    </p>
                                </li>
                                <li>
                                    <h5>Brukerkonto og innlogging</h5>

                                    <p>
                                        For &aring; kunne bruke Portalen m&aring; du ha mottatt tilgang og
                                        innloggingsinformasjon fra et selskap eller en annen juridisk enhet
                                        (&laquo;Selskap&raquo;) som har avtale med Cubit. Disse brukervilk&aring;rene
                                        gir ikke Selskapet eller Brukeren utvidede rettigheter ut over det som
                                        f&oslash;lger av avtalen mellom Selskapet og Cubit.
                                    </p>
                                    <p>
                                        For &aring; f&aring; tilgang til Portalen m&aring; du identifisere seg ved hjelp
                                        av BankID. Brukerkontoen er personlig, og skal ikke deles med andre.
                                    </p>
                                </li>
                                <li>
                                    <h5>Tilgangsrettigheter&nbsp;</h5>
                                    <p>
                                        Selskapet som har avtale med Cubit kan oppnevne &eacute;n s&aring;kalt
                                        &laquo;Adminbruker&raquo;. Adminbrukeren vil ha mulighet til &aring; opprette
                                        flere Brukere som gis tilgang til Portalen. Adminbrukeren kan ogs&aring;
                                        delegere rettigheter og opprette grupper. Adminbrukeren har kun rett til &aring;
                                        dele tilgang til ansatte i Selskapet og er ansvarlig for at tilgang ikke deles
                                        med uvedkommende.
                                    </p>
                                </li>
                            </ol>
                        </ol>

                        <ol start={2}>
                            <li>
                                <h3>Bruksrett og tillatt bruk av Portalen</h3>
                            </li>
                        </ol>
                        <p>
                            Ved aksept av brukervilk&aring;rene f&aring;r Brukeren en midlertidig, ikke-eksklusiv og
                            gjenkallelig rett til &aring; benytte Portalen i Norge. Tilgangen er begrenset til interne
                            forretningsform&aring;l (aktiviteter og operasjoner utelukkende tilknyttet Selskapets
                            bedrift og virksomhet) og i samsvar med brukervilk&aring;rene.
                        </p>
                        <p>
                            Brukeren forplikter seg til &aring; benytte Portalen p&aring; en m&aring;te som ikke er til
                            skade for Cubit eller andre brukere av tjenesten.&nbsp;
                        </p>
                        <p>
                            Brukeren har ikke rett til &aring; fors&oslash;ke &aring; skaffe seg tilgang til
                            uautoriserte deler av Portalen. Brukeren m&aring; oppbevare innloggingsdetaljer p&aring; en
                            trygg m&aring;te og er alene ansvarlig for sikkerheten knyttet til sin brukerkonto.
                        </p>
                        <p>
                            Dersom du har grunn til &aring; anta at din brukerkonto er misbrukt av andre, plikter du
                            &aring; informere oss om dette. Ved mistanke om andre typer sikkerhetsbrudd plikter du
                            &aring; gi beskjed, og avvente videre bruk av Portalen til vi har unders&oslash;kt og gitt
                            tilbakemelding p&aring; mistanken.
                        </p>
                        <ol start={3}>
                            <li>
                                <h3>Bruksrett og tillatt bruk av Rapporter</h3>
                            </li>
                        </ol>
                        <p>
                            Gjennom Portalen f&aring;r Brukeren mulighet til &aring; bestille rapporter, som er
                            utarbeidet av og inneholder informasjon fra tredjeparter (&laquo;Rapporter&raquo;).&nbsp;
                        </p>
                        <p>
                            Brukeren f&aring;r en midlertidig bruksrett til Rapportene, begrenset av den til enhver tid
                            gjeldende avtalen mellom Cubit og Selskapet som har gitt Brukeren tilgang til Portalen.
                        </p>
                        <p>
                            Rapportene er utviklet, utarbeidet og/eller bearbeidet av tredjeparter, som f.eks. en
                            kommune eller en tjenesteleverand&oslash;r. Cubit vil formidle Rapportene, men er ikke
                            ansvarlig for innholdet i eller kvaliteten p&aring; Rapportene.
                        </p>
                        <p>
                            Brukeren kan kun benytte Rapportene i forbindelse med Selskapets vanlige forretningsmessige
                            aktiviteter.&nbsp;
                        </p>
                        <p>Det er ikke tillatt &aring;:</p>
                        <ul>
                            <li>
                                <p>
                                    Benytte Rapportene p&aring; annen m&aring;te enn det som fremg&aring;r av disse
                                    brukervilk&aring;rene
                                </p>
                            </li>
                            <li>
                                <p>Videreselge eller p&aring; annen m&aring;te kommersielt utnytte Rapportene</p>
                            </li>
                            <li>
                                <p>Dele eller gi tilgang til Rapportene til tredjeparter</p>
                            </li>
                            <li>
                                <p>Endre eller modifisere Rapportene</p>
                            </li>
                        </ul>
                        <p>Nedenfor f&oslash;lger s&aelig;rskilte begrensninger p&aring; visse typer Rapporter:</p>
                        <ul>
                            <li>
                                <p>
                                    Rapporter som inneholder informasjon om kommunale avgifter og skatt kan kun benyttes
                                    av eiendomsmeglere i forbindelse med et konkret salg av bolig&nbsp;
                                </p>
                            </li>
                        </ul>
                        <ol start={4}>
                            <li>
                                <h3>Bestilling av Rapporter</h3>
                            </li>
                        </ol>
                        <p>
                            Brukeren kan bestille Rapporter i Portalen. Pris for Rapportene vil fremkomme i tilknytning
                            til Rapporten. Brukeren kan kun bestille Rapporter i den grad og det omfang som er tillatt
                            av Selskapet. Bestilling vil medf&oslash;re at Selskapet blir ansvarlig for betaling av
                            Rapporten. En bestilling er bindende fra Rapporten er lagt i handlekurv og Brukeren har
                            bekreftet kj&oslash;p. Brukeren f&aring;r tilgang til Rapporten innen kort tid, og det er
                            ikke mulig &aring; angre eller be om tilbakebetaling etter en Rapport er bestilt.
                        </p>
                        <ol start={5}>
                            <li>
                                <h3>Endring av brukervilk&aring;rene&nbsp;</h3>
                            </li>
                        </ol>
                        <p>
                            Brukervilk&aring;r kan endres ved &aring; publisere en ny versjon av vilk&aring;rene
                            p&aring; nettsiden hvor Portalen er tilgjengelig. Brukeren er ansvarlig for &aring;
                            gj&oslash;re seg kjent med de til enhver tid gjeldene vilk&aring;rene. Ved vesentlige
                            endringer vil Cubit forh&aring;ndsvarsle Selskapet og/eller Brukeren.
                        </p>
                        <ol start={6}>
                            <li>
                                <h3>Immaterielle rettigheter, eiendomsrett og disposisjonsrett</h3>
                            </li>
                        </ol>
                        <p>
                            Cubit, eller dens lisensgivere, er innehaver(e) av alle immaterielle rettigheter (IPR)
                            knyttet til n&aring;v&aelig;rende og fremtidige versjoner av Portalen og Rapportene.
                            Immaterielle rettigheter inkluderer, men er ikke begrenset til, opphavsrett, patenter,
                            bedriftshemmeligheter, varemerker, merkenavn, design, produktdesign, kildekoder, databaser
                            og know-how, enten disse er registrert eller ikke. All dokumentasjon, inkludert manualer,
                            bruksanvisninger og andre skriftlige, elektroniske eller ikke, beskrivelser av hvordan
                            Portalen er satt opp, metodikk og teoretisk underlag, hvordan det fungerer eller brukes
                            regnes som en del av Portalen og tilh&oslash;rer Cubit. &nbsp;Bruken av Portalen
                            inneb&aelig;rer ingen overdragelse av immaterielle rettigheter til Brukeren. &nbsp;
                        </p>
                        <p>
                            Cubit har en vederlagsfri, ikke-eksklusiv og ikke-tidsbegrenset rett til &aring; benytte
                            Brukerens data i anonymisert form for videreutvikling og forbedring av Portalen. &nbsp;
                        </p>
                        <ol start={7}>
                            <li>
                                <h3>Personopplysninger</h3>
                            </li>
                        </ol>
                        <p>
                            Selskapet som har gitt Brukeren tilgang til Portalen er ansvarlig for Brukerens
                            personopplysninger. Cubit behandler personopplysningene i tr&aring;d med avtale med nevnte
                            Selskap. &nbsp;
                        </p>
                        <ol start={8}>
                            <li>
                                <h3>Konfidensialitet</h3>
                            </li>
                        </ol>
                        <p>
                            Brukeren skal bevare informasjon fra Portalen konfidensielt og ikke dele informasjonen med
                            tredjeparter. Brukeren skal ikke formidle priser p&aring; Rapportene, eller Rapportenes
                            innhold til tredjeparter.&nbsp;
                        </p>
                        <ol start={9}>
                            <li>
                                <h3>Brudd p&aring; brukervilk&aring;rene</h3>
                            </li>
                        </ol>
                        <p>
                            Brudd p&aring; vilk&aring;rene kan medf&oslash;re at Brukeren blir permanent eller
                            midlertidig utestengt fra Portalen. Brudd kan ogs&aring; medf&oslash;re konsekvenser for
                            avtalen mellom Cubit og Selskapet som har gitt Brukeren tilgang til Portalen.
                        </p>
                        <ol start={10}>
                            <li>
                                <h3>Tvister</h3>
                            </li>
                        </ol>
                        <p>
                            Brukervilk&aring;rene reguleres av norsk rett. Tvister som ikke l&oslash;ses i minnelighet
                            kan kreves avgjort ved s&oslash;ksm&aring;l for de alminnelige domstoler, med verneting der
                            Cubit har sin forretningsadresse.
                        </p>
                    </DialogContent>
                </div>
                <DialogActions css={styles.dialogActions}>
                    <Button onClick={handleClose}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton variant={'contained'} color={'secondary'} onClick={handleAgree}>
                        {t('ACCEPT_TERMS_AND_CONDITIONS', 'Aksepter vilkår og betingelser')}
                    </StyledContainedButton>
                </DialogActions>
            </div>
        </Dialog>
    )
}
