/** @jsxImportSource @emotion/react */
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { ProductsAdminTable } from './products-admin-table'
import { Grid } from '@mui/material'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { productsActions, selectSelectedProducts } from './product.slice'
import { ProductsHistoryView } from '../history/products-history.view'
import { AddProduct } from './add-product'
import { KotiButtonSecondary } from '../general/buttons/koti-button-secondary'
import { useTranslation } from 'react-i18next'
import { useDeleteProductMutation } from './products.service'
import { ConfirmDialog } from '../general/dialogs/confirm-dialog'
import { HistoryEntityType } from '../history/models/history-entry'

export const AdminProductsPage: React.FC = () => {
    const selectedProducts = useAppSelector(selectSelectedProducts)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [handleDelete] = useDeleteProductMutation()
    let secondaryActions
    if (selectedProducts && selectedProducts.length > 0) {
        secondaryActions = [
            <ConfirmDialog
                key={'delete_products'}
                title={t('DELETE_PRODUCT_HEADER', 'Delete products')}
                content={t('DELETE_PRODUCT_CONTENT', 'Are you sure you want to delete selected products')}
                onConfirm={() => {
                    handleDelete(selectedProducts.map((x) => x.id)).then(() =>
                        dispatch(productsActions.setSelectedProducts([])),
                    )
                }}
            >
                <KotiButtonSecondary
                    text={t('TEXT_DELETE_PRODUCT', 'Slett produkt', { count: selectedProducts.length })}
                />
            </ConfirmDialog>,
        ]
    }
    return (
        <div>
            <TopBar
                rightActions={getStandardRightActions()}
                secondary={{
                    rightActions: secondaryActions,
                    secondaryText: t('TEXT_PRODUCT_SELECTED', '{{count}} produkt valgt', {
                        count: selectedProducts.length,
                    }),
                    onCancel: () => dispatch(productsActions.setSelectedProducts([])),
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AddProduct />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <ProductsAdminTable />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <ProductsHistoryView entityType={HistoryEntityType.Product} />
                </Grid>
            </Grid>
            <BottomPageNavigation />
        </div>
    )
}
