import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../../app/material-ui/material-ui.config'
export const ineProductStyles = {
    formControl: css`
        min-width: 200px;
    `,
    inputLabel: css`
        transform: none;
        font-size: 12px;
    `,
    totalYearSum: css`
        display: flex;
        padding: 16px 0;
    `,
    totalYearText: css`
        display: flex;
    `,
    totalYearAmount: css`
        font-weight: 600;
        margin-left: 6px;
    `,
    termContainer: css`
        width: 100%;
        margin-bottom: 26px;
    `,
    termHeader: css`
        font-weight: 600;
    `,
    invoiceNumber: css`
        width: 100%;
        border-bottom: solid #a9a9a9 1px;
        padding: 6px 0 6px 0;
        font-size: 12px;
    `,
    priceDevelopment: css`
        width: 100%;
        display: flex;
        border-bottom: solid #a9a9a9 1px;
        padding: 6px 0 6px 0;
        font-weight: 600;
    `,
    ineTabsContainer: css`
        display: flex;
        width: 100%;
        margin: 8px 0;
        @media ${getMobileMediaQuery()} {
            padding: 0;
            margin: 4px 0;
        }
    `,
    ineTableExplanations: css`
        font-size: 12px;
        margin-top: 8px;
    `,
    tableCellAlignLeft: css`
        text-align: left;
    `,
    tableCellAlignRight: css`
        text-align: right;
    `,
    totalRow: css`
        font-weight: 800;
    `,
}
export const ineTabsStyles = {
    '&.MuiTabs-root .MuiTabs-indicator': { backgroundColor: '#86b9d7', height: '3px' },
    borderBottom: 'solid #b4b4b4 1px',
    paddingBottom: '6px',
    width: '100%',
}
export const ineTabStyles = { '&.Mui-selected': { color: '#000000', display: 'flex', alignItems: 'flex-start' } }
export const ineIndentedLine = { p: '6px 0 6px 20px !important' }
export const ineTableStyles = {
    '.MuiTableCell-head ': {
        fontSize: '12px',
        fontStyle: 'italic',
        padding: '6px 0 ',
        borderColor: '#a9a9a9',
    },
    '.MuiTableCell-body ': {
        fontSize: '12px',
        padding: ' 6px 0',
        borderColor: '#a9a9a9',
    },
    '.MuiTableRow-root > .MuiTableCell-root': {
        padding: '6px 0 6px',
    },
    minWidth: 650,
}
