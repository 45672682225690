import { appApi } from '../../redux/api'
import { AuthContext } from '../auth/auth-context.type'
import { User } from './user'
import { PaginatedResults } from '../general/models/paginated-results'
import { CompanyUserWithPid } from './types/company-user'
import { PaginationParams } from '../general/models/pagination-params'
import { RealtorUsersFilters } from '../ine-admin-page/realtors-users/realtor-users-filters'

export const usersApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query<User, string | null | undefined>({
            query: (params) => ({
                url: `/v1/users/${params}`,
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
        getRealtorList: builder.query<
            PaginatedResults<CompanyUserWithPid>,
            PaginationParams<CompanyUserWithPid, RealtorUsersFilters>
        >({
            query: (params) => ({
                url: `/v1/users/realtors/list`,
                method: 'GET',
                params: params,
            }),
            providesTags: [],
        }),
        updateUser: builder.mutation<User, { update: User; id: string }>({
            query: (params) => ({
                url: `/v1/users/${params.id}`,
                method: 'PUT',
                body: params.update,
            }),
            invalidatesTags: ['History'],
        }),
        updateUserContext: builder.mutation<User, { update: AuthContext; id: string }>({
            query: (params) => ({
                url: `/v1/users/${params.id}/context`,
                method: 'PUT',
                body: params.update,
            }),
            invalidatesTags: ['Profile', 'Cart'],
        }),
        deleteUser: builder.mutation<void, string[]>({
            query: (params) => ({
                url: `/v1/users`,
                method: 'DELETE',
                body: { ids: params },
            }),
            invalidatesTags: ['History'],
        }),
        deleteUserFlag: builder.mutation<void, string>({
            query: (params) => ({
                url: `/v1/users/flags`,
                method: 'DELETE',
                body: { flag: params },
            }),
            invalidatesTags: ['Profile'],
        }),
        addUserFlag: builder.mutation<User, string>({
            query: (params) => ({
                url: `/v1/users/flags`,
                method: 'POST',
                body: { flag: params },
            }),
            invalidatesTags: ['Profile'],
        }),
    }),
})
export const {
    useGetProfileQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useUpdateUserContextMutation,
    useAddUserFlagMutation,
    useDeleteUserFlagMutation,
    useLazyGetRealtorListQuery,
} = usersApi
