/** @jsxImportSource @emotion/react */
import React from 'react'
import { Field, Form } from 'react-final-form'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CubitSelectAdapter } from '../../general/forms/cubit-select-adapter'
import { getUserTypeOptions } from '../../users/user-helper'
import { css } from '@emotion/react'
import { KotiCompanySearchAdapter } from '../../general/forms/koti-company-search-adapter'
import { useLazyGetRealtorListExportQuery } from '../../exports/exports.service'
import { ExportOptionsPicker } from '../../exports/export-options-picker'
import StyledContainedButton from '../../styled/styled-contained-button'

type Props = {
    onSubmit: any
    filters: RealtorUsersFilters
}
export type RealtorUsersFilters = {
    company?: string
    role?: string
}
const styles = {
    paper: css`
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    filters: css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: start;
    `,
    rolePicker: css`
        width: 240px;
        margin-right: 32px;
    `,
    companyPicker: css`
        width: 240px;
        margin-right: 32px;
    `,
    label: css`
        font-size: 16px;
        font-weight: 600;
        margin-right: 32px;
    `,
    search: css`
        width: 200px;
        margin-right: 32px;
    `,
    field: css`
        max-width: 100%;
        width: 100%;
        display: block;
        margin-bottom: 16px;
    `,
}
export const RealtorUsersFiltersView: React.FC<Props> = ({ onSubmit, filters }) => {
    const { t } = useTranslation()
    const [triggerRealtorCsv] = useLazyGetRealtorListExportQuery()
    const handleCsvTrigger = () => {
        triggerRealtorCsv({
            page: 0,
            role: filters.role,
            company: filters.company,
        })
    }
    const rolesOptions = [...getUserTypeOptions(t), { label: t('all', 'All'), value: undefined }]
    return (
        <div>
            <Paper elevation={0} css={styles.paper}>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                        <form id={'REALTOR_USERS_FILTERS'} onSubmit={handleSubmit}>
                            <div css={styles.filters}>
                                <div css={styles.label}>{t('TEXT_FILTERS', '')}</div>
                                <div css={styles.companyPicker}>
                                    <Field
                                        fullWidth
                                        name="company"
                                        margin={'dense'}
                                        component={KotiCompanySearchAdapter}
                                        label={t('TEXT_REALTOR_COMPANY', 'Meglerkontor')}
                                        variant="standard"
                                    />
                                </div>
                                <div css={styles.rolePicker}>
                                    <Field
                                        fullWidth
                                        name="role"
                                        options={rolesOptions}
                                        valueIsObject={false}
                                        margin={'dense'}
                                        component={CubitSelectAdapter}
                                        label={t('TEXT_ROLE', 'Role')}
                                        variant="standard"
                                    />
                                </div>

                                <div>
                                    <StyledContainedButton type={'submit'} variant={'contained'} color={'secondary'}>
                                        {t('TEXT_SEARCH', 'Søk')}
                                    </StyledContainedButton>
                                </div>
                            </div>
                        </form>
                    )}
                />
                <ExportOptionsPicker csvTrigger={handleCsvTrigger} />
            </Paper>
        </div>
    )
}
