/** @jsxImportSource @emotion/react */
import { FileCopy } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useCanAccessProducts } from '../../utils/policies'
import { ROUTE_ADMIN, ROUTE_CART, ROUTE_ORDERS, ROUTE_SEARCH } from '../../utils/routes'
import { BottomNavigationCart } from './bottom-navigation-cart'
import { useIsAuthenticated } from '../auth/auth.slice'
import { bottomNavigationActions, selectCurrent } from './bottom-navigation.slice'
import { useMediaQuery, useTheme } from '@mui/material'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'

export const BottomPageNavigation = () => {
    const roles = useIsAuthenticated()
    const theme = useTheme()
    const currentPage = useAppSelector(selectCurrent)
    const productsAccess = useCanAccessProducts()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(getMobileMediaQuery())

    return (
        <Box>
            <BottomNavigation
                showLabels
                sx={{
                    display: isMobile ? 'flex' : 'none',
                    backgroundColor: theme.palette.primary.main,
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    height: '60px',
                    marginTop: '60px',
                    zIndex: 2,
                }}
                value={currentPage}
                onChange={(event, newValue) => {
                    dispatch(bottomNavigationActions.setCurrentPage(newValue))
                }}
            >
                <BottomNavigationAction
                    sx={{
                        color: theme.palette.primary.contrastText,

                        '&.MuiButtonBase-root.Mui-selected': {
                            color: theme.palette.primary.contrastText,
                            marginBottom: '-8px',
                            '&::after': {
                                content: '""',
                                width: '40px',
                                height: '3px',
                                backgroundColor: theme.palette.primary.contrastText,
                                marginTop: '5px',
                            },
                        },
                    }}
                    icon={<FileCopy />}
                    onClick={() => (roles ? navigate(ROUTE_ORDERS) : null)}
                />
                <BottomNavigationAction
                    sx={{
                        color: theme.palette.primary.contrastText,

                        '&.MuiButtonBase-root.Mui-selected': {
                            color: theme.palette.primary.contrastText,
                            marginBottom: '-8px',
                            '&::after': {
                                content: '""',
                                width: '40px',
                                height: '3px',
                                backgroundColor: theme.palette.primary.contrastText,
                                marginTop: '5px',
                            },
                        },
                    }}
                    icon={<SearchIcon />}
                    onClick={() => (roles ? navigate(ROUTE_SEARCH) : null)}
                />
                <BottomNavigationAction
                    sx={{
                        color: theme.palette.primary.contrastText,

                        '&.MuiButtonBase-root.Mui-selected': {
                            color: theme.palette.primary.contrastText,
                            marginBottom: '-8px',
                            '&::after': {
                                content: '""',
                                width: '40px',
                                height: '3px',
                                backgroundColor: theme.palette.primary.contrastText,
                                marginTop: '5px',
                            },
                        },
                    }}
                    icon={<BottomNavigationCart />}
                    onClick={() => (roles ? navigate(ROUTE_CART) : null)}
                />
                {productsAccess && (
                    <BottomNavigationAction
                        sx={{
                            color: theme.palette.primary.contrastText,
                            '&.MuiButtonBase-root.Mui-selected': {
                                color: theme.palette.primary.contrastText,
                                marginBottom: '-8px',
                                '&::after': {
                                    content: '""',
                                    width: '40px',
                                    height: '3px',
                                    backgroundColor: theme.palette.primary.contrastText,
                                    marginTop: '5px',
                                },
                            },
                        }}
                        icon={<SettingsIcon />}
                        onClick={() => (roles ? navigate(ROUTE_ADMIN) : null)}
                    />
                )}
            </BottomNavigation>
        </Box>
    )
}
