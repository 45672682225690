import { appApi } from '../../../redux/api'
import { AccessLog } from './models/access-log.type'
import { PaginatedResults } from '../../general/models/paginated-results'
import { PaginationParams } from '../../general/models/pagination-params'
import { AccessLogFilters } from './access-logs-filters'

const accessLogs = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccessLogs: builder.query<PaginatedResults<AccessLog>, PaginationParams<AccessLog, AccessLogFilters>>({
            query: (params) => ({
                url: '/v1/accessLog',
                method: 'GET',
                params: params,
            }),
            providesTags: ['AccessLog'],
        }),
        getAccessLogsForOrders: builder.query<
            PaginatedResults<AccessLog>,
            PaginationParams<AccessLog, AccessLogFilters>
        >({
            query: (params) => ({
                url: '/v1/accessLog/orders',
                method: 'GET',
                params: params,
            }),
            providesTags: ['AccessLog'],
        }),
    }),
})

export const { useLazyGetAccessLogsQuery, useLazyGetAccessLogsForOrdersQuery } = accessLogs
