import { appApi } from '../api'
import { PaginatedResults } from '../../components/general/models/paginated-results'
import { PaginationParams } from '../../components/general/models/pagination-params'
import { ProductFilters } from '../../components/products/product.slice'
import { User } from '../../components/users/user'
import {
    AcceptInviteRequest,
    BaseCompany,
    Company,
    CompanyConfig,
    CompanyCreateRequest,
    CompanyCreateUserRequest,
    CompanyRegisterRequest,
    CompanyStatus,
    DepartmentCreateRequest,
    FinishRegistrationRequest,
} from '../../components/companies/models/company'
import { UpdateCompanyUserRequest } from '../../components/companies/models/update-company-user-request'
import { Signatory } from '../../components/cubit-admin-page/types/signatory.type'
import { SignableDocument } from '../../components/cubit-admin-page/types/signable-document.type'

const companiesApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanies: builder.query<PaginatedResults<Company>, PaginationParams<Company, ProductFilters>>({
            query: (params) => ({
                url: '/v1/companies',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Companies'],
        }),
        getDepartments: builder.query<Company[], string>({
            query: (params) => ({
                url: `/v1/companies/departments/get-all/${params}`,
                method: 'GET',
            }),
            providesTags: ['Departments'],
        }),
        getCompany: builder.query<Company, string>({
            query: (params) => ({
                url: `/v1/companies/${params}`,
                method: 'GET',
            }),
            providesTags: ['Companies'],
        }),
        getCompanyBySecretKey: builder.query<Company, string>({
            query: (params) => ({
                url: `/v1/companies/secret-key/${params}`,
                method: 'GET',
            }),
            providesTags: ['Companies'],
        }),
        getCompanyUsers: builder.query<PaginatedResults<User>, PaginationParams<User, { companyId: string }>>({
            query: (params) => ({
                url: `/v1/companies/${params.companyId}/users`,
                method: 'GET',
                params: params,
            }),
            providesTags: ['CompanyUsers'],
        }),
        getCompanyDepartmentsUsers: builder.query<
            PaginatedResults<User>,
            PaginationParams<User, { companyId: string }>
        >({
            query: (params) => ({
                url: `/v1/companies/${params.companyId}/users/all`,
                method: 'GET',
                params: params,
            }),
            providesTags: ['CompanyUsers'],
        }),
        createCompany: builder.mutation<Company, CompanyCreateRequest>({
            query: (params) => ({
                url: '/v1/companies',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Companies', 'History'],
        }),
        createDepartment: builder.mutation<Company, DepartmentCreateRequest>({
            query: (params) => ({
                url: '/v1/companies/department',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Departments'],
        }),
        registerCompany: builder.mutation<Company, CompanyRegisterRequest>({
            query: (params) => ({
                url: '/v1/companies/register',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Profile'],
        }),
        acceptInvite: builder.mutation<void, AcceptInviteRequest>({
            query: (params) => ({
                url: '/v1/companies/accept-invite',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Profile'],
        }),
        finishRegistration: builder.mutation<void, FinishRegistrationRequest>({
            query: (params) => ({
                url: '/v1/companies/finish-registration',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateCompanyUser: builder.mutation<void, UpdateCompanyUserRequest>({
            query: (params) => ({
                url: `/v1/companies/${params.companyId}/users/${params.userId}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['CompanyUsers'],
        }),
        createCompanyUser: builder.mutation<Company, CompanyCreateUserRequest>({
            query: (params) => ({
                url: `/v1/companies/${params.companyId}/users`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['CompanyUsers', 'History'],
        }),
        deleteCompanyUser: builder.mutation<Company, { companyId: string; ids: string[] }>({
            query: (params) => ({
                url: `/v1/companies/${params.companyId}/users`,
                method: 'DELETE',
                body: { ids: params.ids },
            }),
            invalidatesTags: ['CompanyUsers', 'History'],
        }),
        sendInvitations: builder.mutation<Company, { companyId: string; userIds: string[] }>({
            query: (params) => ({
                url: `/v1/companies/send-invitations`,
                method: 'POST',
                body: { userIds: params.userIds, companyId: params.companyId },
            }),
            invalidatesTags: ['CompanyUsers'],
        }),
        updateCompany: builder.mutation<Company, { update: BaseCompany; id: string }>({
            query: (params) => ({
                url: `/v1/companies/${params.id}`,
                method: 'PUT',
                body: params.update,
            }),
            invalidatesTags: ['Companies', 'History'],
        }),
        deleteCompany: builder.mutation<void, string[]>({
            query: (params) => ({
                url: `/v1/companies`,
                method: 'DELETE',
                body: { ids: params },
            }),
            invalidatesTags: ['Companies', 'History'],
        }),
        sendContract: builder.mutation<void, { companyId: string; signatories: Signatory[] }>({
            query: (params) => ({
                url: `/v1/signing/generateContract/${params.companyId}`,
                method: 'POST',
                body: params.signatories,
            }),
        }),
        getCompanyDocuments: builder.query<SignableDocument[], { companyId: string }>({
            query: (params) => ({
                url: `/v1/signing/getSignableDocuments/${params.companyId}`,
                method: 'GET',
            }),
        }),
        searchCompanies: builder.query<Company[], { q: string }>({
            query: (params) => ({
                url: `/v1/companies/search`,
                params: params,
                method: 'GET',
            }),
        }),
        editCompanyConfig: builder.mutation<CompanyConfig, { id: string; config: CompanyConfig }>({
            query: (payload) => ({
                url: `/v1/companies/${payload.id}/edit-config`,
                method: 'PUT',
                body: payload.config,
            }),
            invalidatesTags: (result) => (!result ? [] : ['Companies']),
        }),
        changeCompanyStatus: builder.mutation<
            void,
            { id: string; status: Exclude<CompanyStatus, CompanyStatus.WaitingForSigning> }
        >({
            query: (payload) => ({
                url: `/v1/companies/change-status`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        companiesApi.util.updateQueryData('getCompany', payload.id, (draft) => {
                            draft.status = payload.status

                            return draft
                        }),
                    )
                    // eslint-disable-next-line no-empty
                } catch {}
            },
        }),
    }),
})

export const {
    useSendContractMutation,
    useAcceptInviteMutation,
    useGetCompaniesQuery,
    useGetCompanyQuery,
    useLazyGetCompanyQuery,
    useGetCompanyUsersQuery,
    useLazyGetCompanyUsersQuery,
    useFinishRegistrationMutation,
    useLazyGetCompaniesQuery,
    useDeleteCompanyUserMutation,
    useSendInvitationsMutation,
    useGetCompanyBySecretKeyQuery,
    useLazyGetCompanyBySecretKeyQuery,
    useCreateCompanyMutation,
    useRegisterCompanyMutation,
    useUpdateCompanyUserMutation,
    useCreateCompanyUserMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
    useCreateDepartmentMutation,
    useGetDepartmentsQuery,
    useGetCompanyDepartmentsUsersQuery,
    useGetCompanyDocumentsQuery,
    useLazySearchCompaniesQuery,
    useEditCompanyConfigMutation,
    useChangeCompanyStatusMutation,
} = companiesApi
