/** @jsxImportSource @emotion/react */
import { Chip, FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'

const CubitSelectAdapter: React.FC<any> = (props: any) => {
    const {
        input: { name, value, onChange, onFocus, onBlur },
        disabled,
        width,
        options,
        valueIsObject = true,
        label,
        variant,
        meta: { touched, error },
        multiple,
        required,
        ...rest
    } = props

    const valueRendererProps = { selectedValue: value, options, onChange, disabled }
    const valueRenderer = multiple
        ? valueIsObject
            ? renderSelectedMultipleObjects
            : renderSelectedMultipleValues
        : valueIsObject
        ? renderSelectedObject
        : renderSelectedValue

    return (
        <FormControl
            css={cubitFormStyles.field}
            variant={variant}
            required={required}
            error={touched && error ? true : false}
        >
            <FormGroup row>
                {label ? (
                    <InputLabel style={{ background: '#ffffff', paddingLeft: 4, paddingRight: 4 }}>{label}</InputLabel>
                ) : null}
                <Select
                    {...rest}
                    name={name}
                    value={value}
                    multiple={multiple}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    renderValue={() => valueRenderer(valueRendererProps)}
                    style={{ width: width }}
                >
                    {options.map((option: any, index: number) => (
                        <MenuItem key={index} value={valueIsObject ? option : option.value} style={option.style}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormGroup>
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export { CubitSelectAdapter }

type ValueRendererProps = {
    selectedValue: any
    options: any
    onChange: (value: any) => void
    disabled: boolean
}

type ChipsRendererProps = {
    selectedValues: any
    selectedValuesLabels: string
    onDelete: (valueToDelete: any) => void
    disabled: boolean
}

type ChipRendererProps = {
    index: number
    label: string
    value: any
    selectedValues: any
    onDelete: (valueToDelete: any) => void
    disabled: boolean
}

const renderSelectedValue = (props: ValueRendererProps) => {
    const option = props.options.filter((o: any) => o.value === props.selectedValue)[0]
    return option ? option.label : ''
}

const renderSelectedObject = (props: ValueRendererProps) => props.selectedValue.label

const renderSelectedMultipleValues = (props: ValueRendererProps) => {
    const { selectedValue: selectedValues, onChange, disabled } = props
    const onDelete = (valueToDelete: any) => onChange(selectedValues.filter((v: any) => v !== valueToDelete))
    const selectedValuesLabels = selectedValues.map((val: any) => {
        const option = props.options.find((o: any) => o.value === val)
        return option.label
    })

    return renderChips({ selectedValues, selectedValuesLabels, onDelete, disabled })
}

const renderSelectedMultipleObjects = (props: ValueRendererProps) => {
    const { selectedValue: selectedValues, onChange, disabled } = props
    const selectedValuesLabels = selectedValues.map((v: any) => v.label)
    const onDelete = (valueToDelete: any) =>
        onChange(selectedValues.filter((v: any) => v.value !== valueToDelete.value))

    return renderChips({ selectedValues, selectedValuesLabels, onDelete, disabled })
}

const renderChips = (props: ChipsRendererProps) => {
    const { selectedValues, selectedValuesLabels, onDelete, disabled } = props
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedValues.map((value: any, index: number) =>
                renderChip({ index, label: selectedValuesLabels[index], value, selectedValues, onDelete, disabled }),
            )}
        </div>
    )
}

const renderChip = (props: ChipRendererProps) => (
    <Chip
        onClick={props.disabled ? undefined : (e) => e.stopPropagation()}
        key={props.index}
        label={props.label}
        onDelete={props.disabled ? undefined : () => props.onDelete(props.value)}
        style={{ margin: '0 6px 6px 0' }}
        disabled={props.disabled}
    />
)
