import { KotiContainer } from '../general/layout/koti-container'
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { useAuthContextRole } from '../auth/auth.slice'
import { Role } from '../auth/role'
import { SearchNoAccess } from './search-noAccess'
import { SearchRealtor } from './search-realtor'

export const Search = () => {
    const role = useAuthContextRole()

    return (
        <>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                {role === Role.User && <SearchNoAccess />}
                {role !== Role.User && <SearchRealtor />}
            </KotiContainer>
        </>
    )
}
