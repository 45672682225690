export const getUniqueList = (array: any[]) => {
    // @ts-ignore
    const unique = [...new Set(array)]
    return unique
}
export const sortBy = <T>(arr: T[], param: keyof T, order: 'asc' | 'desc'): T[] => {
    return arr.sort((a, b) => {
        if (order === 'asc') {
            if (a[param] < b[param]) {
                return -1
            } else if (a[param] > b[param]) {
                return 1
            } else {
                return 0
            }
        } else {
            if (a[param] > b[param]) {
                return -1
            } else if (a[param] < b[param]) {
                return 1
            } else {
                return 0
            }
        }
    })
}
