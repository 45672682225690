/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Invoice } from '../models/ine-data.type'
import React from 'react'
import { formatCurrencyWithoutCode, getAllItemGroups, getInvoiceSummaryValue } from './ine-product-helper'
import { ineIndentedLine, ineProductStyles } from './ine-product-styles'
import { SimulatedValue } from './simulated-value'
import { IneExplanations } from './ine-explanations'
import { StyledIneTable } from './styles'

type Props = {
    invoice: Invoice
}

export const IneProductDetailedReportTable: React.FC<Props> = ({ invoice }) => {
    const { t } = useTranslation()
    return (
        <>
            <TableContainer>
                <StyledIneTable aria-label="ine-product-detailed-report-table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 240 }}>{t('TEXT_FEE', 'Avgift')}</TableCell>
                            <TableCell>{t('TEXT_PROMPT_TEXT', 'Ledetekst')}</TableCell>
                            <TableCell align={'right'}>{t('TEXT_AMOUNT', 'Beløp')}</TableCell>
                            <TableCell align={'right'}>{t('TEXT_ARREARS', 'Restanse')}</TableCell>
                            <TableCell align={'right'}>{t('TEXT_OWED', 'Tilgode')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getAllItemGroups(invoice.lines).map((group: any) => (
                            <>
                                <TableRow key={group}>
                                    <TableCell style={{ fontWeight: 600 }} colSpan={6}>
                                        {group}
                                    </TableCell>
                                </TableRow>
                                {invoice.lines
                                    .filter((l) => {
                                        return l.itemGroupName === group
                                    })
                                    .map((l: any, index) => (
                                        <TableRow key={`${group}_${index}`}>
                                            <TableCell sx={ineIndentedLine}>
                                                {l.itemName}
                                                <SimulatedValue
                                                    year={invoice.year}
                                                    group={group}
                                                    invoices={[invoice]}
                                                />
                                            </TableCell>
                                            <TableCell>{l.description}</TableCell>
                                            <TableCell align={'right'}>{formatCurrencyWithoutCode(l.amount)}</TableCell>
                                            <TableCell />
                                            <TableCell />
                                        </TableRow>
                                    ))}
                            </>
                        ))}
                        <TableRow>
                            <TableCell css={ineProductStyles.totalRow} colSpan={2}>
                                {t('TEXT_TOTAL_FEE', 'Totale avgifter')}
                                <SimulatedValue year={invoice.year} invoices={[invoice]} />
                            </TableCell>
                            <TableCell css={ineProductStyles.totalRow} align={'right'}>
                                {formatCurrencyWithoutCode(invoice.amount)}
                            </TableCell>
                            <TableCell css={ineProductStyles.totalRow} align={'right'}>
                                {getInvoiceSummaryValue(invoice)[0]}
                            </TableCell>
                            <TableCell css={ineProductStyles.totalRow} align={'right'}>
                                {getInvoiceSummaryValue(invoice)[1]}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </StyledIneTable>
            </TableContainer>

            {invoice.estimated && <IneExplanations />}
        </>
    )
}
