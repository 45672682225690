/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { CartItem } from './models/cart'
import { useRemoveFromCartMutation } from './cart.service'
import { formatCadastreNumber, isMainProperty } from '../../utils/cadastre-utils'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { formatNumber } from '../../utils/number-utils'
import { Box } from '@mui/system'

type Props = {
    cartItem: CartItem
    removable?: boolean
}
const styles = {
    product: css`
        margin-bottom: 16px;

        width: 100%;
    `,
    productName: css`
        font-weight: 600;
        font-size: 18px;
    `,
    text: css`
        font-size: 14px;
        padding-right: 36px;
    `,
    inner: css`
        margin-top: 16px;
        width: 100%;
    `,
    bottomLine: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    `,
    priceWrapper: css`
        display: flex;
        align-items: center;
    `,
    price: css`
        font-size: 18px;
        font-weight: 600;
        margin-right: 24px;
    `,
    grid: css`
        display: flex;
        margin-bottom: 20px;
    `,
    noGrid: css`
        display: grid;
        grid-template-columns: auto;
    `,
    cadastreNumber: css`
        font-weight: 600;
        margin-bottom: 10px;
    `,
    helpText: css`
        margin-bottom: 16px;
    `,
    column1: css`
        width: 200px;
    `,
    mr1: css`
        margin-right: 8px;
    `,
}
export const FullWidthCartItem: React.FC<Props> = ({ cartItem, removable = true }) => {
    const { t } = useTranslation()
    const product = cartItem.product

    const [removeFromCart] = useRemoveFromCartMutation()

    return (
        <div css={styles.product}>
            <div css={styles.productName}>{product.name}</div>
            {Object.keys(cartItem.attributes).map((matrikeklNr) => (
                <div key={matrikeklNr} style={{ marginTop: 16 }}>
                    <div key={matrikeklNr} css={styles.grid}>
                        <div css={styles.column1}>
                            {isMainProperty(matrikeklNr)
                                ? t('MAIN_PROPERTY', 'Grunneiendom')
                                : t('TEXT_SECTION', 'Seksjon')}{' '}
                            <div css={styles.cadastreNumber}>{formatCadastreNumber(matrikeklNr)}</div>
                        </div>
                        <div>
                            {cartItem.attributes[matrikeklNr].map((agreement) => (
                                <div key={agreement.agreementNumber}>
                                    <FormControlLabel
                                        control={<Checkbox disabled={true} checked={true} />}
                                        label={`${agreement.name}  (${t('PART_NR', 'Delnr.')} ${agreement.partNumber})`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            {product.type === 'Electricity' &&
                (cartItem.electricityAttributes || []).map((meter) => (
                    <Box mt={2} display="flex" flexDirection="column" gap={1} key={meter.meteringPointId}>
                        <Typography variant="body2">{`${t('TEXT_CART_ITEM_PLANT_OWNER', 'Anleggskunde')}: ${
                            meter.customerName
                        }`}</Typography>
                        {meter.meteringPointId && (
                            <Typography variant="body2">{`${t('TEXT_CART_ITEM_PLANT_METER_ID', 'Målepunkt-ID')}: ${
                                meter.meteringPointId
                            }`}</Typography>
                        )}
                        {meter.meterNumber && (
                            <Typography variant="body2">{`${t('TEXT_CART_ITEM_PLANT_METER_NR', 'Målernummer')}: ${
                                meter.meterNumber
                            }`}</Typography>
                        )}
                    </Box>
                ))}
            <div css={styles.inner}>
                <div css={styles.text}>
                    <span css={styles.mr1}>{`${t('DELIVERED_BY', 'Levert av')}:`}</span>
                    <span>
                        {product.vendorName} {product.tenantName ? `(${product.tenantName})` : null}
                    </span>
                </div>
                <div css={styles.bottomLine}>
                    <div>
                        <div css={styles.text}>
                            <span css={styles.mr1}>{`${t('TEXT_DESCRIPTION', 'Beskrivelse')}:`}</span>
                            <span>{product.description ?? '-'}</span>
                        </div>
                    </div>
                    <div css={styles.priceWrapper}>
                        <div css={styles.price}>{formatNumber(cartItem.cartItemTotalPrice)}</div>
                        {removable && (
                            <div>
                                <IconButton
                                    onClick={() =>
                                        removeFromCart({
                                            productId: product.id,
                                            cadastreId: cartItem.cadastreId,
                                            cadastreNr: cartItem.cadastreNr,
                                        })
                                    }
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
