import React from 'react'
import { IneProductDetails } from '../ine-product/ine-product-details'
import { IneData } from '../models/ine-data.type'
import { ProductWithAttributes } from '../product'
import { isIneProduct } from '../product-helper'
import { Box } from '@mui/material'

type Props = {
    product: ProductWithAttributes
    matrikkelNumber: string
    ineData: IneData
    propertyBuildingNumbers?: number[]
}

export const OsloProduct: React.FC<Props> = ({ product, ineData, matrikkelNumber, propertyBuildingNumbers }) => {
    return (
        <div>
            <Box sx={(theme) => ({ width: '100%', padding: theme.spacing(2, 3, 0, 4) })}>
                {isIneProduct(product) && ineData && (
                    <IneProductDetails
                        data={ineData}
                        matrikkelNumber={matrikkelNumber}
                        propertyBuildingNumbers={propertyBuildingNumbers}
                    />
                )}
            </Box>
        </div>
    )
}
