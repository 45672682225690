/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ValidatorRequired } from '../../utils/validators/validator-required'
import { CubitTextFieldAdapter } from '../general/forms/cubit-text-field-adapter'
import { CubitCheckboxAdapter } from '../general/forms/cubit-checkbox-adapter'
import { EDIT_COMPANY_FORM_ID } from '../company-admin-page/settings/company-edit'
import { getCompanyInvoiceInterval } from './companies-helper'
import { CubitRadioGroupAdapter } from '../general/forms/cubit-radio-group-adapter'
import { CubitCompanySearchAdapter } from '../general/forms/cubit-company-search-adapter'
import { CubitCompany } from './models/cubit-company.type'
import { ADD_NEW_DEPARTMENT_FORM_ID } from './add-department'

type Props = {
    id: string
    initialValues?: any
    onSubmit: any
}
type AddressProps = {
    fieldName: string
    label: string
}
const styles = {
    innerForm: css`
        padding: 10px 0;
    `,
    inlineInputs: css`
        display: flex;
        width: 50%;
    `,
}
const AddressLine: React.FC<AddressProps> = ({ fieldName, label }) => {
    const addressStyles = {
        addressLine: css`
            display: flex;
        `,
        address: css`
            width: 50%;
            margin-right: 24px;
        `,
        postalCode: css`
            width: 15%;
        `,
    }
    const { t } = useTranslation()
    return (
        <div css={addressStyles.addressLine}>
            <div css={addressStyles.address}>
                <Field
                    name={`${fieldName}.addressText`}
                    width="100%"
                    type={'text'}
                    component={CubitTextFieldAdapter}
                    label={label}
                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                    variant="standard"
                />
            </div>
            <div css={addressStyles.postalCode}>
                <Field
                    width="100%"
                    name={`${fieldName}.postalCode`}
                    component={CubitTextFieldAdapter}
                    label={t('TEXT_POStAL_CODE', 'Postnummer')}
                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                    variant="standard"
                />
            </div>
        </div>
    )
}
export const CompanyForm: React.FC<Props> = ({ id, initialValues, onSubmit }) => {
    const { t } = useTranslation()
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{
                setCompanyData: (args, state, utils) => {
                    const company = args[0] as CubitCompany
                    utils.changeValue(state, `name`, () => company.name)
                    utils.changeValue(state, `organizationNr`, () => company.organizationNumber.toString())
                },
            }}
            render={({ form, handleSubmit, values }) => (
                <form id={id} onSubmit={handleSubmit}>
                    <div css={styles.innerForm}>
                        <Field
                            name="company"
                            width="100%"
                            valueChanged={(company: any) => {
                                form.mutators.setCompanyData(company)
                            }}
                            component={CubitCompanySearchAdapter}
                            label={t('TEXT_REALTOR_COMPANY', 'Meglerkontor')}
                            variant="standard"
                        />
                        <Field
                            disabled
                            name="name"
                            width="50%"
                            component={CubitTextFieldAdapter}
                            label={t('TEXT_NAME', 'Navn')}
                            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            variant="standard"
                        />
                        <Field
                            disabled
                            name="organizationNr"
                            width="50%"
                            component={CubitTextFieldAdapter}
                            label={t('TEXT_ORG_NR', 'Org.nummer')}
                            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            variant="standard"
                        />
                        <Field
                            name="email"
                            width="50%"
                            component={CubitTextFieldAdapter}
                            label={t('TEXT_EMAIL', 'E-postadresse')}
                            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            variant="standard"
                        />

                        <AddressLine fieldName={'address'} label={t('TEXT_ADDRESS', 'Addresse')} />
                        {id !== ADD_NEW_DEPARTMENT_FORM_ID && (
                            <>
                                <Field
                                    type={'checkbox'}
                                    name="useDifferentPaymentAddress"
                                    component={CubitCheckboxAdapter}
                                    label={t('TEXT_INVOICE_ADDRESS_DIFFERENT', 'Send faktura til annen adresse')}
                                    variant="standard"
                                />
                                {values.useDifferentPaymentAddress && (
                                    <AddressLine
                                        fieldName={'invoiceAddress'}
                                        label={t('TEXT_INVOICE_ADDRESS', 'Invoice addresse')}
                                    />
                                )}
                            </>
                        )}
                        {/*{(id === ADD_NEW_COMPANY_FORM_ID || id === ADD_NEW_DEPARTMENT_FORM_ID) && (*/}
                        {/*    <>*/}
                        {/*        <Field*/}
                        {/*            name="realtorAdminName"*/}
                        {/*            width="50%"*/}
                        {/*            component={CubitTextFieldAdapter}*/}
                        {/*            label={t('TEXT_REALTOR_ADMIN_NAME', 'Faglig ansvarlig')}*/}
                        {/*            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}*/}
                        {/*            variant="standard"*/}
                        {/*        />*/}
                        {/*        <Field*/}
                        {/*            name="realtorAdminEmail"*/}
                        {/*            width="50%"*/}
                        {/*            component={CubitTextFieldAdapter}*/}
                        {/*            label={t('TEXT_REALTOR_ADMIN_EMAIL', 'Faglig ansvarlig sin e-post')}*/}
                        {/*            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}*/}
                        {/*            variant="standard"*/}
                        {/*        />*/}
                        {/*    </>*/}
                        {/*)}*/}
                        {id === EDIT_COMPANY_FORM_ID && (
                            <>
                                <Field
                                    defaultValue={14}
                                    name="invoiceInterval"
                                    width="75%"
                                    valueIsObject={false}
                                    component={CubitRadioGroupAdapter}
                                    options={getCompanyInvoiceInterval(t)}
                                    label={t('TEXT_INVOICE_INTERVAL', 'Fakturaintervall')}
                                    variant="standard"
                                />
                            </>
                        )}
                    </div>
                </form>
            )}
        />
    )
}
