import { styled } from '@mui/material'
import React from 'react'

type PropertyTableTitleProps = {
    title: string
}

export const PropertyTableTitle: React.FC<PropertyTableTitleProps> = React.memo(({ title }) => {
    return <Container>{title}</Container>
})

PropertyTableTitle.displayName = 'table-title-component'

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.75, 1.25),
    fontSize: '1.25rem',
}))
