/** @jsxImportSource @emotion/react */
import { Invoice } from '../types/invoice.type'
import React from 'react'
import { Config } from '../../../redux/config'
import { Paper } from '@mui/material'
import { useAccessToken } from '../../auth/auth.slice'
import { useTranslation } from 'react-i18next'
import { InvoicePeriod } from './invoice-period'
import { formatDate } from '../../../utils/date-utils'
import { InvoiceStatus } from './invoice-status'
import { css } from '@emotion/react'
import { KotiButtonSecondaryColor } from '../../general/buttons/koti-button-secondary-color'

const styles = {
    paper: css`
        padding: 16px 16px 0 16px;
        margin-top: 16px;
    `,
    wrapper: css`
        display: flex;
        flex-wrap: wrap;
    `,
    block: css`
        margin-right: 40px;
        margin-bottom: 16px;
    `,
    label: css`
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
    `,
    value: css`
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        padding-right: 20px;
    `,
}
type Props = {
    invoice: Invoice
    companyId: string
}

const DownloadInvoiceButton: React.FC<{ invoice: Invoice; companyId: string }> = ({ invoice, companyId }) => {
    const accessToken = useAccessToken()
    const { t } = useTranslation()
    return (
        <div>
            <KotiButtonSecondaryColor
                text={t('download_pdf', 'Last ned i PDF')}
                onClick={() => {
                    const url = `${Config.backendUrl}/v1/invoicing/download-invoice/${companyId}/${invoice.id}`
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${accessToken}`, // Replace with your actual access token
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Download failed')
                            }
                            return response.blob()
                        })
                        .then((blob) => {
                            // Create a temporary URL for the downloaded file
                            const downloadUrl = URL.createObjectURL(blob)

                            // Create a link element and initiate the download
                            const link = document.createElement('a')
                            link.href = downloadUrl
                            link.setAttribute('download', `faktura - ${invoice?.invoiceNumber}.pdf`)
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)

                            // Clean up the temporary URL
                            URL.revokeObjectURL(downloadUrl)
                        })
                        .catch((error) => {
                            console.error('Error downloading file:', error)
                        })
                }}
            />
        </div>
    )
}

export const SingleInvoiceTop: React.FC<Props> = ({ invoice, companyId }) => {
    const { t } = useTranslation()
    return (
        <Paper elevation={0} css={styles.paper}>
            <div css={styles.wrapper}>
                <div css={styles.block}>
                    <div css={styles.label}>{t('invoice_number', 'Fakturanr.')}</div>
                    <div css={styles.value}>{invoice.invoiceNumber}</div>
                </div>
                <div css={styles.block}>
                    <div css={styles.label}>{t('invoice_period', 'Faktureringsperiode')}</div>
                    <div css={styles.value}>
                        <InvoicePeriod invoice={invoice} />
                    </div>
                </div>
                <div css={styles.block}>
                    <div css={styles.label}>{t('invoice_date', 'Fakturert dato')}</div>
                    <div css={styles.value}>{formatDate(invoice.sentDate)}</div>
                </div>
                <div css={styles.block}>
                    <div css={styles.label}>{t('due_date', 'Forfallsdato')}</div>
                    <div css={styles.value}>{formatDate(invoice.dueDate)}</div>
                </div>
                <div css={styles.block}>
                    <div css={styles.label}>{t('TEXT_TOTAL_SUM', 'Totalsum')}</div>
                    <div css={styles.value}>{invoice.totalAmount}</div>
                </div>
                <div css={styles.block}>
                    <div css={styles.label}>{t('TEXT_STATUS', 'Status')}</div>
                    <div css={styles.value}>
                        <InvoiceStatus invoice={invoice} />
                    </div>
                </div>
                <DownloadInvoiceButton invoice={invoice} companyId={companyId} />
            </div>
        </Paper>
    )
}
