/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const styles = {
    multiList: css`
        display: flex;
        align-items: center;
    `,
    icon: css`
        margin-left: 20px;
    `,
    tooltip: css`
        padding: 6px;
    `,
    ownersHeader: css`
        font-style: italic;
    `,
}
type Props = {
    items?: string[]
    owners?: boolean
}

export const MultiStringViewer: React.FC<Props> = ({ items, owners }) => {
    const { t } = useTranslation()

    const capitalizeWords = (string: string) => {
        const capitalizeFirstLetterLowerCase = (word: string) =>
            word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        return string.split(' ').map(capitalizeFirstLetterLowerCase).join(' ')
    }

    if (!items || items.length === 0) {
        return null
    }
    if (items.length === 1) {
        return <span>{capitalizeWords(items[0])}</span>
    }
    return (
        <>
            <span css={styles.multiList}>
                <div>{owners ? capitalizeWords(items[0]) : items[0]}</div>
                <Tooltip
                    title={
                        <div css={styles.tooltip}>
                            {owners && (
                                <div css={styles.ownersHeader}>
                                    {t('TEXT_OWNER', '{{count}} Eier', { count: items.length })}
                                </div>
                            )}
                            {items.map((item) => (
                                <div key={item}>{owners ? capitalizeWords(item) : item}</div>
                            ))}
                        </div>
                    }
                    placement="right-start"
                >
                    <div css={styles.icon}>
                        <div>
                            <MoreHorizIcon />
                        </div>
                    </div>
                </Tooltip>
            </span>
        </>
    )
}
