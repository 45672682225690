import { CompanyUserRole } from '../companies/models/company'
import { Nullable } from '../../utils/types/nullable.type'
import { AuthContext } from '../auth/auth-context.type'

export type User = {
    id: string
    name: string
    invitedName?: string
    email?: string
    role?: CompanyUserRole
    pid?: string
    status: UserStatus
    companies: UserCompany[]
    authContext: AuthContext
    uiFlags: UserFlags[]
}

type UserCompany = {
    companyId: string
    companyName: Nullable<string>
    email: string
    role: string
    status: string
    terms: number
}
export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'invited',
    WaitingForInvite = 'waitingForInvite',
}
export enum UserFlags {
    SearchTutorial = 'search-tutorial',
}
