import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type Props = {
    text: string
    onClick: () => void
    startIcon?: React.ReactNode
}
export const KotiButtonSecondaryColor: React.FC<Props> = ({ text, onClick, startIcon }) => {
    const theme = useTheme()
    return (
        <Button
            variant={'contained'}
            color={'secondary'}
            sx={{
                '.MuiSvgIcon-root': {
                    fill: theme.palette.secondary.dark,
                },
            }}
            startIcon={startIcon}
            onClick={onClick}
        >
            {text}
        </Button>
    )
}
