import { AccessToken, AccessTokenBase } from 'types/access-tokens/access-token'
import { appApi } from '../api'
import { CreateAccessTokenRequest, DeleteAccessTokenRequest } from 'types/access-tokens/access-token-requests'

const accessTokensApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyAccessTokens: builder.query<AccessTokenBase[], { companyId: string }>({
            query: (payload) => ({
                url: `/v1/accessTokens/company/${payload.companyId}`,
                method: 'GET',
            }),
            providesTags: ['AccessTokens'],
        }),

        createAccessToken: builder.mutation<AccessToken, CreateAccessTokenRequest>({
            query: (payload) => ({
                url: '/v1/accessTokens',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['AccessTokens'] : []),
        }),

        deleteAccessToken: builder.mutation<void, DeleteAccessTokenRequest>({
            query: (payload) => ({
                url: '/v1/accessTokens',
                method: 'DELETE',
                params: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accessTokensApi.util.updateQueryData(
                        'getCompanyAccessTokens',
                        { companyId: payload.companyId },
                        (draft) => {
                            return draft.filter((token) => token.id !== payload.id)
                        },
                    ),
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const { useGetCompanyAccessTokensQuery, useCreateAccessTokenMutation, useDeleteAccessTokenMutation } =
    accessTokensApi
