import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
export const reactPlugin = new ReactPlugin()

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
    },
})

export const initAppInsights = () => {
    if (process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY === 'none') {
        console.log('skipping insights')
        return
    }
    appInsights.loadAppInsights()
}
