import { getCompanyName } from '../users/user-helper'
import { useGetProfileQuery } from '../users/users.service'
import { useAppSelector } from '../../redux/hooks'
import { selectId } from './auth.slice'

export const FreshChatWidget: React.FC = () => {
    const userId = useAppSelector(selectId)
    const { data } = useGetProfileQuery(userId, {
        skip: userId === null,
    })
    if (!data) return null
    // @ts-ignore
    window.fcWidget.user.setProperties({
        externalId: data.id,
        firstName: data.name,
        lastName: '',
        role: data.authContext.role,
        entityId: data.authContext.entityId, // user's first name
        entityType: data.authContext.type, // user's first name
        companyName: getCompanyName(data, data.authContext.entityId), // user's first name
    })
    console.log('FreshChatWidget set User')
    return null
}
