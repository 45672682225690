import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import StyledTypography from '../styled/styled-typography'

interface HomeAccordionProps {
    index: number
    title: string
    header: string
    expandedAccordion: number | false
    handleChangeAccordion: (_event: React.SyntheticEvent, isExpanded: boolean) => void
    children: React.ReactNode
    currentImageUrl: string
}

const HomeAccordion: React.FC<HomeAccordionProps> = ({
    index,
    title,
    expandedAccordion,
    handleChangeAccordion,
    header,
    children,
    currentImageUrl,
}) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} item xs={12}>
            <Grid item xs={12}>
                <Accordion
                    expanded={expandedAccordion === index}
                    onChange={handleChangeAccordion}
                    sx={{
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                        borderBottom: `solid 1px ${theme.palette.accent.main}`,
                        '&.MuiPaper-root': {
                            borderRadius: 0,
                        },
                        '&.MuiAccordion-root:before': {
                            height: 0,
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`accordion${index}-content`}
                        id={`accordion${index}-header`}
                        sx={{
                            borderBottom:
                                expandedAccordion === index ? `solid 3px ${theme.palette.accent.main}` : 'none',
                        }}
                    >
                        <StyledTypography variant="h5">{title}</StyledTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledTypography variant="h6">{header}</StyledTypography>
                        <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }}>{children}</StyledTypography>
                        {isMd && (
                            <Grid item xs={12}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'fill',
                                        mt: theme.spacing(2),
                                        transition: 'opacity 0.5s ease-in-out',
                                        opacity: expandedAccordion === index ? 1 : 0,
                                    }}
                                    alt={`related future image ${index + 1}`}
                                    src={currentImageUrl}
                                ></Box>
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default HomeAccordion
