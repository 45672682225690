/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from '@mui/material'

type Props = {
    text: string
    onClick: () => void
    startIcon?: React.ReactNode
}
export const KotiBackButton: React.FC<Props> = ({ text, onClick, startIcon }) => {
    const styles = {
        backButton: css`
            font-size: 14px;
            padding: 0px 16px;
        `,
    }
    return (
        <Button
            sx={{
                '.MuiSvgIcon-root': {
                    fill: '#000',
                },
                '& .MuiButton-startIcon': { marginRight: '16px' },
            }}
            css={styles.backButton}
            startIcon={startIcon}
            onClick={onClick}
        >
            {text}
        </Button>
    )
}
