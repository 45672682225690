import { Launch } from '@mui/icons-material'
import { Box, Button, Checkbox, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { dispatchSubmit } from '../../utils/forms-utils'
import { KotiContainer } from '../general/layout/koti-container'
import { getMinimalRightActions, TopBar } from '../topbar/top-bar'
import { TermsDialog } from '../companies/TermsDialog'
import { useAcceptInviteMutation, useLazyGetCompanyBySecretKeyQuery } from '../../redux/apis/companies-api'
import { formatOrgNumber } from '../companies/companies-helper'
import { QueryStatusView } from '../general/query-status.view'
import StyledContainedButton from '../styled/styled-contained-button'
import { styled } from '@mui/system'
import {
    CompanyInfoContainer,
    ErrorBlock,
    FormContainer,
    FormHeader,
    StyledForm,
    StyledTermsBtnText,
    TermsAndConditionsContainer,
} from './styles'

const FORM_ID = 'FINISH_SIGNUP'

type BlockProps = {
    label: string
    value: any
}

export const TermsBlock: React.FC<BlockProps> = ({ label, value }) => {
    return (
        <BlockContainer>
            <BlockLabel>{label}</BlockLabel>
            <BlockValue>{value}</BlockValue>
        </BlockContainer>
    )
}

export const Terms: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const secretKey = searchParams.get('secretKey')

    const [isOpen, setIsOpen] = useState(false)
    const [agree, setAgree] = useState(false)

    const [getCompany, { data }] = useLazyGetCompanyBySecretKeyQuery()
    const [acceptInvite, { error, isError }] = useAcceptInviteMutation()

    useEffect(() => {
        if (secretKey) {
            getCompany(secretKey)
        }
    }, [getCompany, secretKey])

    const handleOpen = () => {
        setIsOpen((prev) => !prev)
    }

    const onSubmit = async () => {
        if (!secretKey || !data) return

        await acceptInvite({ secretKey }).unwrap()
        navigate('/')
    }

    return (
        <Box sx={{ backgroundColor: '#fff', height: '100vh' }}>
            <TopBar rightActions={getMinimalRightActions()} />
            <KotiContainer>
                <FormContainer container>
                    <FormHeader>{t('TEXT_FINISH_SIGNUP', 'Finish sign up')}</FormHeader>

                    {data && (
                        <Grid item xs={12}>
                            <CompanyInfoContainer>
                                <TermsBlock label={t('TEXT_NAME', 'Navn')} value={data.name} />
                                <TermsBlock
                                    label={t('TEXT_ORG_NR', 'Org. nr.')}
                                    value={formatOrgNumber(data.organizationNr)}
                                />
                                <TermsBlock label={t('TEXT_ADDRESS', 'Adresse')} value={data.address.addressText} />
                                <TermsBlock
                                    label={t('TEXT_POSTAL_CODE', 'Postnummer')}
                                    value={data.address.postalCode}
                                />
                            </CompanyInfoContainer>
                        </Grid>
                    )}
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                            <StyledForm id={FORM_ID} onSubmit={handleSubmit}>
                                <TermsAndConditionsContainer>
                                    <Checkbox
                                        onChange={() => setAgree((prev) => !prev)}
                                        checked={agree}
                                        sx={{ padding: '0' }}
                                    />
                                    <Button onClick={() => handleOpen()}>
                                        <StyledTermsBtnText>
                                            {t(
                                                'TERMS_AND_CONDITIONS',
                                                'Jeg aksepterer koti sine vilkår og betingelser.',
                                            )}
                                        </StyledTermsBtnText>
                                        <Launch />
                                    </Button>
                                </TermsAndConditionsContainer>

                                <StyledContainedButton
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={!agree}
                                    onClick={() => dispatchSubmit(FORM_ID)}
                                    style={{ width: '50%', marginTop: '40px' }}
                                >
                                    {t('TEXT_SEND_REQUEST', 'Send forespørsel')}
                                </StyledContainedButton>

                                <ErrorBlock>
                                    <QueryStatusView error={error} isError={isError} />
                                </ErrorBlock>
                            </StyledForm>
                        )}
                    />
                </FormContainer>
            </KotiContainer>
            <TermsDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => setIsOpen(false)}
                handleTermsAgree={() => setAgree(true)}
            />
        </Box>
    )
}

const BlockContainer = styled('div')(({ theme }) => ({
    color: '#000',
    padding: theme.spacing(2, 0, 1, 0),
    borderBottom: '1px solid #8f8f8f',
}))

const BlockLabel = styled('div')({
    fontSize: '0.75rem',
})

const BlockValue = styled('div')({
    fontSize: '1rem',
})
