const FONT = {
    /**
     * Tomato Grotesk
     */
    TOMATO: 'Tomato Grotesk',
    /**
     * Open Sans, sans-serif
     */
    OPEN_SANS: 'Open Sans, sans-serif',
}

export default FONT
