import { Agreement, Invoice, Line, ListableTransaction } from '../models/ine-data.type'
import { getUniqueList } from '../../../utils/array-utils'
import { formatNumber } from '../../../utils/number-utils'

export type SelectedReceiver = {
    name: string
    partNumber: number
}
export const getInvoiceSummaryValue = (invoice: Invoice): any[] => {
    if (invoice.balance === 0) {
        return [0, 0]
    }
    if (invoice.balance > 0) {
        return [formatNumber(Math.abs(invoice.balance)), 0]
    }
    return [0, formatNumber(Math.abs(invoice.balance))]
}
export const getInvoiceReceivers = (agreements: Agreement[]): SelectedReceiver[] => {
    const receivers = agreements.map((agreement) => agreement.invoiceRecipient.name)

    const uniqueReceivers = getUniqueList(receivers)
    return uniqueReceivers
        .map((r) => {
            const list = agreements
                .filter((a) => a.invoiceRecipient.name === r)
                .sort((a, b) => (a.partNumber < b.partNumber ? -1 : 1))
            return list.map((a) => {
                return {
                    name: r,
                    partNumber: a.partNumber,
                }
            })
        })
        .flat()
}
export const getTerms = (year: string, inv: Invoice[]) => {
    const invoices = inv.filter((i) => year === 'all' || i.year === Number(year))
    const terms = invoices.map((i) => i.term).sort((a, b) => (Number(a) < Number(b) ? -1 : 1))

    return getUniqueList(terms)
}
export const getAllYears = (invoices: Invoice[], all = true) => {
    const years = invoices
        .map((invoice: any) => invoice.year.toString())
        .filter((value: string, index: number, self: any) => self.indexOf(value) === index)
        .sort((a: number, b: number) => (a > b ? -1 : 1))
    if (!all) {
        return years
    }
    return ['all', ...years]
}

export const getAllItemGroups = (lines: Line[]) =>
    lines
        .map((line: Line) => line.itemGroupName)
        .filter((value: string, index: number, self: any) => self.indexOf(value) === index)
        .sort()

export const getYearTotal = (invoices: Invoice[], year: string | number) =>
    invoices
        .filter((i: any) => year === 'all' || i.year.toString() === year.toString())
        .reduce((accumulator: any, invoices: any) => accumulator + invoices.amount, 0)
        .toFixed(2)

export const getPreviousYears = (invoices: any, count: any) => {
    return invoices
        .map((invoice: Invoice) => invoice.year)
        .filter((value: number, index: number, self: any) => self.indexOf(value) === index)
        .slice(-count)
        .reverse()
}

export const getAllPreviousYearsItemsGroups = (invoices: Invoice[], count: number) => {
    const lastYears = invoices
        .map((invoice: Invoice) => invoice.year)
        .filter((value: number, index: number, self: any) => self.indexOf(value) === index)
        .slice(-count)

    const filteredObjects = invoices.filter((invoice: Invoice) => lastYears.includes(invoice.year))

    const distinctValues = filteredObjects
        .flatMap((invoice: Invoice) => invoice.lines.map((line: Line) => line.itemGroupName))
        .filter((value: string, index: number, self: any) => self.indexOf(value) === index)
    return distinctValues
}

export const getYearTotalByGroup = (invoices: Invoice[], group: string, year: number) => {
    const filteredByYear = invoices.filter((i: Invoice) => i.year === year)
    const filteredArray = filteredByYear.reduce((acc: any, invoice: Invoice) => {
        return acc.concat(invoice.lines.filter((line: Line) => line.itemGroupName === group))
    }, [])
    const sum = filteredArray.reduce((acc: number, line: Line) => acc + line.amount, 0)
    return sum
}

export const formatCurrencyWithoutCode = (value: any) => {
    const formatter = new Intl.NumberFormat('no-NO', {
        style: 'decimal',
        minimumFractionDigits: 2,
    })
    return formatter.format(value)
}
export const formatCurrencyWithCode = (value: any) => {
    const formatter = new Intl.NumberFormat('no-NO', {
        style: 'currency',
        currency: 'NOK',
        minimumFractionDigits: 2,
        currencyDisplay: 'code',
    })
    return formatter.format(value)
}

export const filterInvoicesByYear = (year: string, agreement: Agreement) => {
    if (year === 'all') {
        const invoices = [...agreement.invoices]
        return invoices.sort((a, b) => {
            if (a.year > b.year) {
                return -1
            } else if (a.year === b.year) {
                if (a.estimated && !b.estimated) {
                    return -1
                } else if (!a.estimated && b.estimated) {
                    return 1
                } else if (a.term > b.term) {
                    return -1
                } else if (a.term < b.term) {
                    return 1
                } else {
                    return 0
                }
            } else {
                return 1
            }
        })
    }
    return agreement.invoices.filter((i) => i.year.toString() === year)
}
export const aggregateInvoiceTransactions = (invoices: Invoice[]) => {
    const aggregatedInvoices: Invoice[] = []

    invoices.forEach((invoice) => {
        const aggregatedInvoice: Invoice = { ...invoice, transactions: [] }
        let fTransactionAmount = 0
        let fTransactionDate: Date | undefined
        let fTransactionTypeName: string | undefined

        invoice.transactions?.forEach((transaction) => {
            if (transaction.type === 'F') {
                fTransactionAmount += transaction.amount
                fTransactionDate = transaction.date
                fTransactionTypeName = transaction.typeName
            } else {
                aggregatedInvoice.transactions?.push(transaction)
            }
        })

        if (fTransactionAmount > 0) {
            aggregatedInvoice.transactions?.push({
                type: 'F',
                amount: fTransactionAmount,
                date: fTransactionDate,
                typeName: fTransactionTypeName,
                costs: 0,
                interest: 0,
            })
        }

        aggregatedInvoices.push(aggregatedInvoice)
    })

    return aggregatedInvoices
}
export const getTransactionList = (invoices: Invoice[]) => {
    const transactions: ListableTransaction[] = []
    invoices.forEach((invoice) => {
        invoice.transactions?.forEach((transaction) => {
            transactions.push({
                year: invoice.year,
                amount: transaction.amount,
                date: transaction.date?.toString(),
                typeName: transaction.typeName,
                kid: invoice.kid,
                term: invoice.term,
                number: invoice.number,
            })
        })
    })

    return transactions.sort((a, b) => {
        if (a.date === undefined || b.date === undefined) {
            return 0
        }
        return Date.parse(b.date) - Date.parse(a.date)
    })
}
