import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const SearchNoAccess = () => {
    const { t } = useTranslation()

    return (
        <Container>
            <Header>{t('NO_ACCESS_YET', 'Du har ikke tilgang ennå')}</Header>
            <div
                dangerouslySetInnerHTML={{
                    __html: t(
                        'NO_ACCESS_TEXT',
                        'if you are a realtor, use invite link that has been provided in the email in order to pair your account to your company',
                    ),
                }}
            />
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    background: '#bedef1',
    borderRadius: '15px',
    fontSize: '1.25rem',
    padding: theme.spacing(2.5, 5),
}))

const Header = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2.5),
    fontWeight: 600,
    fontSize: '1.375rem',
}))
