import { CubitPriceType, Product } from 'components/products/product'

export const getTotalProductPrice = (product: Product): number => {
    if (product.cubitPriceType === CubitPriceType.Flat) {
        return calculateFlatPrice(product)
    }
    if (product.cubitPriceType === CubitPriceType.Percentage) {
        return calculatePercentagePrice(product)
    }
    return product.basePrice || 0
}

const calculateFlatPrice = ({ basePrice, cubitPrice, vat }: Product) => {
    const productPrice = basePrice + cubitPrice
    const actualVat = vat || 0
    return actualVat === 0 ? productPrice : Math.round(productPrice * (actualVat / 100 + 1) * 100) / 100
}

const calculatePercentagePrice = ({ basePrice, cubitPrice, vat }: Product) => {
    const productPrice = basePrice * (1 + cubitPrice / 100)
    const actualVat = vat || 0
    return actualVat === 0 ? productPrice : Math.round(productPrice * (actualVat / 100 + 1) * 100) / 100
}
