import { Grid } from '@mui/material'
import { styled } from '@mui/system'

export const FormContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: '#f4f9fd',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(6.25, 0),

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2.5, 0),
    },
}))

export const FormHeader = styled('div')(({ theme }) => ({
    fontSize: '1.875rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        padding: theme.spacing(2.5),
    },
}))

export const CompanyInfoContainer = styled('div')({
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '100%',
})

export const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
        width: '90%',
    },
}))

export const TermsAndConditionsContainer = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(5),
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
}))

export const StyledTermsBtnText = styled('span')(({ theme }) => ({
    padding: theme.spacing(0, 0.75),
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
}))

export const ErrorBlock = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2.5),
    color: 'darkred',
}))
