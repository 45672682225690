import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getAuthRedirect } from '../../utils/routes'

import { useOsloLoginMutation } from './auth.service'
import { authActions } from './auth.slice'

import { Loader } from '../general/loader/loader'

const SsoAuthPage: React.FC = () => {

    const [is_loading, set_is_loading] = useState(true)
    const [error, set_error] = useState('')
    const [osloLogin] = useOsloLoginMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    // how to use that in typescript ?
    //const { tenant } = useParams()
    const tenant = useParams().tenant + ''

    const [, code] =
        document.location.search
            .replace('?', '')
            .split('&')
            .map((p) => p.split('='))
            .find(([key]) => key === 'code') || []

    useEffect(() => {

        osloLogin({ tenant, code })
            .unwrap()
            .then((response) => {
                set_is_loading(false)
                dispatch(authActions.setCredentials(response))
                navigate(getAuthRedirect(response.authContext.role))
            })
            .catch((e) => {
                console.error(e)
                set_is_loading(false)
                set_error(`Error happened: ${e}`)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ paddingLeft: '40px' }}>
            {is_loading && <Loader />}

            {error && <div>{t('TEXT_SSO_FAILED', 'Single sign-on failed')}</div>}
        </div>
    )
}

export default SsoAuthPage
