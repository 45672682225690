/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, FormGroup, TextField } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'

const CubitTextFieldAdapter: React.FC<any> = (props: any) => {
    const {
        width,
        input: { id, name, value, onChange, onFocus },
        meta: { touched, error },
        ...rest
    } = props

    return (
        <FormControl css={cubitFormStyles.field}>
            <FormGroup row>
                <TextField
                    style={{ width: width }}
                    id={id || name}
                    error={touched && error ? true : false}
                    helperText={touched && error}
                    {...rest}
                    required={false}
                    name={name}
                    onChange={(event: any) => onChange(event.target.value)}
                    onFocus={onFocus}
                    {...props.input}
                    value={value}
                />
            </FormGroup>
        </FormControl>
    )
}

export { CubitTextFieldAdapter }
