/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Add, Close } from '@mui/icons-material'
import { useTheme, Box, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsOsloGuy } from '../../auth/auth.slice'
import { useAddToCartMutation, useGetCartQuery, useRemoveFromCartMutation } from '../../cart/cart.service'
import { IneProductRealtors } from '../ine-product/ine-product-realtors'
import { ProductWithAttributes, InvoiceReceiver } from '../product'
import { isIneProduct } from '../product-helper'
import StyledContainedButton from '../../styled/styled-contained-button'

type Props = {
    product: ProductWithAttributes
    unitCadastreId: number
    matrikkelNumber: string
    expandable?: boolean
}

const styles = {
    product: css`
        padding: 4px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    `,
    productName: css`
        font-weight: 600;
        font-size: 18px;
    `,
    moreLess: css`
        text-decoration: underline;
        cursor: pointer;
    `,
    text: css`
        font-size: 14px;
        padding-right: 36px;
    `,
    inner: css`
        width: 100%;
        padding: 16px 24px 0 36px;
    `,
    bottomLine: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    `,
    priceWrapper: css`
        display: flex;
        align-items: center;
        margin-left: auto;
    `,
    price: css`
        font-size: 18px;
        font-weight: 600;
        margin-right: 24px;
    `,
    productRight: css`
        display: flex;
        align-items: center;
    `,
}
export const FullWidthProduct: React.FC<Props> = ({ product, unitCadastreId, matrikkelNumber, expandable = true }) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(expandable ? false : true)
    const isOsloGuy = useIsOsloGuy()
    const [productAttributes, setProductAttributes] = useState<Record<string, InvoiceReceiver[]>>({})

    useEffect(() => {
        product.attributes.forEach((attr) => {
            attr.invoiceReceivers.forEach((receiver) => {
                toggleAttribute(attr.matrikeklNr, receiver, true)
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const toggleAttribute = (cadastreNr: string, invoiceReceiver: InvoiceReceiver, insert: boolean) => {
        setProductAttributes((prevState) => {
            if (insert) {
                //check to not add same values multiple times
                if (
                    !prevState[cadastreNr] ||
                    (prevState[cadastreNr] &&
                        !prevState[cadastreNr].find((x) => x.agreementNumber === invoiceReceiver.agreementNumber))
                ) {
                    const newAttributes = [...(prevState[cadastreNr] ?? []), invoiceReceiver]
                    return { ...prevState, [cadastreNr]: newAttributes }
                } else {
                    return { ...prevState }
                }
            } else {
                const newAttributes = prevState[cadastreNr].filter(
                    (x) => x.agreementNumber !== invoiceReceiver.agreementNumber,
                )
                return { ...prevState, [cadastreNr]: newAttributes }
            }
        })
    }

    return (
        <div>
            <div css={styles.product}>
                <div>
                    <div css={styles.productName}> {product.name} </div>
                    {expandable && (
                        <div css={styles.moreLess} onClick={() => setExpanded(!expanded)}>
                            {expanded ? t('show_less', 'Show less') : t('more_info', 'More info')}
                        </div>
                    )}
                </div>
                <div css={styles.productRight}>
                    <div css={styles.priceWrapper}>
                        <div css={styles.price}>{product.price},-</div>
                    </div>
                    <AddToCartButton
                        productAttributes={productAttributes}
                        product={product}
                        unitCadastreId={unitCadastreId}
                        matrikkelNumber={matrikkelNumber}
                    />
                </div>
            </div>
            {expanded && (
                <div css={styles.inner}>
                    {isIneProduct(product) && !isOsloGuy && (
                        <IneProductRealtors
                            product={product}
                            productAttributes={productAttributes}
                            toggleAttribute={toggleAttribute}
                        />
                    )}
                    {!isOsloGuy && (
                        <>
                            <div css={styles.text}>{t('DELIVERED_BY', 'Levert av')}</div>
                            <div css={styles.text}>
                                {product.vendorName} {product.tenantName ? `(${product.tenantName})` : null}
                            </div>
                            <Box mt={1.25}>
                                <div css={styles.text}>{t('TEXT_DESCRIPTION', 'Beskrivelse')}</div>
                                <div css={styles.text}>{product.description ?? '-'}</div>
                            </Box>

                            <div css={styles.bottomLine}>
                                {product.disclaimer && (
                                    <DisclaimerContainer>
                                        <p>
                                            {product.disclaimer
                                                ? `${t('TEXT_DISCLAIMER', 'Ansvarsfraskrivelse:')} ${
                                                      product.disclaimer
                                                  }`
                                                : `${t('TEXT_DISCLAIMER', 'Ansvarsfraskrivelse:')} -`}
                                        </p>
                                    </DisclaimerContainer>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
const AddToCartButton: React.FC<{
    productAttributes: Record<string, InvoiceReceiver[]>
    product: ProductWithAttributes
    unitCadastreId: number
    matrikkelNumber: string
}> = ({ productAttributes, product, unitCadastreId, matrikkelNumber }) => {
    const { t } = useTranslation()
    const cart = useGetCartQuery()
    const theme = useTheme()

    const [addToCart, { isLoading: isAdding }] = useAddToCartMutation()
    const [removeFromCart, { isLoading: isRemoving }] = useRemoveFromCartMutation()
    const hasNonEmptyArray = (obj: Record<string, InvoiceReceiver[]>): boolean => {
        for (const key in obj) {
            if (Array.isArray(obj[key]) && obj[key].length > 0) {
                return true
            }
        }
        return false
    }
    const isProductAdded =
        cart.data &&
        cart.data.items.find(
            (item) =>
                item.productId === product.id &&
                (item.cadastreId === unitCadastreId || !item.cadastreId) &&
                item.cadastreNr === matrikkelNumber,
        )
    return (
        <div>
            <StyledContainedButton
                variant={'contained'}
                disabled={
                    isAdding ||
                    isRemoving ||
                    (!isProductAdded && isIneProduct(product) && !hasNonEmptyArray(productAttributes))
                }
                startIcon={isProductAdded ? <Close /> : <Add />}
                color={'secondary'}
                sx={{
                    background: isProductAdded ? theme.palette.accent.main : theme.palette.accent.light,
                }}
                onClick={() =>
                    isProductAdded
                        ? removeFromCart({
                              productId: product.id,
                              cadastreId: unitCadastreId,
                              cadastreNr: matrikkelNumber,
                          })
                        : addToCart({
                              productId: product.id,
                              cadastreId: unitCadastreId,
                              cadastreNumber: matrikkelNumber,
                              attributes: productAttributes,
                          })
                }
            >
                {isProductAdded ? t('REMOVE_FROM_CART', 'Lagt i handlekurv') : t('ADD_TO_CART', 'Legg i handlekurv')}
            </StyledContainedButton>
        </div>
    )
}
const DisclaimerContainer = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(7),
    fontSize: 14,
    fontStyle: 'italic',
    display: 'flex',
    maxWidth: 'calc(100% - 350px)',

    '& p': {
        fontSize: 'inherit',
        margin: 0,
    },
}))
