import { KotiContainer } from '../general/layout/koti-container'
import { Field, Form } from 'react-final-form'
import { Button } from '@mui/material'
import { useLazySearchCompaniesQuery } from '../../redux/apis/companies-search-api'
import { getCurrentDate } from '../../utils/date-utils'

const getCompanyAddress = (a1: any, a2: any): any => {
    if (a1 && a1.addressText) {
        return {
            AddressText: a1.addressText,
            PostalCode: a1.postalCode,
        }
    }
    if (a2 && a2.addressText) {
        return {
            AddressText: a2.addressText,
            PostalCode: a2.postalCode,
        }
    }
    return {
        AddressText: '',
        PostalCode: '',
    }
}
export const CompanyImport: React.FC = () => {
    const [trigger] = useLazySearchCompaniesQuery()
    const companies: any[] = []
    async function onSubmit(values: any) {
        const orgNumbers = values.orgNumbers.split('\n').map((c: string) => c.trim())
        const orgNames = values.orgNames.split('\n').map((c: string) => c.trim())
        // let signatoriesNames = values.signatoriesNames.split('\n').map((c: string) => c.trim())
        // let signatoriesEmails = values.signatoriesEmails.split('\n').map((c: string) => c.trim())
        for (const orgNumber of orgNumbers) {
            const index: number = orgNumbers.indexOf(orgNumber)
            const on = orgNumber.substring(2)
            if (index < 1000) {
                trigger(on)
                    .unwrap()
                    .then((d: any) => {
                        const dd = d[0]
                        const comp: any = {
                            OrganizationNr: on,
                            UseDifferentPaymentAddress: false,
                            Name: orgNames[index],
                            Email: '',
                            Address: getCompanyAddress(dd.postalAddress, dd.visitingAddress),
                            RealtorAdminName: '',
                            RealtorAdminEmail: '',
                            InvoiceAddress: null,
                            Deleted: null,
                            Created: getCurrentDate(),
                            Updated: getCurrentDate(),
                            ParentId: null,
                            DocumentsSigned: false,
                            Status: 'active',
                            InvoiceInterval: 30,
                        }
                        companies.push(comp)
                    })
                await delay(500)
            }
        }
    }
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
    return (
        <KotiContainer>
            <Form
                keepDirtyOnReinitialize
                initialValues={{}}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form id={'companies'} onSubmit={handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <h4>Org numbers</h4>
                                <Field name={`orgNumbers`} component={'textarea'} />
                            </div>
                            <div>
                                <h4>Org name</h4>
                                <Field name={`orgNames`} component={'textarea'} />
                            </div>
                            <div>
                                <h4>signatories names</h4>
                                <Field name={`signatoriesNames`} component={'textarea'} />
                            </div>
                            <div>
                                <h4>signatories emails</h4>
                                <Field name={`signatoriesEmails`} component={'textarea'} />
                            </div>
                        </div>
                        <Button type="submit" color="primary">
                            Submit
                        </Button>
                    </form>
                )}
            />
        </KotiContainer>
    )
}
