import { TopBar } from '../topbar/top-bar'
import { KotiContainer } from '../general/layout/koti-container'
import React from 'react'
import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const SigningThankYouPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div>
            <TopBar />
            <KotiContainer>
                <Paper sx={(theme) => ({ padding: theme.spacing(2) })} elevation={0}>
                    <Typography>{t('thank_you', 'Takk')}</Typography>
                </Paper>
            </KotiContainer>
        </div>
    )
}
