import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'

type CartState = {
    projectNumbers: { [key: string]: string }
}

const slice = createSlice({
    name: 'cart',
    initialState: {
        projectNumbers: {},
    } as CartState,
    reducers: {
        setProjectNumbers: (state, { payload }: PayloadAction<{ [key: string]: string }>) => {
            state.projectNumbers = payload
        },
    },
})

export default slice.reducer

export const cartActions = slice.actions

export const selectProjectNumbers = (state: RootState) => state.cart.projectNumbers
