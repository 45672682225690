import { Role } from './role'

export type AuthContext = {
    type: ContextType
    entityId: string
    role: Role
}

export enum ContextType {
    Company = 'company',
    User = 'user',
}
