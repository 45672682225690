/** @jsxImportSource @emotion/react */
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { KotiContainer } from '../general/layout/koti-container'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { TopPageTabs } from '../general/tabs/top-page-tabs'
import { MyOrders } from './my-orders'
import { MyCompanyOrders } from './my-company-orders'
import { useAuthContext } from '../auth/auth.slice'
import { SignalR } from '../notifications/signal-r'

export enum OrdersTabs {
    MyOrders = 'myOrders',
    AllOrders = 'allOrders',
}

export const OrdersView: React.FC = () => {
    const context = useAuthContext()
    const { t } = useTranslation()
    const [tab, setTab] = useState(OrdersTabs.MyOrders)
    const [reload, setReload] = useState(OrdersTabs.MyOrders)

    const tabs = [
        { value: OrdersTabs.MyOrders, label: t('TEXT_MY_REPORTS', 'Dine rapporter') },
        { value: OrdersTabs.AllOrders, label: t('TEXT_COMPANIES_REPORTS', 'Ditt meglerkontors rapporter') },
    ]

    const handleNotification = (message: any) => {
        setReload(message)
    }

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <SignalR handleNotification={handleNotification} />
                <Grid item xs={12}>
                    <TopPageTabs tabs={tabs} tab={tab} setTab={setTab} title={t('TEXT_ORDERS', 'Bestillinger')} />
                </Grid>
                {tab === OrdersTabs.MyOrders && <MyOrders reload={reload} />}
                {tab === OrdersTabs.AllOrders && (
                    <MyCompanyOrders companyId={context?.entityId ?? ''} reload={reload} />
                )}
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
