import { FC } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Card, Grid, Link, useTheme } from '@mui/material'
import StyledTypography from '../styled/styled-typography'

interface HomeCardProps {
    header: string
    body: string
    linkText?: string
    linkUrl?: string
}
const HomeCard: FC<HomeCardProps> = ({ header, body, linkText, linkUrl }) => {
    const theme = useTheme()
    return (
        <Grid item xs={12} lg={4}>
            <Card
                sx={{
                    p: theme.spacing(4),
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 0,
                    height: '100%',
                }}
            >
                <StyledTypography
                    bold
                    sx={{
                        fontFamily: 'Tomato Grotesk',
                        pb: theme.spacing(2),
                    }}
                >
                    {header}
                </StyledTypography>
                <StyledTypography
                    sx={{
                        fontFamily: 'Tomato Grotesk',
                        whiteSpace: 'pre-line',
                        pb: theme.spacing(2),
                    }}
                >
                    {body}
                </StyledTypography>
                {linkText && (
                    <Link
                        sx={{ display: 'flex', alignItems: 'center', pb: theme.spacing(4) }}
                        href={linkUrl}
                        underline="always"
                    >
                        <StyledTypography sx={{ pr: theme.spacing(1), fontFamily: 'Tomato Grotesk' }}>
                            {linkText}
                        </StyledTypography>
                        <ArrowForwardIcon />
                    </Link>
                )}
            </Card>
        </Grid>
    )
}

export default HomeCard
