/** @jsxImportSource @emotion/react */
import { Agreement, IneData, InvoiceRecipient } from '../models/ine-data.type'
import { IneProduct } from './ine-product'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { ineProductStyles } from './ine-product-styles'
import { getInvoiceReceivers, SelectedReceiver } from './ine-product-helper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { sortBy } from '../../../utils/array-utils'
import { useIsOsloReadOnly } from 'components/auth/auth.slice'

type Props = {
    data: IneData
    matrikkelNumber: string
    propertyBuildingNumbers?: number[]
}

const styles = {
    wrapper: css`
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0;
    `,
    block: css`
        margin-right: 40px;
        margin-bottom: 16px;
    `,
    label: css`
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
    `,
    value: css`
        font-size: 16px;
        white-space: nowrap;
        padding-right: 20px;
    `,
}
const Details: React.FC<{ agreement: Agreement; propertyBuildingNumbers?: number[] }> = ({
    agreement,
    propertyBuildingNumbers,
}) => {
    const { t } = useTranslation()
    const isOsloReadOnlyUser = useIsOsloReadOnly()

    const getAddress = (r: InvoiceRecipient) => {
        const { addressText1, addressText2, addressText3, postalCode, city, country } = r
        let address = ''
        if (addressText1 && addressText1.length > 0) address += addressText1.trim()
        if (addressText2 && addressText2.length > 0) address += ' ' + addressText2.trim()
        if (addressText3 && addressText3.length > 0) address += ' ' + addressText3.trim()
        if (postalCode) address += ' ' + postalCode.trim()
        if (city) address += ', ' + city.trim()
        if (country.toLowerCase() !== 'norge') address += ', ' + country.trim()
        return address.trim()
    }

    return (
        <div css={styles.wrapper}>
            <div css={styles.block}>
                <div css={styles.label}>{t('USER_NAME', 'Bruksnavn')}</div>
                <div css={styles.value}>{agreement.propertyName}</div>
            </div>
            <div css={styles.block}>
                <div css={styles.label}>{t('INVOICE_ADDRESS', 'Fakturaadresse')}</div>
                <div css={styles.value}>{getAddress(agreement.invoiceRecipient)}</div>
            </div>
            <div css={styles.block}>
                <div css={styles.label}>{t('OWNER_CONTACT', 'Eierkontakt')}</div>
                <div css={styles.value}>
                    {agreement.ownerContact.name}{' '}
                    {getAddress(agreement.ownerContact) ? `(${getAddress(agreement.ownerContact)})` : ''}
                </div>
            </div>
            {!isOsloReadOnlyUser && (
                <div css={styles.block}>
                    <div css={styles.label}>{t('PAYMENT_FORM', 'Betalingsform')}</div>
                    <div css={styles.value}>{agreement.paymentType}</div>
                </div>
            )}
            <div css={styles.block}>
                <div css={styles.label}>{t('PRINCIPAL', 'Oppdragsgiver')}</div>
                <div css={styles.value}>{agreement.clientNumber}</div>
            </div>
            {isOsloReadOnlyUser && propertyBuildingNumbers && (
                <div css={styles.block}>
                    <div css={styles.label}>{t('BUILDING_NUMBER', 'Bygningsnummer')}</div>
                    <div css={styles.value}>
                        {propertyBuildingNumbers.length === 0 ? '-' : propertyBuildingNumbers.join(', ')}
                    </div>
                </div>
            )}
        </div>
    )
}
export const IneProductDetails: React.FC<Props> = ({ data, matrikkelNumber, propertyBuildingNumbers }) => {
    const { t } = useTranslation()
    const receivers = getInvoiceReceivers(data[matrikkelNumber])

    const [invoiceReceiver, setInvoiceReceiver] = useState<SelectedReceiver>(receivers[0])
    const [receiverIndex, setReceiverIndex] = useState<number>(0)
    const handleChangeInvoiceReceiver = (event: any) => {
        const index = event.target.value as number
        setInvoiceReceiver(receivers[index])
        setReceiverIndex(index)
    }
    return (
        <div>
            <FormControl margin="normal" css={ineProductStyles.formControl}>
                <InputLabel css={ineProductStyles.inputLabel} id="ine-receiver-select-label">
                    {t('TEXT_SELECT_RECEIVER', 'Fakturamottaker/avtale')}
                </InputLabel>
                <Select
                    labelId="ine-receiver-select-label"
                    id="ine-receiver-select"
                    value={receiverIndex}
                    onChange={(event) => handleChangeInvoiceReceiver(event)}
                    variant="standard"
                >
                    {sortBy(receivers, 'partNumber', 'asc').map((receiver: SelectedReceiver, index) => (
                        <MenuItem key={`${receiver.name}_${receiver.partNumber}`} value={index}>{`${receiver.name} (${t(
                            'PART_NR',
                            'Delnr.',
                        )} ${receiver.partNumber})`}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {data[matrikkelNumber] &&
                data[matrikkelNumber]
                    .filter((a) => a.invoiceRecipient.name === invoiceReceiver.name)
                    .filter((a) => a.partNumber === invoiceReceiver.partNumber)
                    .map((a) => (
                        <div key={a.agreementNumber}>
                            <Details agreement={a} propertyBuildingNumbers={propertyBuildingNumbers} />
                            <IneProduct agreement={a} />
                        </div>
                    ))}
        </div>
    )
}
