/** @jsxImportSource @emotion/react */
import { Tab, Tabs } from '@mui/material'
import { TabType } from '../../general/tabs/tab.type'
import { ineProductStyles, ineTabsStyles, ineTabStyles } from './ine-product-styles'

type Props = {
    title: string
    tabs: TabType[]
    tab: string
    setTab: (tab: any) => void
}
export const IneProductsTabs: React.FC<Props> = ({ tabs, tab, setTab }) => {
    return (
        <div css={ineProductStyles.ineTabsContainer}>
            <Tabs sx={ineTabsStyles} value={tab} onChange={(event, value) => setTab(value)}>
                {tabs.map((tab, index) => (
                    <Tab key={`${tab.value}_${index}`} css={ineTabStyles} value={tab.value} label={tab.label} />
                ))}
            </Tabs>
        </div>
    )
}
