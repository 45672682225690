import React from 'react'
import { useTranslation } from 'react-i18next'
import { SearchField } from 'components/general/search-field/search-field'
import { styled } from '@mui/material'

type PropertySearchBarProps = {
    onSearch: (query: string) => void
}

export const PropertySearchBar: React.FC<PropertySearchBarProps> = ({ onSearch }) => {
    const { t } = useTranslation()

    return (
        <div>
            <SearchBarWrapper>
                <SearchField
                    label={t('TEXT_SEARCH_HU', 'Search for owner or housing unit number')}
                    onTextChanged={onSearch}
                />
            </SearchBarWrapper>
        </div>
    )
}

const SearchBarWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1.25),
    maxWidth: 600,
}))
