import { BaseEntity } from '../../general/models/base-entity'

export type Company = {
    organizationNr: string
    documentsSigned: boolean
    parentId?: string
    useDifferentPaymentAddress: boolean
    name: string
    email: string
    address: Address
    invoiceAddress?: Address
    realtorAdminName: string
    realtorAdminEmail: string
    status: CompanyStatus
    invoiceInterval: number
    config: CompanyConfig
} & BaseEntity

export type CompanyConfig = {
    permissions: CompanyPermissionsConfig
    apiAccess: CompanyApiTokensConfig
}

export type CompanyPermissionsConfig = {
    hasAccessToIneProducts: boolean
}

export type CompanyApiTokensConfig = {
    isPartner: boolean
    availableScopes: string[]
}

export type Address = {
    addressText: string
    postalCode: string
    postalName?: string
}

export type CompanyCreateRequest = {
    organizationNr: string
    useDifferentPaymentAddress: boolean
    name: string
    email: string
    address: Address
    invoiceAddress: Address
    realtorAdminName: string
    realtorAdminEmail: string
}

export type DepartmentCreateRequest = {
    organizationNr: string
    name: string
    email: string
    address: Address
    realtorAdminName: string
    realtorAdminEmail: string
}

export type CompanyRegisterRequest = {
    id?: string
    organizationNr: string
    address: Address
    name: string
    email?: string
    realtorUserName?: string
    realtorUserEmail?: string
    isRealtorAdmin: boolean
    realtorAdminName: string
    realtorAdminEmail: string
    secretKey: string | null
}

export type AcceptInviteRequest = {
    secretKey: string
}

export type FinishRegistrationRequest = {
    secretKey: string
    id: string
    realtorAdminName: string
    realtorAdminEmail: string
}

export enum CompanyUserRole {
    Admin = 'admin',
    User = 'user',
}

export enum CompanyStatus {
    Active = 'active',
    Inactive = 'inactive',
    WaitingForSigning = 'waitingForSigning',
}
export type CompanyCreateUserRequest = {
    pid: string
    companyId: string
    role: CompanyUserRole
}

export type BaseCompany = Omit<Company, keyof BaseEntity>
