import { useTranslation } from 'react-i18next'
import { AppBar, Box, Toolbar, useTheme } from '@mui/material'
import StyledTypography from '../styled/styled-typography'

const HomeHeader: React.FC = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <AppBar
            sx={{
                backgroundColor: '#f5f2ef',
                color: theme.palette.primary.main,
                boxShadow: 'none',
                mt: theme.spacing(-8),
            }}
            position="static"
        >
            <Toolbar sx={{ justifyContent: { xs: 'flex-end', sm: 'flex-start' } }}>
                <StyledTypography sx={{ pl: theme.spacing(2) }} color="primary">
                    {t('run_by_cubit', 'Run by Cubit')}
                </StyledTypography>
                <Box
                    component="img"
                    sx={{
                        maxWidth: theme.spacing(3),
                        maxHeight: theme.spacing(2),
                        pl: theme.spacing(1),
                    }}
                    alt="Cubit logo"
                    src={'https://cubitblobstorage.blob.core.windows.net/public-images/Cubit_logosymbol.svg'}
                />
            </Toolbar>
        </AppBar>
    )
}

export default HomeHeader
