import { Card, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const SearchNoResults = () => {
    const { t } = useTranslation()

    return (
        <StyledCard elevation={0}>
            <Typography variant="h5" textAlign="center">
                {t('ADDRESS_SEARCH_NO_RESULTS', 'Fant ingen samsvarende resultater')}
            </Typography>
        </StyledCard>
    )
}

const StyledCard = styled(Card)(({ theme }) => ({
    minHeight: 150,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
