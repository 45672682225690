import { appApi } from '../../redux/api'
import { PaginatedResults } from '../general/models/paginated-results'
import { PaginationParams } from '../general/models/pagination-params'
import { HistoryEntry } from './models/history-entry'
import { HistoryFilters } from './models/history-filters'

const historyApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getHistory: builder.query<PaginatedResults<HistoryEntry>, PaginationParams<HistoryEntry, HistoryFilters>>({
            query: (params) => ({
                url: '/v1/history',
                method: 'GET',
                params: params,
            }),
            providesTags: ['History'],
        }),
    }),
})

export const { useGetHistoryQuery, useLazyGetHistoryQuery } = historyApi
