import { KotiTable } from '../../general/table/koti-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortDirection } from '../../general/models/sort-direction'
import { useLazyGetAccessLogsQuery } from './access-log.service'
import { TFunction } from 'i18next'
import { TableStructure } from '../../general/table/table-structure.type'
import { formatDateTime } from '../../../utils/date-utils'
import { AccessLog } from './models/access-log.type'
import { removeMunicipality } from '../../../utils/cadastre-utils'
import { Paper } from '@mui/material'
import { AccessLogFilters } from './access-logs-filters'

const getAccessLogTableStructure = (t: TFunction): TableStructure<AccessLog>[] => [
    {
        label: t('TEXT_DATE', 'Dato'),
        columnName: 'created',
        sortFunction: (object) => object.created.getTime(),
        value: (object) => formatDateTime(object.created),
    },
    {
        label: t('TEXT_USER', 'Bruker'),
        columnName: 'userName',
        sortFunction: (object) => object.userName,
        value: (object) => object.userName,
    },
    {
        label: t('TEXT_EMAIL', 'E-postadresse'),
        columnName: 'userEmail',
        sortFunction: (object) => object.userEmail,
        value: (object) => object.userEmail,
    },

    {
        label: t('TEXT_CADASTRE_NR', 'Matrikkelnr.'),
        columnName: 'cadastreNr',
        sortFunction: (object) => removeMunicipality(object.cadastreNr),
        value: (object) => removeMunicipality(object.cadastreNr),
    },
]
export const AccessLogsTable: React.FC<{ filters: AccessLogFilters }> = ({ filters }) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('created')
    const [direction, setDirection] = useState<SortDirection>('desc')

    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    const [trigger, { data }] = useLazyGetAccessLogsQuery()
    const onSubmit = () => trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, filters])
    if (!data || !data.data) {
        return null
    }
    return (
        <Paper elevation={0}>
            <KotiTable
                pagination={true}
                selectable={false}
                tableStructure={getAccessLogTableStructure(t)}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                rows={data?.data}
                total={data?.total ?? 0}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
        </Paper>
    )
}
