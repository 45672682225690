import { getStandardRightActions, TopBar } from '../../topbar/top-bar'
import React from 'react'
import { KotiContainer } from '../../general/layout/koti-container'
import { BottomPageNavigation } from '../../bottom-navigation/bottom-navigation'
import { Grid } from '@mui/material'
import { CartItems } from './cart-items'
import { InvoiceInfo } from './invoice-info'

export const CheckoutView: React.FC = () => {
    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CartItems />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InvoiceInfo />
                    </Grid>
                </Grid>
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
