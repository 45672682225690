/** @jsxImportSource @emotion/react */
import { SortDirection } from '../general/models/sort-direction'
import { useLazyGetOrdersQuery, useRegenerateOrderMutation } from './orders.service'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux/hooks'
import { selectOrdersFilters } from './order.slice'
import { usePrevious } from '../general/use-previous'
import { Button, IconButton, Paper } from '@mui/material'
import { KotiTable } from '../general/table/koti-table'
import { OrderTableItem } from './models/order'
import { Loader } from '../general/loader/loader'
import { Config } from '../../redux/config'
import { TFunction } from 'i18next'
import { TableStructure } from '../general/table/table-structure.type'
import { formatDateTime, getDifferenceFromNow } from '../../utils/date-utils'
import { Download, Sync } from '@mui/icons-material'
import { getProductList } from './orders-helper'
import { MultiStringViewer } from '../general/multi-viewer/multi-string-viewer'
import TablePagination from '@mui/material/TablePagination'
import { OrdersTableFilters } from './OrdersTableFilters'

const onDownload = (object: OrderTableItem) => {
    const link = document.createElement('a')
    link.href = `${Config.backendUrl}/v1/orders/${object.orderId}/pdf/${object.index}?accessToken=${object.pdf.accessToken}`
    link.click()
}

const getOrdersTableStructure = (t: TFunction, onSubmit: any, regen: any): TableStructure<OrderTableItem>[] => [
    {
        label: t('TEXT_DATE', 'Dato'),
        columnName: 'created',
        sortFunction: (object) => object.created.getTime(),
        value: (object) => formatDateTime(object.created),
    },
    {
        label: t('TEXT_PROJECT_NR', 'Oppdragsnr.'),
        value: (object) => object.note,
    },
    {
        label: t('TEXT_ORDER_NR', 'Ordrenr.'),
        columnName: 'orderId',
        sortFunction: (object) => object.orderNumber,
        value: (object) => object.orderNumber,
    },
    {
        label: t('TEXT_PRODUCT_NAME', 'Produkt'),
        sortFunction: (object) => object.product.name,
        value: (object) => object.product.name,
    },
    {
        label: t('TEXT_ADDRESS', 'Adresse'),
        sortFunction: (object) => (object.address ? object.address.addressText : ''),
        value: (object) =>
            object.address ? <MultiStringViewer items={object.address.addressText.split(',')} owners={false} /> : '',
    },
    {
        label: t('TEXT_POStAL_CODE', 'Postnummer'),
        sortFunction: (object) => object.address.postalCode,
        value: (object) => object.address && `${object.address.postalCode || ''} ${object.address.postalName || ''}`,
    },
    {
        label: t('TEXT_CADASTRE_NR', 'Matrikkelnr.'),
        sortFunction: (object) => object.cadastreNr,
        value: (object) => object.cadastreNr,
    },
    {
        label: t('TEXT_DOWNLOAD', 'Last ned'),
        value: (object) => {
            if (object.pdf !== null) {
                return (
                    <IconButton
                        aria-label={`${t('TEXT_DOWNLOAD', 'Last ned')}`}
                        name={'download'}
                        onClick={(e) => {
                            onDownload(object)
                            e.stopPropagation()
                        }}
                    >
                        <Download aria-label={t('TEXT_DOWNLOAD', 'Last ned')} />
                    </IconButton>
                )
            } else {
                let minutesDiff = 0
                if (object.created) {
                    minutesDiff = getDifferenceFromNow(object.generated ?? object.created, 'minutes')
                }
                if (Math.abs(minutesDiff) > 1) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {t('TEXT_GENERATING_FAILED', 'Failed')}
                            <Button
                                sx={{ borderColor: '#000', minWidth: 'auto', marginLeft: '10px' }}
                                size={'small'}
                                variant={'outlined'}
                                onClick={(e) => {
                                    regen(object.orderId)
                                    e.stopPropagation()
                                }}
                            >
                                <Sync fontSize={'small'} />
                            </Button>
                        </div>
                    )
                }
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {t('TEXT_GENERATING', 'Genereres') + '...'}
                        <Button
                            sx={{ borderColor: '#000', minWidth: 'auto', marginLeft: '10px' }}
                            size={'small'}
                            variant={'outlined'}
                            onClick={(e) => {
                                onSubmit()
                                e.stopPropagation()
                            }}
                        >
                            <Sync fontSize={'small'} />
                        </Button>
                    </div>
                )
            }
        },
    },
]

export const MyOrders: React.FC<{ reload: any }> = ({ reload }) => {
    const { t } = useTranslation()
    const filters = useAppSelector(selectOrdersFilters)
    const prevFilters = usePrevious(filters)

    const [regenerate] = useRegenerateOrderMutation()
    const [trigger, { data }] = useLazyGetOrdersQuery()

    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('created')
    const [direction, setDirection] = useState<SortDirection>('desc')

    const withoutCacheSubmit = () =>
        trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters }, false)

    useEffect(() => {
        withoutCacheSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        if (prevFilters === filters) {
            trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
        }
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, filters, prevFilters])

    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    const onSubmit = () => trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })

    const regen = (orderId: string) => {
        regenerate({ id: orderId })
    }

    if (!data || !data.data) return <Loader />

    const products = getProductList(data.data)

    return (
        <Paper elevation={0}>
            <OrdersTableFilters onSubmit={onSubmit} />
            <KotiTable
                selectable={false}
                pagination={false}
                rowsPerPageOptions={[]}
                tableStructure={getOrdersTableStructure(t, withoutCacheSubmit, regen)}
                rowsPerPage={products.length}
                page={pageNumber}
                rows={products}
                total={products.length}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
            <TablePagination
                labelRowsPerPage={<span>{t('ROWS_PER_PAGE', 'Rader per side')}</span>}
                component="div"
                count={data.total}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                onPageChange={(event, newPage) => setPageNumber(newPage)}
                onRowsPerPageChange={(event) => setItemsPerPage(parseInt(event.target.value))}
            />
        </Paper>
    )
}
