/** @jsxImportSource @emotion/react */
import { IconButton, Paper } from '@mui/material'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Company, CompanyStatus } from './models/company'
import { TableStructure } from '../general/table/table-structure.type'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SearchField } from '../general/search-field/search-field'
import { SortDirection } from '../general/models/sort-direction'
import { companiesActions, CompanyFilterType, selectCompanyFilters, selectSelectedCompanies } from './company.slice'
import { KotiTable } from '../general/table/koti-table'
import { usePrevious } from '../general/use-previous'
import { useLazyGetCompaniesQuery } from '../../redux/apis/companies-api'
import { formatOrgNumber, getCompanyStatusText } from './companies-helper'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Link, useNavigate } from 'react-router-dom'
import { getCompanyRoute } from '../../utils/routes'
import { formatDateTime } from '../../utils/date-utils'
import { companyTableStyles } from './company-table-styles'
import { OpenInNew } from '@mui/icons-material'
import { css } from '@emotion/react'

const styles = {
    status: css`
        display: flex;
        align-items: center;
    `,
    statusCircle: css`
        width: 18px;
        height: 18px;
        border-radius: 9px;
        display: block;
        margin: 0 10px 0 0;
    `,
}
const UserStatusCell: React.FC<{ status: CompanyStatus }> = ({ status }) => {
    const { t } = useTranslation()
    switch (status) {
        case CompanyStatus.Active:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#5fd379' }} />
                    {getCompanyStatusText(t, status)}
                </div>
            )
        case CompanyStatus.Inactive:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#f35b5b' }} />
                    {getCompanyStatusText(t, status)}
                </div>
            )
        case CompanyStatus.WaitingForSigning:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#0000aa' }} />
                    {getCompanyStatusText(t, status)}
                </div>
            )
        default:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#d3c85f' }} />
                    {getCompanyStatusText(t, status)}
                </div>
            )
    }
}
const getProductTableStructure = (t: TFunction): TableStructure<Company>[] => [
    {
        label: t('TEXT_NAME', 'Navn'),
        columnName: 'name',
        sortFunction: (object) => object.name,
        value: (object) => object.name,
    },
    {
        label: t('TEXT_ORG_NR', 'Org.nummer'),
        columnName: 'organizationNr',
        sortFunction: (object) => object.organizationNr,
        value: (object) => {
            if (object.parentId) {
                return (
                    <span>
                        <b>{formatOrgNumber(object.organizationNr)} - Avd.</b>
                    </span>
                )
            }
            return formatOrgNumber(object.organizationNr)
        },
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_ADDRESS', 'Address'),
        columnName: 'address',
        sortFunction: (object) => object.address.addressText,
        value: (object) => `${object.address.addressText} ${object.address.postalCode}`,
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_CREATED', 'Created'),
        columnName: 'created',
        sortFunction: (object) => object.created,
        value: (object) => formatDateTime(object.created),
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_SiGNED', 'Signed'),
        columnName: 'documentsSigned',
        sortFunction: (object) => object.documentsSigned,
        value: (object) => (object.documentsSigned ? t('TEXT_YES', 'Ja') : t('TEXT_NO', 'Nei')),
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_STATUS', 'Status'),
        columnName: 'status',
        sortFunction: (item) => item.status,
        value: (item) => <UserStatusCell status={item.status} />,
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (property) => property.name,
        value: (object) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                        onClick={(event) => event.stopPropagation()}
                        to={`${window.location.origin}/company/${object.id}`}
                    >
                        <IconButton>
                            <OpenInNew />
                        </IconButton>
                    </Link>
                    <KeyboardArrowRightIcon />
                </div>
            )
        },
    },
]

type Props = {
    onSubmit: () => void
}

const TableTop: React.FC<Props> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return (
        <div css={companyTableStyles.paper}>
            <div css={companyTableStyles.searchRow}>
                <div css={companyTableStyles.searchField}>
                    <SearchField
                        onSubmit={onSubmit}
                        label={t('TEXT_SEARCH_COMPANIES', 'Søk...')}
                        onTextChanged={(value) => {
                            dispatch(companiesActions.setFilters({ filter: CompanyFilterType.q, value }))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export const CompanyTable: React.FC = () => {
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('id')
    const [direction, setDirection] = useState<SortDirection>('desc')
    const navigate = useNavigate()
    const selectedItems = useAppSelector(selectSelectedCompanies)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const filters = useAppSelector(selectCompanyFilters)
    const prevFilters = usePrevious(filters)
    const handleOnSelectChange = (selectedItems: Company[]) => {
        dispatch(companiesActions.setSelectedItems(selectedItems))
    }
    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    const [trigger, { data }] = useLazyGetCompaniesQuery()
    const onSubmit = () => trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (prevFilters === filters) {
            trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
        }
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, filters, prevFilters])

    const tableStructure = getProductTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            <TableTop onSubmit={onSubmit} />
            <KotiTable
                onRowClick={(item: Company) => navigate(getCompanyRoute(item))}
                selectedItems={selectedItems}
                tableStructure={tableStructure}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                pagination={true}
                rows={data?.data}
                onSelectChange={handleOnSelectChange}
                total={data?.total ?? 0}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
        </Paper>
    )
}
