import { Cart, CartItem } from './models/cart'

export const groupCartItems = (items: CartItem[]) => {
    const grouped: { [key: string]: CartItem[] } = {}
    items.forEach((item) => {
        if (grouped[item.cadastreNr]) {
            grouped[item.cadastreNr].push(item)
        } else {
            grouped[item.cadastreNr] = []
            grouped[item.cadastreNr].push(item)
        }
    })
    return grouped
}

export const getAddressFromCartItems = (items: CartItem[]) => {
    const item = items[0]
    return item.address.addressText?.split(',')
}

export const getPostalInfo = (items: CartItem[]) => {
    const item = items[0]
    return `${item.address.postalCode} ${item.address.postalCodeName}`
}

export const getTotalForItems = (items: CartItem[]) => {
    return items.reduce((accumulator: any, cartItem: CartItem) => accumulator + cartItem.cartItemTotalPrice, 0)
}

export const getVatAmount = (cartItems: CartItem[]): number => {
    const vatRate = cartItems[0].product.vat / 100 // Convert the VAT rate from integer to percentage
    const vatAmount = cartItems.reduce((sum, item) => sum + (item.product.price / (1 + vatRate)) * vatRate, 0)
    return vatAmount
}

export const getDistinctVATValues = (cartItems: CartItem[]): number[] => {
    const vatSet = new Set<number>()
    cartItems.forEach((item) => {
        vatSet.add(item.product.vat)
    })
    return Array.from(vatSet)
}

export const projectNumbersValid = (data: Cart | undefined, projectNumbers: { [key: string]: string }) => {
    return (
        data &&
        data.items.every((item) => projectNumbers[item.cadastreNr] && projectNumbers[item.cadastreNr].length > 0)
    )
}
