/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ProductFilterType, productsActions, selectProductFilters } from './product.slice'
import { useGetVendorsQuery } from './products.service'
import { KotiAccordion, KotiAccordionDetails, KotiAccordionSummary } from '../general/accordion/koti-accordion'
import { css } from '@emotion/react'

type Props = {
    onSubmit: () => void
}
type FilterRowProps = {
    label: string
    component: React.ReactNode
}
const FilterRow: React.FC<FilterRowProps> = ({ label, component }) => {
    const styles = {
        filterRow: css`
            display: flex;
            align-items: center;
        `,
        filterLabel: css`
            width: 100%;
            padding-right: 16px;
            max-width: 250px;
        `,
        filter: css`
            width: 100%;
            max-width: 250px;
        `,
    }
    return (
        <div css={styles.filterRow}>
            <div css={styles.filterLabel}>{label}</div>
            <div css={styles.filter}>{component}</div>
        </div>
    )
}
export const ProductsFilters: React.FC<Props> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const productFilters = useAppSelector(selectProductFilters)
    const FILTERS_PANEL = 'panel1'
    const [expanded, setExpanded] = React.useState<string | false>('')
    const dispatch = useAppDispatch()
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }
    const { data: vendors = [] } = useGetVendorsQuery()
    const onVendorChange = (event: SelectChangeEvent) => {
        dispatch(productsActions.setProductFilter({ filter: ProductFilterType.vendorId, value: event.target.value }))
        onSubmit()
    }
    return (
        <KotiAccordion expanded={expanded === FILTERS_PANEL} onChange={handleChange(FILTERS_PANEL)}>
            <KotiAccordionSummary>
                <Typography>{t('TEXT_FILTERS', 'Filtre')}</Typography>
            </KotiAccordionSummary>
            <KotiAccordionDetails>
                <FilterRow
                    label={t('TEXT_PRODUCT_VENDOR', 'Produktleverandør')}
                    component={
                        <FormControl fullWidth>
                            <Select
                                variant={'standard'}
                                labelId="select-vendor"
                                id="vendor-filter"
                                value={productFilters.vendorId}
                                onChange={onVendorChange}
                            >
                                <MenuItem value={''}>-</MenuItem>
                                {vendors.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                />
            </KotiAccordionDetails>
        </KotiAccordion>
    )
}
