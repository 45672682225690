import { Hidden, Tab, Tabs } from '@mui/material'
import { TabType } from './tab.type'
import StyledTypography from '../../styled/styled-typography'
import { styled } from '@mui/system'

type Props = {
    title: string
    tabs: TabType[]
    tab: string
    setTab: (tab: any) => void
}

export const TopPageTabs: React.FC<Props> = ({ tabs, tab, title, setTab }) => {
    return (
        <Container>
            <div>
                <Hidden mdDown>
                    <StyledTypography variant="h5" sx={(theme) => ({ margin: theme.spacing(0, 0, 3, 2) })}>
                        {title}
                    </StyledTypography>
                </Hidden>
                <Tabs
                    sx={{
                        '&.MuiTabs-root .MuiTabs-indicator': { backgroundColor: '#000000' },
                    }}
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                >
                    {tabs.map((tab, index) => (
                        <StyledTab key={`${tab.value}_${index}`} value={tab.value} label={tab.label} />
                    ))}
                </Tabs>
            </div>
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(7.5, 5, 0, 0),
    width: '100%',
    margin: theme.spacing(1, 0),
    backgroundColor: '#fff',

    [theme.breakpoints.down('md')]: {
        backgroundImage: 'none',
        padding: 0,
        margin: theme.spacing(0.5, 0),
    },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#000000',
    fontFamily: 'Tomato Grotesk',
    '&.Mui-selected': { color: '#000000' },

    [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
    },
}))
