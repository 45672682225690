import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { KotiContainer } from '../general/layout/koti-container'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { useSearchParams } from 'react-router-dom'
import { useGetPropertyUnitsQuery } from '../search/search.service'
import { getMainPropertyCadastreNumber } from '../../utils/cadastre-utils'
import { Box, CircularProgress, Grid } from '@mui/material'
import { QueryStatusView } from '../general/query-status.view'
import { PropertyViewBuilder } from './PropertyViewBuilder'

export const PropertyView: React.FC = () => {
    const [searchParams] = useSearchParams()
    const cadastreNumber = searchParams.get('cadastreNumber')

    const { data, isSuccess, error, isError, isLoading } = useGetPropertyUnitsQuery({
        cadastreNumber: getMainPropertyCadastreNumber(cadastreNumber),
    })

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            {isLoading && (
                <Box sx={{ height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={52} />
                </Box>
            )}
            <KotiContainer>
                {isSuccess && !isLoading ? (
                    <Grid container spacing={2}>
                        <PropertyViewBuilder units={data.properties} type={data.type} />
                    </Grid>
                ) : null}
                <QueryStatusView error={error} isError={isError} />
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
