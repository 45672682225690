import { Control, Controller, Path, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    FormHelperText,
    Box,
} from '@mui/material'

export interface Option {
    label: string
    value: number | string
    disabled?: boolean
    description?: string
}

interface ControlledSelectProps<T extends FieldValues> extends Omit<SelectProps, 'name' | 'error' | 'onChange'> {
    control: Control<T>
    name: Path<T>
    options: Array<Option> | null
    label?: string
    hideDisabledOptions?: boolean
    inputSuffix?: React.ReactNode
    onChangeCb?: (e: SelectChangeEvent<unknown>) => void
}

export const ControlledMuiSelect = <T extends FieldValues>({
    name,
    label,
    control,
    options,
    onChangeCb,
    hideDisabledOptions,
    fullWidth,
    inputSuffix,
    ...props
}: ControlledSelectProps<T>) => {
    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                    <FormControl fullWidth={fullWidth} error={!!error?.message}>
                        {label && <InputLabel>{label}</InputLabel>}
                        <Select
                            {...rest}
                            {...props}
                            onChange={(e) => {
                                onChangeCb?.(e)
                                rest.onChange(e)
                            }}
                            inputProps={{ ref }}
                            MenuProps={{
                                style: { zIndex: 10000 },
                            }}
                            value={value ? value : props.multiple ? [] : ''}
                            error={Boolean(error?.message)}
                        >
                            {(options || []).map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={option.disabled}
                                    sx={{
                                        display: option.disabled && hideDisabledOptions ? 'none' : 'inherit',
                                    }}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error?.message && (
                            <FormHelperText error sx={{ ml: 0 }}>
                                {t(error.message)}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            {inputSuffix}
        </Box>
    )
}
