import React from 'react'
import { useGetNotificationsQuery } from './userNotifications.service'
import { UserStorageNotification } from './notification.types'
import { Alert, AlertColor, AlertTitle, styled } from '@mui/material'
import { getStoredNotifications, setStoredNotifications } from 'utils/storage-utils'
import { KotiContainer } from '../layout/koti-container'

export const ActiveUserNotifications: React.FC = () => {
    const { data: notifications, isSuccess } = useGetNotificationsQuery({
        activeOnly: true,
        page: 0,
        itemsPerPage: 5,
        sortBy: 'dateFrom',
    })

    const [activeNotifications, setActiveNotifications] = React.useState<UserStorageNotification[]>([])

    React.useEffect(() => {
        if (!isSuccess) return

        const newNotifications: UserStorageNotification[] = []

        const existingNotifications = getStoredNotifications() || []
        // to remove from storage notifications that no longer exist or are expired
        const filteredExistingNotifications = existingNotifications.filter((storageNotification) =>
            notifications.data.some((notification) => notification.id === storageNotification.id),
        )

        for (const notification of notifications.data) {
            const existingNotification = filteredExistingNotifications.find(
                (storageNotification) => storageNotification.id === notification.id,
            )

            if (existingNotification) {
                newNotifications.push(existingNotification)
                continue
            }

            const storageNotification: UserStorageNotification = { ...notification, dismissed: false }
            newNotifications.push(storageNotification)
        }
        const notDismissedNewNotifications = newNotifications.filter((notification) => !notification.dismissed)
        setActiveNotifications(notDismissedNewNotifications)
        setStoredNotifications(newNotifications)
    }, [notifications, isSuccess])

    const handleCloseAlert = (id: string) => {
        const existingNotifications = getStoredNotifications() || []

        const newActiveNotifications = activeNotifications.filter((notification) => notification.id !== id)
        const notificationIdx = existingNotifications.findIndex((notification) => notification.id === id)

        existingNotifications[notificationIdx].dismissed = true

        setStoredNotifications(existingNotifications)
        setActiveNotifications(newActiveNotifications)
    }

    if (!isSuccess || activeNotifications.length === 0) return null

    return (
        <KotiContainer>
            <NotificationsContainer>
                {activeNotifications.map((notification) => (
                    <Alert
                        onClose={notification.isDismissable ? () => handleCloseAlert(notification.id) : undefined}
                        key={notification.id}
                        severity={notification.variant.toLowerCase() as AlertColor}
                    >
                        <AlertTitle sx={{ fontWeight: 600 }}>{notification.title}</AlertTitle>
                        {notification.message}
                    </Alert>
                ))}
            </NotificationsContainer>
        </KotiContainer>
    )
}

const NotificationsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}))
