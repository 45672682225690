/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ValidatorRequired } from '../../utils/validators/validator-required'
import { CubitTextFieldAdapter } from '../general/forms/cubit-text-field-adapter'
import { CubitRadioGroupAdapter } from '../general/forms/cubit-radio-group-adapter'
import { canUserChangeStatus, getStatusOptions, getUserTypeOptions } from './user-helper'
import { CubitSelectAdapter } from '../general/forms/cubit-select-adapter'
import { CompanyUserRole } from '../companies/models/company'
import { CubitCheckboxAdapter } from '../general/forms/cubit-checkbox-adapter'

type Props = {
    id: string
    initialValues?: any
    onSubmit: any
}
const styles = {
    innerForm: css`
        padding: 10px 0;
    `,
    inlineInputs: css`
        display: flex;
        width: 50%;
    `,
}

export const UserForm: React.FC<Props> = ({ id, initialValues, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <form id={id} onSubmit={handleSubmit}>
                    <div css={styles.innerForm}>
                        <Field
                            name="name"
                            width="75%"
                            component={CubitTextFieldAdapter}
                            label={t('TEXT_NAME', 'Navn')}
                            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            variant="standard"
                        />
                        <Field
                            name="email"
                            width="75%"
                            component={CubitTextFieldAdapter}
                            label={t('TEXT_EMAIL', 'E-postadresse')}
                            validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            variant="standard"
                        />
                        {/*{id === 'ADD_NEW_USER' && (*/}
                        {/*    <Field*/}
                        {/*        name="pid"*/}
                        {/*        width="75%"*/}
                        {/*        component={CubitTextFieldAdapter}*/}
                        {/*        label={t('TEXT_PERSONAL_NR', 'Personnummer')}*/}
                        {/*        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}*/}
                        {/*        variant="standard"*/}
                        {/*    />*/}
                        {/*)}*/}
                        {id !== 'ADD_NEW_USER' && canUserChangeStatus(initialValues) && (
                            <Field
                                name={'status'}
                                label={t('TEXT_STATUS', 'Status')}
                                component={CubitRadioGroupAdapter}
                                options={getStatusOptions(t)}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                            />
                        )}
                        <Field
                            defaultValue={CompanyUserRole.User}
                            name="role"
                            width="75%"
                            valueIsObject={false}
                            component={CubitSelectAdapter}
                            options={getUserTypeOptions(t)}
                            label={t('TEXT_USER_TYPE', 'Type')}
                            variant="standard"
                        />
                        {id === 'ADD_NEW_USER' && (
                            <Field
                                type={'checkbox'}
                                name="sendInvite"
                                component={CubitCheckboxAdapter}
                                label={t('DONT_SEND_INVITE', 'Send invite')}
                                variant="standard"
                            />
                        )}
                    </div>
                </form>
            )}
        />
    )
}
