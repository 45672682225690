import { PropertyUnit } from '../components/search/models/property-search-response'

export const contains = (value: string, search: string): boolean =>
    value ? value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 : false
export const containsInList = (list: string[], search: string): boolean => {
    return list && list.length > 0 ? list.some((tag) => contains(tag, search)) : false
}
export const filterHousingUnit = (housingUnits: PropertyUnit[], search: string): boolean => {
    if (search && housingUnits) {
        const words = search.split(' ')
        return housingUnits.every((c) =>
            words.every(
                (word) =>
                    contains(c.address?.addressText ?? '', word) ||
                    contains(c.address?.residentialNumber ?? '', word) ||
                    containsInList(
                        c.owners.map((o) => o.name),
                        word,
                    ),
            ),
        )
    } else {
        return true
    }
}
