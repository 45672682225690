import React from 'react'
import { useSelector } from 'react-redux'
import { selectAuthContext } from '../../auth/auth.slice'
import { useGetCompanyQuery } from '../../../redux/apis/companies-api'
import { Loader } from '../../general/loader/loader'
import { useTranslation } from 'react-i18next'
import { Box, Paper, styled } from '@mui/material'
import { getTotalForItems } from '../cart-helper'
import { useGetCartQuery, useSubmitCartMutation } from '../cart.service'
import { selectProjectNumbers } from '../cart.slice'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ORDERS } from '../../../utils/routes'
import { formatNumber } from '../../../utils/number-utils'
import StyledContainedButton from '../../styled/styled-contained-button'

export const InvoiceInfo: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const context = useSelector(selectAuthContext)
    const projectNumbers = useSelector(selectProjectNumbers)

    const { data: cart, isSuccess } = useGetCartQuery()
    const { data: company } = useGetCompanyQuery(context?.entityId ?? '')
    const [submitCart, { isLoading }] = useSubmitCartMutation()

    const handleSubmit = async () => {
        if (!isSuccess) return

        const notes = cart.items.map((i) => ({ cadastreNr: i.cadastreNr, note: projectNumbers[i.cadastreNr] }))

        await submitCart({ notes }).unwrap()
        navigate(ROUTE_ORDERS)
    }

    if (!company || !cart) {
        return <Loader />
    }

    let address = company.address

    if (company.useDifferentPaymentAddress && company.invoiceAddress) {
        address = company.invoiceAddress
    }

    return (
        <Paper sx={{ px: 4, pb: 4 }} elevation={0}>
            <Heading>{t('INVOICE_ADDRESS', 'Fakturaadresse')}</Heading>
            <div>
                <div>
                    <b>{company.name}</b>
                </div>
                <div>
                    {address.addressText}, {address.postalCode}
                </div>
                <div>{company.email}</div>
            </div>
            <Box my={5}>
                <Heading>{t('INVOICE_AMOUNT', 'Fakturabeløp')}</Heading>
                <Amount>NOK {formatNumber(getTotalForItems(cart.items))}</Amount>
            </Box>
            <Disclaimer
                dangerouslySetInnerHTML={{
                    __html: t(
                        'CHECKOUT_DISCLAIMER',
                        'Vi behandler dine data i samsvar med Koti’s Personvernspolicy.\nVed å fortsette godtar du Koti’s generelle vilkår og salgsbetingelser.',
                    ),
                }}
            />
            <StyledContainedButton
                variant={'contained'}
                color={'secondary'}
                disabled={isLoading}
                sx={{
                    padding: '14px 24px',
                    fontSize: '20px',
                }}
                onClick={handleSubmit}
            >
                {t('FINISH_CHECKOUT', 'Fullfør bestilling')}
            </StyledContainedButton>
        </Paper>
    )
}

const Heading = styled('h2')(({ theme }) => ({
    padding: theme.spacing(2, 0),
    margin: 0,
}))

const Disclaimer = styled('div')(({ theme }) => ({
    fontSize: '0.875rem',
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(5),
}))

const Amount = styled('div')({
    fontSize: '1.25rem',
})
