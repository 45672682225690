/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    useDeleteCompanyUserMutation,
    useLazyGetCompanyUsersQuery,
    useSendInvitationsMutation,
} from '../../redux/apis/companies-api'
import { KotiButtonSecondary } from '../general/buttons/koti-button-secondary'
import { ConfirmDialog } from '../general/dialogs/confirm-dialog'
import { SortDirection } from '../general/models/sort-direction'
import { KotiTable } from '../general/table/koti-table'
import { TableStructure } from '../general/table/table-structure.type'
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { EditUser } from './edit-user'
import { User, UserStatus } from './user'
import { getStatusText, getUserTypeText } from './user-helper'
import { selectSelectedUsers, usersActions } from './users.slice'

type Props = {
    companyId: string
}
type UserStatusProps = {
    status: UserStatus
}
const styles = {
    status: css`
        display: flex;
        align-items: center;
    `,
    statusCircle: css`
        width: 18px;
        height: 18px;
        border-radius: 9px;
        display: block;
        margin: 0 10px 0 0;
    `,
}
const UserStatusCell: React.FC<UserStatusProps> = ({ status }) => {
    const { t } = useTranslation()
    switch (status) {
        case UserStatus.Active:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#5fd379' }} />
                    {getStatusText(t, status)}
                </div>
            )
        case UserStatus.Inactive:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#f35b5b' }} />
                    {getStatusText(t, status)}
                </div>
            )
        case UserStatus.Pending:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#d3c85f' }} />
                    {getStatusText(t, status)}
                </div>
            )
        case UserStatus.WaitingForInvite:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#0000aa' }} />
                    {getStatusText(t, status)}
                </div>
            )
        default:
            return (
                <div css={styles.status}>
                    <span css={styles.statusCircle} style={{ background: '#d3c85f' }} />
                    {getStatusText(t, status)}
                </div>
            )
    }
}
const getUserTableStructure = (t: TFunction, companyId: string): TableStructure<User>[] => [
    {
        label: t('TEXT_NAME', 'Navn'),
        columnName: 'name',
        sortFunction: (item) => item.name,
        value: (item) => item.name,
    },
    {
        label: t('TEXT_ROLE', 'Role'),
        columnName: 'role',
        sortFunction: (item) => item.role,
        value: (item) => getUserTypeText(t, item.role),
    },
    {
        label: t('TEXT_STATUS', 'Status'),
        columnName: 'status',
        sortFunction: (item) => item.status,
        value: (item) => <UserStatusCell status={item.status} />,
    },
    {
        label: t('TEXT_EMAIL', 'Email'),
        columnName: 'email',
        sortFunction: (item) => item.email,
        value: (item) => item.email,
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (user) => user.name,
        value: (user) => <EditUser user={user} companyId={companyId} />,
    },
]
export const UsersTable: React.FC<Props> = ({ companyId }) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortBy, setSortBy] = useState('id')
    const dispatch = useAppDispatch()
    const selectedItems = useAppSelector(selectSelectedUsers)
    const [direction, setDirection] = useState<SortDirection>('desc')
    const [trigger, { data }] = useLazyGetCompanyUsersQuery()
    const tableStructure = getUserTableStructure(t, companyId)

    const [handleDelete] = useDeleteCompanyUserMutation()
    const [handleInvite] = useSendInvitationsMutation()
    let secondaryActions
    if (selectedItems && selectedItems.length > 0) {
        secondaryActions = [
            <ConfirmDialog
                key={'delete_confirm'}
                title={t('DELETE_USER_HEADER', 'Delete users')}
                content={t('DELETE_USER_CONTENT', 'Are you sure you want to delete selected users')}
                onConfirm={() => {
                    handleDelete({ companyId, ids: selectedItems.map((x) => x.id) }).then(() =>
                        dispatch(usersActions.setSelectedUsers([])),
                    )
                }}
            >
                <KotiButtonSecondary text={t('TEXT_DELETE_USER', 'Slett bruker', { count: selectedItems.length })} />
            </ConfirmDialog>,
            <ConfirmDialog
                key={'invite_confirm'}
                title={t('INVITE_USER_HEADER', 'Inviter brukere')}
                content={t(
                    'INVITE_USER_CONTENT',
                    'Trykker du “Send invitasjon” vil valgte brukere få en e-post med link for å fullføre registreringen.',
                )}
                onConfirm={() => {
                    handleInvite({ companyId, userIds: selectedItems.map((x) => x.id) }).then(() =>
                        dispatch(usersActions.setSelectedUsers([])),
                    )
                }}
            >
                <KotiButtonSecondary text={t('TEXT_SEND_INVITES', 'Send invitasjon')} />
            </ConfirmDialog>,
        ]
    }
    const handleOnSelectChange = (selectedItems: User[]) => {
        dispatch(usersActions.setSelectedUsers(selectedItems))
    }
    useEffect(() => {
        trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, companyId })
    }, [trigger, companyId, pageNumber, itemsPerPage, sortBy, direction])
    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }
    return (
        <div>
            <TopBar
                rightActions={getStandardRightActions()}
                secondary={{
                    rightActions: secondaryActions,
                    secondaryText: t('TEXT_USERS_SELECTED', '{{count}} bruker valgt', {
                        count: selectedItems.length,
                    }),
                    onCancel: () => dispatch(usersActions.setSelectedUsers([])),
                }}
            />
            <KotiTable
                selectedItems={selectedItems}
                tableStructure={tableStructure}
                rowsPerPage={itemsPerPage}
                pagination={true}
                page={pageNumber}
                rows={data?.data}
                onSelectChange={handleOnSelectChange}
                selectable={true}
                total={data?.total ?? 0}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
        </div>
    )
}
