import { appApi } from '../../redux/api'
import { AddToCartRequest, Cart, RemoveFromCartRequest, SubmitCartRequest } from './models/cart'

const cartsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getCart: builder.query<Cart, void>({
            query: () => ({
                url: '/v1/carts',
                method: 'GET',
            }),
            providesTags: ['Cart'],
        }),
        addToCart: builder.mutation<void, AddToCartRequest>({
            query: (params) => ({
                url: '/v1/carts/add-item',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Cart'],
        }),
        removeFromCart: builder.mutation<void, RemoveFromCartRequest>({
            query: (params) => ({
                url: '/v1/carts/remove-item',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ['Cart'],
        }),
        submitCart: builder.mutation<void, SubmitCartRequest>({
            query: (params) => ({
                url: '/v1/carts/submit',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Cart', 'Orders'],
        }),
    }),
})

export const { useGetCartQuery, useAddToCartMutation, useRemoveFromCartMutation, useSubmitCartMutation } = cartsApi
