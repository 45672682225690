/** @jsxImportSource @emotion/react */
import { Agreement } from '../models/ine-data.type'
import React, { useEffect } from 'react'
import { ineProductStyles } from './ine-product-styles'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getAllYears } from './ine-product-helper'
import { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { TransactionList } from './transaction-list'

type Props = {
    agreement: Agreement
}

export const IneProductTransactions: React.FC<Props> = ({ agreement }) => {
    const { t } = useTranslation()
    const invoices = agreement.invoices
    const [selectedYear, setSelectedYear] = React.useState<string>(getAllYears(invoices)[0] ?? 'all')
    const handleChangeYear = (event: SelectChangeEvent) => {
        setSelectedYear(event.target.value as string)
    }
    useEffect(() => {
        setSelectedYear(getAllYears(invoices)[0] ?? 'all')
    }, [invoices])
    return (
        <>
            <FormControl margin="normal" css={ineProductStyles.formControl}>
                <InputLabel css={ineProductStyles.inputLabel} id="ine-year-select-label">
                    {t('TEXT_SELECT_YEAR', 'Velg år')}
                </InputLabel>
                <Select
                    labelId="ine-year-select-label"
                    id="ine-year-select"
                    value={selectedYear}
                    onChange={handleChangeYear}
                    variant="standard"
                >
                    {getAllYears(invoices).map((year: any) => (
                        <MenuItem key={year} value={year}>
                            {year === 'all' ? t('TEXT_ALL_YEARS', 'Alle år') : year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div>
                <div style={{ width: '100%', marginBottom: '26px' }}>
                    <div style={{ fontSize: '12px' }}>
                        <TransactionList year={selectedYear} agreement={agreement} />
                    </div>
                </div>
            </div>
        </>
    )
}
