import { Button, FormControl, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAuthRedirect } from '../../utils/routes'
import { useFakeLoginMutation } from './auth.service'
import { authActions } from './auth.slice'
import { Role } from './role'
import { KotiContainer } from '../general/layout/koti-container'
import { TopBar } from '../topbar/top-bar'

export const FakeLoginView: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pid, setPid] = useState('')
    const [password, setPassword] = useState('')
    const [scenario, setScenario] = useState('casual_login')
    const [role, setRole] = useState<Role>(Role.User)
    const [secretKey, setSecretKey] = useState<string>('')
    const [fakeLogin, { isLoading, isError }] = useFakeLoginMutation()

    const handleSubmit = (event: React.SyntheticEvent) => {
        event?.preventDefault()
        fakeLogin({ pid, role, password })
            .unwrap()
            .then((response) => {
                dispatch(authActions.setCredentials(response))
                if (scenario === 'realtor_admin_signup') {
                    navigate(`/sign-up`)
                }
                if (secretKey.length > 0) {
                    if (scenario === 'invited_realtor_user') {
                        navigate(`/terms?secretKey=${secretKey}`)
                    }
                    if (scenario === 'invited_realtor_admin') {
                        navigate(`/finish-sign-up?secretKey=${secretKey}`)
                    }
                }
                if (scenario === 'casual_login') {
                    navigate(getAuthRedirect(response.authContext.role))
                }
            })
    }
    const handleChange = (scenario: string) => {
        setScenario(scenario)
        if (scenario === 'oslo_user_read_only') {
            setRole(Role.OsloReadOnly)
        }
        if (scenario === 'oslo_user') {
            setRole(Role.OsloUser)
        }
        if (scenario === 'oslo_ine') {
            setRole(Role.OsloReadOnlyLimited)
        }
    }
    return (
        <KotiContainer>
            <TopBar />
            <div>
                <FormControl>
                    <RadioGroup
                        value={scenario}
                        name="radio-buttons-group"
                        onChange={(event, value) => {
                            handleChange(value)
                        }}
                    >
                        <FormControlLabel value="casual_login" control={<Radio />} label="I just want to login" />
                        <FormControlLabel
                            value="invited_realtor_user"
                            control={<Radio />}
                            label="I am invited company user (Admin or standard)"
                        />
                        <FormControlLabel
                            value="invited_realtor_admin"
                            control={<Radio />}
                            label="I am first realtor admin and need to finish signing up"
                        />
                        <FormControlLabel
                            value="realtor_admin_signup"
                            control={<Radio />}
                            label="I am Realtor admin and want to signup new company"
                        />
                        <FormControlLabel
                            value="oslo_user"
                            control={<Radio />}
                            label="I want to create/login Oslo user"
                        />
                        <FormControlLabel
                            value="oslo_ine"
                            control={<Radio />}
                            label="I want to create/login Oslo INE user"
                        />
                        <FormControlLabel
                            value="oslo_user_read_only"
                            control={<Radio />}
                            label="I want to create/login Oslo read only user"
                        />
                    </RadioGroup>
                </FormControl>
                <form onSubmit={handleSubmit}>
                    <label>
                        <Typography>
                            <p>everything works. pid, email or random symbols</p>
                        </Typography>
                        <div>
                            <TextField
                                label="Pid"
                                variant="outlined"
                                onChange={(event) => setPid(event.target.value)}
                            />
                        </div>
                        {/*{scenario === 'casual_login' && (*/}
                        {/*    <Select*/}
                        {/*        labelId="demo-simple-select-label"*/}
                        {/*        id="demo-simple-select"*/}
                        {/*        value={role}*/}
                        {/*        label="Role"*/}
                        {/*        onChange={(event) => setRole(event.target.value as Role)}*/}
                        {/*    >*/}
                        {/*        <MenuItem value={Role.User}>{Role.User}</MenuItem>*/}
                        {/*        <MenuItem value={Role.CubitAdmin}>{Role.CubitAdmin}</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*)}*/}
                        {(scenario === 'invited_realtor_user' || scenario === 'invited_realtor_admin') && (
                            <div>
                                <TextField label="secret key" onChange={(event) => setSecretKey(event.target.value)} />
                            </div>
                        )}
                        {/*<div>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={*/}
                        {/*            <Checkbox checked={redirect} onChange={(event) => setRedirect(event.target.checked)} />*/}
                        {/*        }*/}
                        {/*        label="Redirect to terms"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div>
                            <TextField
                                label="Password"
                                name="secret-key"
                                variant="outlined"
                                autoComplete="on"
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </div>
                    </label>
                    <br />
                    <Button type="submit" variant="contained" disabled={isLoading}>
                        Submit
                    </Button>
                    {isLoading ? <Typography>Logging in</Typography> : null}
                    {isError ? <Typography>Log in failed</Typography> : null}
                </form>
            </div>
        </KotiContainer>
    )
}
