/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Add, Download, Remove, RestartAlt, Sync } from '@mui/icons-material'
import { OrderTableItem } from '../models/order'
import { getDifferenceFromNow } from 'utils/date-utils'
import { EXEMPT_ORDER_INVOICE_ID } from 'constants/orders'

type AdminOrdersActionsMenuProps = {
    anchorEl?: null | HTMLElement
    id?: string
    selectedRow?: OrderTableItem
    onClose: () => void
    onExemptAction: (id: string) => void
    onDownloadAction: (id: string) => void
    onRegenerateAction: (id: string) => void
    onRegenerateFailedOrderAction: (id: string) => void
    onRefreshAction: () => void
}

export const AdminOrdersActionsMenu: React.FC<AdminOrdersActionsMenuProps> = ({
    anchorEl,
    onExemptAction,
    onDownloadAction,
    onRegenerateAction,
    onRegenerateFailedOrderAction,
    onRefreshAction,
    onClose,
    id,
    selectedRow,
}) => {
    const { t } = useTranslation()

    if (!selectedRow) return null

    const hasPdf = selectedRow ? selectedRow.pdf !== null : undefined
    const minutesDiff = selectedRow?.created
        ? getDifferenceFromNow(selectedRow.generated ?? selectedRow.created, 'minutes')
        : 0

    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            slotProps={{
                paper: { sx: { minWidth: 200 } },
            }}
        >
            <List sx={{ width: '100%' }}>
                {hasPdf && (
                    <ListItemButton onClick={() => onDownloadAction(id!)}>
                        <ListItemIcon>
                            <Download aria-label={t('TEXT_DOWNLOAD', 'Last ned')} />
                        </ListItemIcon>
                        <ListItemText primary={t('TEXT_DOWNLOAD', 'Last ned')} />
                    </ListItemButton>
                )}
                {!hasPdf && (
                    <>
                        {Math.abs(minutesDiff) > 1 ? (
                            <ListItemButton onClick={() => onRegenerateFailedOrderAction(id!)}>
                                <ListItemIcon>
                                    <Sync aria-label={t('TEXT_GENERATING_FAILED', 'Failed')} />
                                </ListItemIcon>
                                <ListItemText primary={t('TEXT_GENERATING_FAILED', 'Failed')} />
                            </ListItemButton>
                        ) : (
                            <ListItemButton onClick={onRefreshAction}>
                                <ListItemIcon>
                                    <Download aria-label={t('TEXT_GENERATING', 'Genereres')} />
                                </ListItemIcon>
                                <ListItemText primary={t('TEXT_GENERATING', 'Genereres') + '...'} />
                            </ListItemButton>
                        )}
                    </>
                )}

                {selectedRow.invoiceId === EXEMPT_ORDER_INVOICE_ID ? (
                    <ListItemButton onClick={() => onExemptAction(id!)}>
                        <ListItemIcon>
                            <Add aria-label={t('TEXT_UNDO_EXEMPT_ORDER', 'Angre fritatt')} color="success" />
                        </ListItemIcon>
                        <ListItemText primary={t('TEXT_UNDO_EXEMPT_ORDER', 'Angre fritatt')} />
                    </ListItemButton>
                ) : (
                    <ListItemButton onClick={() => onExemptAction(id!)}>
                        <ListItemIcon>
                            <Remove aria-label={t('TEXT_EXEMPT_ORDER', 'Unntatt')} color="error" />
                        </ListItemIcon>
                        <ListItemText primary={t('TEXT_EXEMPT_ORDER', 'Unntatt')} />
                    </ListItemButton>
                )}

                <ListItemButton onClick={() => onRegenerateAction(id!)}>
                    <ListItemIcon>
                        <RestartAlt aria-label={t('TEXT_REGENERATE_ORDER', 'Regenerere')} color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={t('TEXT_REGENERATE_ORDER', 'Regenerere')} />
                </ListItemButton>
            </List>
        </Popover>
    )
}
