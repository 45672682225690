import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLang } from './translation-helper'
import * as nbTranslation from './locale/nb.json'
import * as enTranslation from './locale/en.json'

// https://github.com/i18next/react-i18next/issues/1587#issuecomment-1386909661
declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

export const initTranslation = () => {
    i18n.use(initReactI18next)
        .init({
            lng: 'nb',
            supportedLngs: ['nb', 'en'],
            resources: {
                nb: {
                    translation: nbTranslation,
                },
                en: {
                    translation: enTranslation,
                },
            },
            contextSeparator: '*',
            pluralSeparator: '%%',
            nsSeparator: '::',
        })
        .then(() => i18n.changeLanguage(getLang()))
}
