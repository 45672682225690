export enum HistoryType {
    Updated = 'updated',
    Created = 'created',
    Deleted = 'deleted',
}

export enum HistoryEntityType {
    Company = 'company',
    Product = 'product',
}

export enum HistoryChangeType {
    Updated = 'updated',
    Created = 'created',
    Deleted = 'deleted',
}

export type Change = {
    type: HistoryChangeType
    property: string
    oldValue: string
    newValue: string
}

export type HistoryEntry = {
    changes: Change[]
    created: Date | string
    entityName: string
    relatedEntityId: HistoryEntityType
    entityType: string
    id: string
    type: HistoryType
    meta: any
    userId: string
    userName: string
}
