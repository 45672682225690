import { SearchField } from 'components/general/search-field/search-field'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'redux/hooks'
import { OrderFilterType, ordersActions } from './order.slice'
import { Container, SearchFieldContainer, SearchRow } from 'components/general/table/TableFilters'

type OrdersTableFiltersProps = {
    onSubmit: () => void
}

export const OrdersTableFilters: React.FC<OrdersTableFiltersProps> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    return (
        <Container>
            <SearchRow>
                <SearchFieldContainer>
                    <SearchField
                        onSubmit={onSubmit}
                        label={t('TEXT_SEARCH_COMPANIES', 'Søk...')}
                        onTextChanged={(value) => {
                            dispatch(ordersActions.setFilters({ filter: OrderFilterType.q, value }))
                        }}
                    />
                </SearchFieldContainer>
            </SearchRow>
        </Container>
    )
}
