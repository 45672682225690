import { Box, styled } from '@mui/material'

const LayoutBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    bgcolor: '#f5f2ef',
}))

export default LayoutBox
