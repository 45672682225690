/** @jsxImportSource @emotion/react */
import { infoBlockStyles } from './info-block-styles'

type Props = {
    label: string
    value: any
}
export const InfoLine: React.FC<Props> = ({ label, value }) => {
    return (
        <div css={infoBlockStyles.line}>
            <div css={infoBlockStyles.label}>{label}</div>
            <div css={infoBlockStyles.value}>{value}</div>
        </div>
    )
}
