export const HUMAN_READABLE_COMPANY_CONFIG_KEYS: Record<string, string> = {
    // t('COMPANY_CONFIG_ACCESS_TO_INE_PRODUCT')
    hasAccessToIneProducts: 'COMPANY_CONFIG_ACCESS_TO_INE_PRODUCT',
    // t('COMPANY_CONFIG_IS_PARTNER')
    isPartner: 'COMPANY_CONFIG_IS_PARTNER',
    // t('COMPANY_CONFIG_AVAILABLE_SCOPES')
    availableScopes: 'COMPANY_CONFIG_AVAILABLE_SCOPES',
}

export const TOKEN_AGE_OPTIONS = ['7', '30', '60', '90', 'Never']
