/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { LinearProgress, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { ROUTE_FINISH_SIGN_UP, ROUTE_TERMS } from '../../utils/routes'
import { TopBar } from '../topbar/top-bar'
import { useLoginMutation } from './auth.service'
import { authActions } from './auth.slice'

export const AuthCompanyPage: React.FC = () => {
    const styles = {
        wrapper: css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        `,
        progress: css`
            margin-top: 40px;
        `,
        centered: css`
            max-width: 500px;
            text-align: center;
            font-size: 20px;
            width: 100%;
            @media ${getMobileMediaQuery()} {
            }
        `,
    }

    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const secretKey = searchParams.get('secretKey')
    const userSecretKey = searchParams.get('userSecretKey')

    const [loginUser, { isError, isLoading }] = useLoginMutation()

    useEffect(() => {
        if (token != null) {
            if (secretKey != null) {
                loginUser({ accessToken: token, secret: secretKey })
                    .unwrap()
                    .then((response) => {
                        dispatch(authActions.setCredentials(response))
                        navigate(ROUTE_FINISH_SIGN_UP + `?secretKey=${secretKey}`)
                    })
            } else if (userSecretKey != null) {
                loginUser({ accessToken: token, secret: userSecretKey })
                    .unwrap()
                    .then((response) => {
                        dispatch(authActions.setCredentials(response))
                        navigate(ROUTE_TERMS + `?secretKey=${userSecretKey}`)
                    })
            }
        }
    }, [navigate, token, dispatch, secretKey, userSecretKey, loginUser])

    return (
        <div>
            <TopBar />
            <div css={styles.wrapper}>
                {isLoading ? (
                    <div css={styles.centered}>
                        <span>{t('TEXT_LOGGING_IN', 'Logger deg inn...')}</span>
                        <LinearProgress css={styles.progress} color="inherit" />
                    </div>
                ) : null}
                {isError ? <Typography>{t('TEXT_LOGGING_IN_FAILED', 'Login failed')}</Typography> : null}
            </div>
        </div>
    )
}
