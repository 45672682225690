import React from 'react'
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import { Box, Collapse, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ElectricityProductViewProps } from './ProductDisplay'
import { ProductMainInfo } from './common/ProductMainInfo'
import { ProductContainer } from './common/shared'
import { ProductSecondaryInfo } from './common/ProductSecondaryInfo'
import { SearchField } from 'components/general/search-field/search-field'
import { useLazyGetPlantsQuery } from 'components/search/search.service'
import { DataGridNoRows } from 'components/general/table/dataGrid/DataGridNoRows'
import { ProductPlant } from 'components/products/models/plants'

const calculateRowSelectionModel = (
    product: ElectricityProductViewProps['product'],
    attributes: ElectricityProductViewProps['selectedAttributesFromCart'],
) => {
    if (attributes && attributes.length !== 0) {
        return attributes.map((attribute) => attribute.meteringPointId)
    }
    return product?.electricityAttributes?.length === 1 ? [product.electricityAttributes[0].meteringPointId] : []
}

export const ElectricityProduct: React.FC<ElectricityProductViewProps> = ({
    product,
    onCartButtonClick,
    isInCart,
    isLoading,
    selectedAttributesFromCart,
}) => {
    const { t } = useTranslation()
    const [fetchPlants, { isFetching }] = useLazyGetPlantsQuery({})

    const additionalFetchedAttributes = React.useRef<ProductPlant[]>([])

    const [isExpanded, setIsExpanded] = React.useState(false)
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>(
        calculateRowSelectionModel(product, selectedAttributesFromCart),
    )
    // cant just use useMemo as we do a conditional backend fetch if no rows are found
    const [rows, setRows] = React.useState<ProductPlant[]>(getInitialRows())
    // have to keep paginationState otherwise it just cashes and doesnt change if we fetch additional rows
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: Math.min(rows.length, 100),
        page: 0,
    })

    const columns: GridColDef<ProductPlant>[] = [
        {
            field: 'meteringPointId',
            headerName: t('ELECTRICITY_TABLE_METERING_POINT_ID_HEADER', 'Målepunkt-ID'),
            flex: 1,
            editable: false,
            minWidth: 200,
            maxWidth: 220,
        },
        {
            field: 'meterNumber',
            headerName: t('ELECTRICITY_TABLE_METER_NUMBER_HEADER', 'Målernummer'),
            flex: 1,
            editable: false,
            minWidth: 200,
            maxWidth: 220,
        },
        {
            field: 'customerName',
            headerName: t('ELECTRICITY_TABLE_METER_OWNER_HEADER', 'Anleggskunde'),
            flex: 1,
            editable: false,
            minWidth: 240,
        },
        {
            field: 'address',
            headerName: t('ELECTRICITY_TABLE_ADDRESS_HEADER', 'Adresse'),
            flex: 1,
            editable: false,
            minWidth: 220,
            valueGetter: (params) => params.row.address.addressText || '-',
        },
        {
            field: 'postal',
            headerName: t('ELECTRICITY_TABLE_POSTAL_HEADER', 'Postnummer'),
            flex: 1,
            editable: false,
            minWidth: 120,
            maxWidth: 120,
            valueGetter: (params) => params.row.address.postalCode,
        },
    ]

    function getInitialRows() {
        if (!selectedAttributesFromCart || selectedAttributesFromCart.length === 0) {
            return product.electricityAttributes || []
        }

        const attributesFromAdditionalSearches = selectedAttributesFromCart.filter(
            (cartAttribute) =>
                !product.electricityAttributes?.some(
                    (productAttribute) => productAttribute.meteringPointId == cartAttribute.meteringPointId,
                ),
        )

        // if cart has plants added that were found by additional search
        // we add those plants to initial rows
        if (attributesFromAdditionalSearches.length) {
            additionalFetchedAttributes.current = attributesFromAdditionalSearches

            return [...attributesFromAdditionalSearches, ...(product.electricityAttributes || [])]
        }

        return product.electricityAttributes || []
    }

    const handleCartButtonClick = () => {
        const selectedIDs = new Set(rowSelectionModel)
        const selectedAttributesFromProduct = (product.electricityAttributes || []).filter((row) =>
            selectedIDs.has(row.meteringPointId),
        )
        const selectedAttributesFromAdditionalSearches = additionalFetchedAttributes.current.filter((row) =>
            selectedIDs.has(row.meteringPointId),
        )
        const selectedAttributes = [...selectedAttributesFromProduct, ...selectedAttributesFromAdditionalSearches]

        onCartButtonClick(selectedAttributes)
    }

    const handleQueryChange = async (v: string) => {
        const sanitizedQuery = v.trim().toLowerCase()
        // return all available current rows;
        if (!sanitizedQuery) {
            const newRows = [...additionalFetchedAttributes.current, ...(product.electricityAttributes || [])]
            setRows(newRows)
            setPaginationModel({ page: 0, pageSize: newRows.length })
            return
        }

        const filteredRows = rows.filter(
            (attribute) =>
                attribute.meteringPointId.includes(sanitizedQuery) ||
                attribute.meterNumber.includes(sanitizedQuery) ||
                attribute.customerName.toLowerCase().includes(sanitizedQuery),
        )

        if (filteredRows.length !== 0) {
            setRows(filteredRows)
            setPaginationModel({ page: 0, pageSize: filteredRows.length })
            return
        }

        // currently we only support querying by id or plant number
        const maybeNumber = Number(sanitizedQuery)

        if (isNaN(maybeNumber)) {
            setRows([])
            setPaginationModel((prev) => ({ page: 0, pageSize: prev.pageSize }))
            return
        }

        const payload = await fetchPlants({ query: v, tenantId: product.tenantId }, true).unwrap()

        if (payload.length === 0) {
            setRows([])
            setPaginationModel((prev) => ({ page: 0, pageSize: prev.pageSize }))
            return
        }

        additionalFetchedAttributes.current = [...payload, ...additionalFetchedAttributes.current]
        setRows(payload)
        setPaginationModel({ page: 0, pageSize: payload.length })
    }

    return (
        <ProductContainer className="product-container">
            <ProductMainInfo
                onCartButtonClick={handleCartButtonClick}
                product={product}
                isExpanded={isExpanded}
                onToggleExpand={() => setIsExpanded((prev) => !prev)}
                isInCart={isInCart}
                isCartButtonDisabled={isLoading || rowSelectionModel.length === 0}
                priceOverride={rowSelectionModel.length * product.price}
            >
                <SearchFieldContainer>
                    <SearchField
                        label={t('TEXT_SEARCH_ELECTRICITY_PRODUCT', 'Søk etter eier, målepunkt-ID eller målernummer')}
                        onTextChanged={handleQueryChange}
                    />
                </SearchFieldContainer>
            </ProductMainInfo>

            <Collapse in={isExpanded}>
                <ProductSecondaryInfo product={product} />
            </Collapse>

            <Box mt={4}>
                <Box mb={2}>
                    <Typography variant="body1">
                        {t('ELECTRICITY_PRODUCT_HELP_TEXT', 'Anleggene som er valgt her vil bli tatt med i rapporten.')}
                    </Typography>
                </Box>
                {/* constrain container size */}
                <Box sx={{ height: Math.min(120 + rows.length * 52, 600) + 'px', width: '100%' }}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-row:last-of-type > .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                        }}
                        rows={rows}
                        rowCount={rows.length}
                        columns={columns}
                        paginationModel={paginationModel}
                        getRowId={(row) => row.meteringPointId}
                        checkboxSelection
                        disableRowSelectionOnClick
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        hideFooter
                        hideFooterPagination
                        onRowSelectionModelChange={(ids) => setRowSelectionModel(ids)}
                        rowSelectionModel={rowSelectionModel}
                        isRowSelectable={() => !isInCart}
                        slots={{
                            noRowsOverlay: DataGridNoRows,
                        }}
                        loading={isFetching}
                        keepNonExistentRowsSelected
                    />
                </Box>
            </Box>
        </ProductContainer>
    )
}

const SearchFieldContainer = styled('div')({
    marginRight: 'auto',
    maxWidth: 420,
    flexGrow: 1,
    alignSelf: 'flex-start',
})
