import { Grid } from '@mui/material'
import { OsloUserUnitsList } from 'components/property/propertyViews/oslo/oslo-user-units-list'
import { PropertyResponseItem } from 'components/search/models/property-search-response'
import { PropertyTop } from './property-top'

// TODO: if isy pro aktiv api would work, we could refactor this ...
export const OsloUserPropertyView: React.FC<{ units: PropertyResponseItem[] }> = ({ units }) => {
    return (
        <>
            <Grid item xs={12}>
                <PropertyTop data={units} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OsloUserUnitsList units={units} />
            </Grid>
        </>
    )
}
