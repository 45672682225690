import LayoutBox from '../styled/layout-box'
import HomeFooter from './home-footer'
import HomeHeader from './home-header'
import HomeView from './home.view'

const HomeLayout: React.FC = () => {
    return (
        <LayoutBox>
            <HomeHeader />
            <HomeView />
            <HomeFooter />
        </LayoutBox>
    )
}

export default HomeLayout
