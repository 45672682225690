import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
export const companyTableStyles = {
    paper: css`
        padding: 16px;
    `,
    productFilters: css`
        margin: 16px 0 16px 0;
    `,
    searchRow: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media ${getMobileMediaQuery()} {
            flex-direction: column;
            align-items: flex-start;
        }
    `,
    searchField: css`
        width: 60%;
        @media ${getMobileMediaQuery()} {
            width: 100%;
            margin-bottom: 16px;
        }
    `,
}
