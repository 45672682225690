/** @jsxImportSource @emotion/react */
import { Paper } from '@mui/material'
import { AddDepartment } from '../companies/add-department'
import { DepartmentsTable } from '../companies/departments-table'
import React from 'react'

type Props = {
    companyId?: string
}
export const CompanyAdminDepartmentsView: React.FC<Props> = ({ companyId }) => {
    return (
        <Paper elevation={0} square={true}>
            <AddDepartment />

            <DepartmentsTable companyId={companyId} />
        </Paper>
    )
}
