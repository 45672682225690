import { Link, styled } from '@mui/material'

const StyledFooterLink = styled(Link)(({ theme }) => ({
    textDecoration: 'underline',
    color: theme.palette.secondary.light,
    '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.dark,
    },
}))

export default StyledFooterLink
