import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { RealtorUsersFilters, RealtorUsersFiltersView } from '../realtors-users/realtor-users-filters'
import { RealtorUsersTable } from '../realtors-users/realtor-users-table'

export const RealtorUsersView: React.FC = () => {
    const [filters, setFilters] = useState<RealtorUsersFilters>({})
    const onSubmit = (values: any) => {
        setFilters({
            role: values.role,
            company: values.company ? values.company.id : undefined,
        })
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RealtorUsersFiltersView onSubmit={onSubmit} filters={filters} />
                </Grid>
                <Grid item xs={12}>
                    <RealtorUsersTable filters={filters} />
                </Grid>
            </Grid>
        </div>
    )
}
