import { useGetCartQuery } from '../cart.service'
import { useSelector } from 'react-redux'
import { selectProjectNumbers } from '../cart.slice'
import { Loader } from '../../general/loader/loader'
import {
    getAddressFromCartItems,
    getDistinctVATValues,
    getPostalInfo,
    getTotalForItems,
    getVatAmount,
    groupCartItems,
    projectNumbersValid,
} from '../cart-helper'
import { KeyboardArrowDown } from '@mui/icons-material'
import { MultiStringViewer } from '../../general/multi-viewer/multi-string-viewer'
import { Box, Divider, Paper, styled } from '@mui/material'
import { FullWidthCartItem } from '../full-width-cart-item'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CART } from '../../../utils/routes'
import { formatNumber } from '../../../utils/number-utils'

export const CartItems: React.FC = () => {
    const cart = useGetCartQuery()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [opened, setOpened] = useState<string[]>([])
    const projectNumbers = useSelector(selectProjectNumbers)

    const handleOpened = (cadastreId: string) => {
        if (opened.includes(cadastreId)) {
            setOpened(opened.filter((o) => o !== cadastreId))
        } else {
            setOpened([...opened, cadastreId])
        }
    }

    useEffect(() => {
        if (cart.data) {
            const isValid = projectNumbersValid(cart.data, projectNumbers)
            if (!isValid) {
                navigate(ROUTE_CART)
            }
        }
    }, [cart.data, projectNumbers, navigate])

    if (!cart.data) {
        return <Loader />
    }

    const grouped = groupCartItems(cart.data.items)

    return (
        <Paper elevation={0}>
            <CardTitle>{t('TEXT_CART', 'Handlekurv')}</CardTitle>
            {Object.keys(grouped).map((key, index) => {
                const isOpened = !opened.includes(key)
                return (
                    <div key={key + index}>
                        <PropertyTitle onClick={() => handleOpened(key)}>
                            <div
                                style={{
                                    marginRight: 20,
                                    height: 24,
                                    transform: `rotate(${isOpened ? 180 : 0}deg)`,
                                }}
                            >
                                <KeyboardArrowDown />
                            </div>
                            <Box mr={5}>
                                <ValueContainer>{key}</ValueContainer>
                                <ValueContainer>
                                    {getAddressFromCartItems(grouped[key])?.[0] ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MultiStringViewer
                                                items={getAddressFromCartItems(grouped[key])}
                                                owners={false}
                                            />
                                            <div style={{ marginLeft: 10 }}>{getPostalInfo(grouped[key])}</div>
                                        </div>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </ValueContainer>
                            </Box>
                            <PropertyTotal>{getTotalForItems(grouped[key])}</PropertyTotal>
                        </PropertyTitle>
                        {isOpened && (
                            <div>
                                <Box sx={{ margin: '22px 0 16px' }}>
                                    <Box sx={{ px: 4 }}>
                                        <ProjectNumberLabel>
                                            {t('TEXT_PROJECT_NUMBER', 'Oppdragsnummer')}
                                        </ProjectNumberLabel>
                                        {projectNumbers[key]}
                                    </Box>
                                </Box>
                                <Divider style={{ marginBottom: 16 }} />
                                <Box sx={{ px: 4 }}>
                                    {grouped[key].map((cartItem, index) => {
                                        return <FullWidthCartItem key={index} removable={false} cartItem={cartItem} />
                                    })}
                                </Box>
                                <Divider style={{ margin: '16px 0' }} />
                            </div>
                        )}
                    </div>
                )
            })}
            <VatContainer>
                <div>{t('TEXT_ITEM_COUNT', '{{count}} vare', { count: cart.data.items.length })}</div>
                <div>{formatNumber(getTotalForItems(cart.data.items) - getVatAmount(cart.data.items))}</div>
            </VatContainer>
            <VatContainer>
                <div>
                    {t('TEXT_VAT', 'MVA')} {getDistinctVATValues(cart.data.items).join(', ')}%
                </div>
                <div>{formatNumber(getVatAmount(cart.data.items))}</div>
            </VatContainer>
            <CartTotal>
                <div>
                    <b>{t('TEXT_TOTAL_SUM', 'Sum ink. MVA')}</b>
                </div>
                <div>
                    <b>{formatNumber(getTotalForItems(cart.data.items))}</b>
                </div>
            </CartTotal>
        </Paper>
    )
}

const CardTitle = styled('h2')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    fontSize: '1.375rem',
    fontWeight: 600,
    marginBottom: theme.spacing(4.5),
}))

const PropertyTitle = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    background: '#bedef1',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
}))

const ProjectNumberLabel = styled('div')({
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.55)',
})

const ValueContainer = styled('div')({
    fontSize: '1rem',
    color: '#000',
})

const PropertyTotal = styled('div')({
    fontSize: '22px',
    marginLeft: 'auto',
})

const CartTotal = styled('div')({
    padding: '34px 16px 40px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '22px',
})

const VatContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2, 0, 2),
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
}))
