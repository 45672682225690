import { css } from '@emotion/react'
export const searchFieldStyles = {
    searchFieldContainer: css`
        margin: 0 0 2px 0;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0;
        background-color: #fff;
    `,
}
export const searchFieldInputBase = {
    ml: 1,
    flex: 1,
    margin: 0,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '5px 0 5px 5px',
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    '&.Mui-focused': {
        border: '1px solid rgba(0, 0, 0, 0.5)',
    },
}
