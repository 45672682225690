import { Edit } from '@mui/icons-material'
import { Button, Typography, styled } from '@mui/material'
import { CompanyConfig } from 'components/companies/models/company'
import { HUMAN_READABLE_COMPANY_CONFIG_KEYS } from 'constants/company-config'
import { useTranslation } from 'react-i18next'

// doesnt handle complex types
const changeValueToReadable = (value: unknown): string => {
    if (typeof value === 'boolean' || typeof value === 'undefined') return value ? 'TEXT_YES' : 'TEXT_NO'
    if (typeof value === 'number' || typeof value === 'string') return value.toString()

    // for now we assume that config holds array of primitives
    if (Array.isArray(value)) return value.join(' ')
    throw new Error('Not implemented')
}

type SectionFormDisplayProps = {
    form: CompanyConfig[keyof CompanyConfig]
    onEnableEdit: () => void
}

export const SectionFormDisplay: React.FC<SectionFormDisplayProps> = ({ form, onEnableEdit }) => {
    const { t } = useTranslation()

    return (
        <div>
            <DisplayContainer>
                {Object.entries(form).map(([key, value]) => {
                    const readableValue = changeValueToReadable(value)
                    return (
                        <Typography key={key} variant="body2">{`${t(HUMAN_READABLE_COMPANY_CONFIG_KEYS[key])} - ${t(
                            readableValue,
                        )}`}</Typography>
                    )
                })}
            </DisplayContainer>

            <ActionsContainer>
                <Button
                    onClick={onEnableEdit}
                    sx={{ minWidth: 120 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                >
                    {t('TEXT_EDIT')}
                </Button>
            </ActionsContainer>
        </div>
    )
}

const ActionsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
}))

const DisplayContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}))
