import { useTranslation } from 'react-i18next'
import css from './loader.module.css'

type Props = {
    text?: string
}
export const Loader: React.FC<Props> = ({ text }) => {
    const { t } = useTranslation()
    return (
        <div className={css.container}>
            <div className={css.loader} />
            <div>{text ?? t('TEXT_LOADING_PRODUCTS', 'Laster inn produkt(er). Vent litt...')}</div>
        </div>
    )
}
