/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material'
import { css } from '@emotion/react'

type Props = {
    text: string
}
export const KotiTableHeader: React.FC<Props> = ({ text }) => {
    const styles = {
        kotiTableHeader: css`
            margin-bottom: 16px;
        `,
    }
    return (
        <Typography css={styles.kotiTableHeader} variant={'h5'}>
            {text}
        </Typography>
    )
}
