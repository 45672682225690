import Typography from '@mui/material/Typography'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useTranslation } from 'react-i18next'

const isFetchBaseQueryError = (error?: FetchBaseQueryError | SerializedError): error is FetchBaseQueryError => {
    return error != null && 'status' in error
}

const isErrorTranslatable = (data?: unknown): data is { translationKey: string; title: string } => {
    return data != null && typeof data === 'object' && 'translationKey' in data
}

const isString = (value: unknown): value is string => {
    return typeof value === 'string' || value instanceof String
}

type Props = {
    error?: FetchBaseQueryError | SerializedError
    isError: boolean
}

export const QueryStatusView: React.FC<Props> = ({ error, isError }) => {
    const { t } = useTranslation()

    if (!isError) {
        return null
    }

    if (isFetchBaseQueryError(error)) {
        if (isErrorTranslatable(error.data)) {
            return <Typography>{t(error.data.translationKey, error.data.title)}</Typography>
        }
        if (isString(error.data)) {
            return <pre>{error.data}</pre>
        }
    }

    return null
}
