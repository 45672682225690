/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Launch } from '@mui/icons-material'
import { Button, Checkbox, Grid } from '@mui/material'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { dispatchSubmit } from '../../utils/forms-utils'
import { ValidatorRequired } from '../../utils/validators/validator-required'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { CubitCompanySearchAdapter } from '../general/forms/cubit-company-search-adapter'
import { CubitRadioGroupAdapter } from '../general/forms/cubit-radio-group-adapter'
import { CubitTextFieldAdapter } from '../general/forms/cubit-text-field-adapter'
import { KotiContainer } from '../general/layout/koti-container'
import { QueryStatusView } from '../general/query-status.view'
import { getMinimalRightActions, TopBar } from '../topbar/top-bar'
import { useLazyGetCompanyBySecretKeyQuery, useRegisterCompanyMutation } from '../../redux/apis/companies-api'
import { CompanyRegisterRequest } from './models/company'
import { TermsDialog } from './TermsDialog'
import StyledContainedButton from '../styled/styled-contained-button'

const getRealtorAdminOptions = (t: TFunction) => {
    return [
        { label: t('TEXT_NO', 'Nei'), value: false },
        { label: t('TEXT_YES', 'Ja'), value: true },
    ]
}
export const CompanyRegister: React.FC = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const secretKey = searchParams.get('secretKey')
    const [isOpen, setIsOpen] = useState(false)
    const [agree, setAgree] = useState(false)

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }
    const styles = {
        innerForm: css`
            padding: 10px 0;
            width: 100%;
        `,
        adminInfo: css`
            font-weight: bold;
            margin: 16px 0;
        `,
        formHeader: css`
            font-size: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            font-weight: bold;
            padding-bottom: 40px;
            @media ${getMobileMediaQuery()} {
                font-size: 20px;
                padding-bottom: 20px;
            }
        `,
        formContainer: css`
            background-color: #f4f9fd;
            display: flex;
            justify-content: center;
            padding: 50px 0;
            @media ${getMobileMediaQuery()} {
                padding: 20px 0;
            }
        `,
        form: css`
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media ${getMobileMediaQuery()} {
                width: 90%;
            }
        `,
        termsAndConditions: css`
            padding-top: 40px;
            display: flex;
            align-self: flex-start;
            justify-content: flex-start;
        `,
    }
    const { t } = useTranslation()
    const FORM_ID = 'REGISTER_COMPANY'
    const [getCompany] = useLazyGetCompanyBySecretKeyQuery()
    useEffect(() => {
        if (secretKey) {
            getCompany(secretKey)
        }
    }, [getCompany, secretKey])
    const [registerCompany, { isLoading, error, isError }] = useRegisterCompanyMutation()
    const onSubmit = (values: any) => {
        const request: CompanyRegisterRequest = {
            id: values.id,
            organizationNr: values.company.organizationNumber.toString(),
            name: values.company.name,
            email: values.company.email,
            realtorUserName: values.realtorUserName,
            realtorUserEmail: values.realtorUserEmail,
            isRealtorAdmin: values.isRealtorAdmin,
            realtorAdminName: values.isRealtorAdmin ? values.realtorUserName : values.realtorAdminName,
            realtorAdminEmail: values.isRealtorAdmin ? values.realtorUserEmail : values.realtorAdminEmail,
            address: values.company.visitingAddress,
            secretKey: secretKey,
        }
        registerCompany(request)
            .unwrap()
            .then((company) => {
                navigate(`/company/${company.id}`)
            })
    }
    return (
        <div style={{ backgroundColor: '#fff', height: '100vh' }}>
            <TopBar rightActions={getMinimalRightActions()} />
            <KotiContainer>
                <Grid css={styles.formContainer} container>
                    <div css={styles.formHeader}>{t('REGISTER_REALTOR_COMPANY', 'Registrer ditt meglerkontor')}</div>

                    <Form
                        initialValues={{ isRealtorAdmin: true }}
                        onSubmit={onSubmit}
                        render={({ handleSubmit, values }) => (
                            <form css={styles.form} id={FORM_ID} onSubmit={handleSubmit}>
                                <div css={styles.innerForm}>
                                    <Field
                                        name="company"
                                        width="100%"
                                        component={CubitCompanySearchAdapter}
                                        label={t('TEXT_REALTOR_COMPANY', 'Meglerkontor')}
                                        variant="standard"
                                    />
                                    <Field
                                        name="realtorUserName"
                                        width="100%"
                                        component={CubitTextFieldAdapter}
                                        label={t('TEXT_NAME', 'Navn')}
                                        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                        variant="standard"
                                    />
                                    <div style={{ marginBottom: '40px', width: '100%' }}>
                                        <Field
                                            name="realtorUserEmail"
                                            width="100%"
                                            component={CubitTextFieldAdapter}
                                            label={t('TEXT_EMAIL', 'E-postadresse')}
                                            validate={(value) =>
                                                ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                                            }
                                            variant="standard"
                                        />
                                    </div>

                                    <Field
                                        name={'isRealtorAdmin'}
                                        label={t('TEXT_IS_REALTOR_ADMIN', 'Er du faglig ansvarlig?')}
                                        component={CubitRadioGroupAdapter}
                                        direction="row"
                                        options={getRealtorAdminOptions(t)}
                                        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    />
                                    {!values.isRealtorAdmin && (
                                        <>
                                            <div css={styles.adminInfo}>
                                                {t('TEXT_INFO_ABOUT_ADMIN', 'Informasjon om faglig ansvarlig')}
                                            </div>
                                            <Field
                                                name="realtorAdminName"
                                                width="100%"
                                                component={CubitTextFieldAdapter}
                                                label={t('TEXT_NAME', 'Navn')}
                                                validate={(value) =>
                                                    ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                                                }
                                                variant="standard"
                                            />
                                            <Field
                                                name="realtorAdminEmail"
                                                width="100%"
                                                component={CubitTextFieldAdapter}
                                                label={t('TEXT_EMAIL', 'E-postadresse')}
                                                validate={(value) =>
                                                    ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                                                }
                                                variant="standard"
                                            />
                                        </>
                                    )}
                                </div>
                                <div css={styles.termsAndConditions}>
                                    <Checkbox onChange={() => setAgree(!agree)} checked={agree} sx={{ padding: '0' }} />
                                    <Button onClick={() => handleOpen()}>
                                        <span style={{ padding: '0 6px', fontSize: '14px' }}>
                                            {t(
                                                'TERMS_AND_CONDITIONS',
                                                'Jeg aksepterer koti sine vilkår og betingelser.',
                                            )}
                                        </span>
                                        <Launch />
                                    </Button>
                                </div>
                                <StyledContainedButton
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={!agree || isLoading}
                                    onClick={() => dispatchSubmit(FORM_ID)}
                                    style={{ width: '50%', marginTop: '40px' }}
                                >
                                    {t('TEXT_SEND_REQUEST', 'Send forespørsel')}
                                </StyledContainedButton>
                                <QueryStatusView error={error} isError={isError} />
                            </form>
                        )}
                    />
                </Grid>
            </KotiContainer>
            <BottomPageNavigation />
            <TermsDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => setIsOpen(false)}
                handleTermsAgree={() => setAgree(true)}
            />
        </div>
    )
}
