import { PropertyResponseItem } from 'components/search/models/property-search-response'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { filterHousingUnit } from 'utils/text-search-utils'

export const usePropertyTable = (initialPropertyUnits: PropertyResponseItem[]) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [searchValue, setSearchValue] = React.useState('')

    const cadastreNumber = searchParams.get('cadastreNumber')
    const addressFilter = searchParams.get('address')

    const filteredUnits = React.useMemo(() => {
        let filteredUnits = initialPropertyUnits

        if (addressFilter) {
            filteredUnits = filteredUnits.filter((unit) => filterHousingUnit(unit.housingUnits, addressFilter))
        }

        if (searchValue.trim().length > 0) {
            filteredUnits = filteredUnits.filter((unit) => filterHousingUnit(unit.housingUnits, searchValue))
        }

        return filteredUnits
    }, [addressFilter, searchValue, initialPropertyUnits])

    const handleDeleteAddressFilter = () => {
        const newQuery = `cadastreNumber=${cadastreNumber}`

        setSearchParams(newQuery)
    }

    return {
        cadastreNumber,
        addressFilter,
        filteredUnits,
        onSearchUnits: setSearchValue,
        onRemoveAddressFilter: handleDeleteAddressFilter,
    } as const
}
