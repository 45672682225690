/** @jsxImportSource @emotion/react */
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { CubitDatePickerAdapter } from '../../general/forms/cubit-date-picker-adapter'
import { Paper } from '@mui/material'
import { css } from '@emotion/react'
import { KotiCompanySearchAdapter } from '../../general/forms/koti-company-search-adapter'
import React from 'react'
import { ExportOptionsPicker } from '../../exports/export-options-picker'
import { useLazyGetOrdersExportQuery } from '../../exports/exports.service'
import { OrderFilters } from '../../orders/order.slice'
import StyledContainedButton from '../../styled/styled-contained-button'

type Props = {
    onSubmit: any
    filters: OrderFilters
}
const styles = {
    paper: css`
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    filters: css`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
    `,
    datePicker: css`
        width: 140px;
        margin-right: 32px;
    `,
    label: css`
        font-size: 16px;
        font-weight: 600;
        margin-right: 32px;
    `,
    search: css`
        width: 200px;
        margin-right: 32px;
    `,
    companyPicker: css`
        width: 240px;
        margin-right: 32px;
    `,
    field: css`
        max-width: 100%;
        width: 100%;
        display: block;
        margin-bottom: 16px;
    `,
}
export const RealtorReportFilters: React.FC<Props> = ({ onSubmit, filters }) => {
    const { t } = useTranslation()
    const [triggerOrdersCsv] = useLazyGetOrdersExportQuery()
    const handleCsvTrigger = () => {
        triggerOrdersCsv({
            page: 0,
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
            company: filters.company,
        })
    }
    return (
        <Paper elevation={0} css={styles.paper}>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form id={'REALTOR_REPORT_FILTERS'} onSubmit={handleSubmit}>
                        <div css={styles.filters}>
                            <div css={styles.label}>{t('TEXT_FILTERS', '')}</div>
                            <div css={styles.datePicker}>
                                <Field
                                    name="from"
                                    margin={'dense'}
                                    component={CubitDatePickerAdapter}
                                    label={t('TEXT_FROM', 'Fra')}
                                    variant="standard"
                                />
                            </div>
                            <div css={styles.datePicker}>
                                <Field
                                    name="to"
                                    component={CubitDatePickerAdapter}
                                    label={t('TEXT_TO', 'Til')}
                                    variant="standard"
                                />
                            </div>
                            <div css={styles.companyPicker}>
                                <Field
                                    fullWidth
                                    name="company"
                                    margin={'dense'}
                                    component={KotiCompanySearchAdapter}
                                    label={t('TEXT_REALTOR_COMPANY', 'Meglerkontor')}
                                    variant="standard"
                                />
                            </div>
                            {/*<div css={styles.search}>*/}
                            {/*    <Field*/}
                            {/*        name="q"*/}
                            {/*        margin={'dense'}*/}
                            {/*        component={CubitTextFieldAdapter}*/}
                            {/*        label={t('TEXT_SEARCH', 'Søk')}*/}
                            {/*        InputProps={{*/}
                            {/*            endAdornment: (*/}
                            {/*                <InputAdornment position="end">*/}
                            {/*                    <Search />*/}
                            {/*                </InputAdornment>*/}
                            {/*            ),*/}
                            {/*        }}*/}
                            {/*        variant="standard"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div>
                                <StyledContainedButton type={'submit'} variant={'contained'} color={'secondary'}>
                                    {t('TEXT_SEARCH', 'Søk')}
                                </StyledContainedButton>
                            </div>
                        </div>
                    </form>
                )}
            />
            <ExportOptionsPicker csvTrigger={handleCsvTrigger} />
        </Paper>
    )
}
