/** @jsxImportSource @emotion/react */
import { bottomNavigationStyles } from './bottom-navigation-styles'
import { useGetCartQuery } from '../cart/cart.service'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

export const BottomNavigationCart: React.FC = () => {
    const cart = useGetCartQuery()
    const cartItemsCount = cart?.data?.items?.length
    return (
        <div css={bottomNavigationStyles.bottomNavigationIcon}>
            <ShoppingCartIcon />
            {cartItemsCount && cartItemsCount > 0 ? (
                <div css={bottomNavigationStyles.cartItemsCount}>{cartItemsCount}</div>
            ) : null}
        </div>
    )
}
