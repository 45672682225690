/** @jsxImportSource @emotion/react */
import { Chip, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatCadastreNumber } from '../../../utils/cadastre-utils'
import { css } from '@emotion/react'
import React from 'react'
import { PropertyUnitAddress } from '../../search/models/property-search-response'

type Props = {
    cadastreNumber: string
    address: PropertyUnitAddress
}
const styles = {
    title: css`
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
    `,
    titleLabel: css`
        color: #858585;
        font-style: italic;
        font-size: 14px;
    `,
    textBlock: css`
        padding: 24px;
    `,

    rowBlocks: css`
        display: flex;
    `,
    rowBlock: css`
        margin-right: 34px;
    `,
    chip: css`
        margin-top: 34px;
    `,
}

export const HistoricPropertyTop: React.FC<Props> = ({ cadastreNumber, address }) => {
    const { t } = useTranslation()

    return (
        <Paper>
            <div css={styles.textBlock}>
                <div css={styles.titleLabel}>{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</div>
                <div css={styles.title}>{formatCadastreNumber(cadastreNumber)}</div>

                <div css={styles.rowBlocks}>
                    <div css={styles.rowBlock}>
                        <div css={styles.titleLabel}>{t('PROPERTY_ADDRESSES', 'Tilknyttede adresser')}</div>
                        {address.addressText}
                        {address.postalCode ? ', ' + address.postalCode : ''} {address.postalCodeName}
                    </div>
                </div>
                <Chip css={styles.chip} color={'primary'} label={t('HISTORIC_PROPERTY', 'Historic property')} />
            </div>
        </Paper>
    )
}
