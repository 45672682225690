import { isIneProduct } from 'components/products/product-helper'
import { OsloTaxesProductForRealtor } from './OsloTaxesProductForRealtor'
import { TaxesProductViewProps } from './ProductDisplay'

export const TaxesProduct: React.FC<TaxesProductViewProps> = ({ product, onCartButtonClick, isInCart, isLoading }) => {
    const isIne = isIneProduct(product)

    if (isIne) {
        return (
            <OsloTaxesProductForRealtor
                onCartButtonClick={onCartButtonClick}
                product={product}
                isInCart={isInCart}
                isLoading={isLoading}
            />
        )
    }

    console.error('this case is not implemented')
    return null
}
