import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { App } from './app/app'
import reportWebVitals from './report-web-vitals'
import { initTranslation } from './translation/i18n'

const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container)
initTranslation()
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
)

const initializeFreshchatWidget = () => {
    window.fcWidget.init({
        token: '10ae3e70-3c9c-4141-b85c-b3395d797b93',
        faqTags: {
            tags: ['koti'],
            filterType: 'category',
        },
        host: 'https://wchat.freshchat.com',
        siteId: 'KOTI',
        config: {
            headerProperty: {
                appName: 'Koti',
                appLogo: 'https://koti.no/static/media/koti.1ba947efd70035c8c292faaad4ac8f14.svg',
                backgroundImage: '#ffffff',
                backgroundColor: '#273a57',
                foregroundColor: '#ffffff',
            },
        },
    })
}

document.addEventListener('DOMContentLoaded', initializeFreshchatWidget)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
