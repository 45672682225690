import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
export const bottomNavigationStyles = {
    bottomNavigation: css`
        display: none;
        @media ${getMobileMediaQuery()} {
            display: flex;
            background-color: #bedef1;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 60px;
            margin-top: 60px;
            z-index: 2;
        }
    `,
    bottomNavigationIcon: css`
        display: flex;
        align-items: center;
    `,
    cartItemsCount: css`
        margin-left: 6px;
        font-weight: bold;
    `,
}
export const navigationAction = {
    '&.MuiButtonBase-root.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: '-8px',
        '&::after': {
            content: '""',
            width: '40px',
            height: '3px',
            backgroundColor: '#407a9c',
            marginTop: '5px',
        },
    },
}
