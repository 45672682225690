import InfoIcon from '@mui/icons-material/Info'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { SearchField } from '../general/search-field/search-field'
import { SearchResultsTable } from './search-results-table'
import { SearchTutorial } from './search-tutorial'
import { useLazySearchAddressQuery } from './search.service'
import { useIsAdmin, useIsOsloGuy } from '../auth/auth.slice'
import { useLazyGetProductsByCadastreNumberQuery } from '../products/products.service'
import { addLeadingZeros, createMatrikkelNumber } from '../../utils/cadastre-utils'
import { Agreement } from '../products/models/ine-data.type'
import { Loader } from '../general/loader/loader'
import StyledTypography from '../styled/styled-typography'
import { SearchNoResults } from './search-noResults'
import { Box, styled } from '@mui/material'
import { OSLO_MUNICIPALITY } from 'constants/municipality'
import { HistoricSearchEntity } from './models/address-search-response'

// currently is used by oslo, realtors, and admins
export const SearchRealtor = () => {
    const { t } = useTranslation()
    const [searchQuery, setQuery] = useSearchParams()
    const isOsloGuy = useIsOsloGuy()
    const isAdmin = useIsAdmin()

    const [trigger, { data: addresses, isFetching }] = useLazySearchAddressQuery()
    const [triggerProducts, { data: products }] = useLazyGetProductsByCadastreNumberQuery()

    const [historicQuery, setHistoricQuery] = useState<string>()
    const [isLoadingHistoric, setIsLoadingHistoric] = useState<boolean>(false)

    const query = searchQuery.get('query') ?? ''
    let historicResults: HistoricSearchEntity[] = []

    const triggerSearch = useCallback(
        (query: string) => {
            if (query) {
                trigger({
                    query,
                    municipality: isOsloGuy ? OSLO_MUNICIPALITY : isAdmin ? undefined : undefined,
                })
            }
        },
        [isOsloGuy, isAdmin, trigger],
    )

    useEffect(() => {
        triggerSearch(query)
    }, [query, triggerSearch])

    if (
        historicQuery &&
        products &&
        products.ineData &&
        products.ineData[historicQuery] &&
        products.ineData[historicQuery].length > 0
    ) {
        const agreement: Agreement = products.ineData[historicQuery][0]
        historicResults.push({
            addressText: agreement.address,
            postalCode: agreement.postalCode,
            postalName: agreement.city,
            municipalityCode: addLeadingZeros(agreement.municipalityNumber),
            municipalityName: 'OSLO',
            cadastreNumber: historicQuery,
            isHistoric: true,
        })
    } else {
        historicResults = []
    }

    const onSubmit = () => {
        //if no results are found and submit is clicked, we try to trigger ine search for historical
        if (!addresses || addresses.length === 0) {
            const nr = createMatrikkelNumber(query)
            if (nr.length > 0 && !isLoadingHistoric) {
                setHistoricQuery(nr)
                setIsLoadingHistoric(true)
                triggerProducts({ cadastreNumber: nr }).then(() => {
                    setIsLoadingHistoric(false)
                })
            }
        } else {
            setIsLoadingHistoric(false)
            setHistoricQuery('')
        }
        triggerSearch(query)
    }

    const onQueryChanged = (query: string) => {
        query = query.replaceAll('.', '/')
        setQuery(createSearchParams({ query }))
    }

    const showResults = query && (addresses?.length !== 0 || historicResults.length !== 0)

    return (
        <>
            <SearchTutorial />
            <Box mb={1}>
                <SearchHeaderContainer>
                    <StyledTypography fontFamily={'TOMATO'} sx={{ marginBottom: 1.25 }}>
                        {t('TEXT_FIND_HOUSING', 'Finn bolig')}
                    </StyledTypography>
                    <StyledTypography fontFamily={'TOMATO'}>
                        {t('TEXT_SEARCH_BY_ADDRESS_OR_CADASTRE', 'Søk på adresse eller matrikkel.')}
                    </StyledTypography>
                </SearchHeaderContainer>
                <SearchContainer>
                    <SearchFieldContainer>
                        <SearchField
                            defaultValue={query}
                            onSubmit={onSubmit}
                            label={t('TEXT_SEARCH_PRODUCTS', 'Søk...')}
                            onTextChanged={onQueryChanged}
                            wait={400}
                        />
                        <SearchInfoHelperContainer>
                            <InfoIcon fontSize="small" />
                            <div>{t('TEXT_SEARCH_EXAMPLE', 'For eksempel Eksempelstien 111 eller 0301-1/1')}</div>
                        </SearchInfoHelperContainer>
                    </SearchFieldContainer>
                </SearchContainer>
            </Box>
            {isLoadingHistoric && (
                <div style={{ margin: '20px 0' }}>
                    <Loader text={t('TEXT_HISTORIC_LOADING', 'Søker etter historisk eiendom. Vent litt...') ?? ''} />
                </div>
            )}
            {showResults ? (
                <Box pb={4}>
                    <SearchResultsTable
                        addressSearchResults={addresses || []}
                        historicResults={historicResults}
                        isLoading={isFetching}
                    />
                </Box>
            ) : (
                <SearchNoResults />
            )}
            <BottomPageNavigation />
        </>
    )
}

const SearchHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    backgroundColor: '#fff',
    padding: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5, 0),
    },
}))

const SearchContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spacing(6),
    justifyContent: 'center',
    backgroundColor: '#fff',

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f8f8f8',
        padding: theme.spacing(1.25, 0, 0, 0),
    },
}))

const SearchFieldContainer = styled('div')(({ theme }) => ({
    width: '40%',

    [theme.breakpoints.down('md')]: {
        width: '90%',
    },
}))

const SearchInfoHelperContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1.25),
    color: '#000000b3',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontStyle: 'italic',
}))
