/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ineProductStyles } from './ine-product-styles'

export const IneExplanations: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div css={ineProductStyles.ineTableExplanations}>
            <div>
                <sup>* </sup>
                {t(
                    'TEXT_SIMULATED_PROPERTY_TAX_EXPLANATION',
                    'Dette er en stipulert avgift siden året inneholder terminer som ikke er fakturert ennå.',
                )}
            </div>
            <div>
                <sup>** </sup>
                {t(
                    'TEXT_LAST_YEAR_SUM_EXPLANATION',
                    'Fjorårets beløp. Eksakt beløp blir tilgjengelig etter februar i år.',
                )}
            </div>
        </div>
    )
}
