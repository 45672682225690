/** @jsxImportSource @emotion/react */
import { useGetCartQuery } from '../cart/cart.service'
import { useGetProductsByCadastreNumberQuery } from '../products/products.service'
import { FullWidthProduct } from '../products/single-product/full-width-product'
import { HousingUnit } from '../search/models/address-search-response'
import { Loader } from '../general/loader/loader'
import { css } from '@emotion/react'
import { useIsOsloGuy } from '../auth/auth.slice'
import { OsloProduct } from '../products/single-product/oslo-product'

const styles = {
    item: css`
        margin: 2px 0;
        padding: 10px;
        border-bottom: 2px solid #f8f8f8;
    `,
}

type Props = {
    unit: HousingUnit
    cadastreNumber?: string
}

export const ProductsView: React.FC<Props> = ({ unit, cadastreNumber }) => {
    const { data } = useGetProductsByCadastreNumberQuery({
        cadastreNumber: cadastreNumber || '',
    })
    const cart = useGetCartQuery()
    const isOsloGuy = useIsOsloGuy()
    if (!data || !cart.data) return <Loader />

    return (
        <div>
            {data.products.map((p) => (
                <div key={p.id} css={styles.item}>
                    {isOsloGuy ? (
                        <OsloProduct product={p} matrikkelNumber={unit.cadastreNumber} ineData={data.ineData} />
                    ) : (
                        <FullWidthProduct
                            matrikkelNumber={unit.cadastreNumber}
                            product={p}
                            unitCadastreId={unit.cadastreId}
                        />
                    )}
                </div>
            ))}
            {/*<pre>{JSON.stringify(data.ineData, undefined, 2)}</pre>*/}
        </div>
    )
}
