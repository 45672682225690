import { Navigate, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { AuthCompanyPage } from '../components/auth/auth-company.page'
import { AuthDevPage } from '../components/auth/auth-dev.page'
import { AuthPage } from '../components/auth/auth.page'
import SsoAuthPage from '../components/auth/sso.page'
import { useAuthContextRole, useIsAuthenticated } from '../components/auth/auth.slice'
import { CartView } from '../components/cart/cart.view'
import { CompanyRegister } from '../components/companies/company-register.view'
import { CompanyView } from '../components/companies/single-page/company.view'
import { CompanyAdminPage } from '../components/company-admin-page/company-admin.view'
import { CubitAdminPage } from '../components/cubit-admin-page/cubit-admin.view'
import { LoginPage } from '../components/login/LoginPage'
import { OrdersView } from '../components/orders/orders.view'
import { AdminProductsPage } from '../components/products/admin-products.view'
import { Search } from '../components/search/search.view'
import { FinishCompanyAdminSignup } from '../components/sign-up/finish-company-admin-sign-up'
import { Terms } from '../components/sign-up/terms'
import '../index.css'
import {
    useCanAccessCompanyAdmin,
    useCanAccessProducts,
    useCanAccessProperty,
    useCanAccessSearch,
} from '../utils/policies'
import {
    getAuthRedirect,
    ROUTE_ADMIN,
    ROUTE_BILLING_DASHBOARD,
    ROUTE_CART,
    ROUTE_CHECKOUT,
    ROUTE_COMPANY,
    ROUTE_COMPANY_ADMIN,
    ROUTE_FINISH_SIGN_UP,
    ROUTE_HISTORIC_PROPERTY,
    ROUTE_INE_ADMIN,
    ROUTE_INVOICE,
    ROUTE_ORDERS,
    ROUTE_PRODUCTS,
    ROUTE_PROPERTY,
    ROUTE_SEARCH,
    ROUTE_SIGN_UP,
} from '../utils/routes'
import { initAppInsights } from './analytics/telemetry-config'
import { appTheme } from './material-ui/material-ui.config'
import { PropertyView } from '../components/property/property.view'
import { IneAdminPage } from '../components/ine-admin-page/ine-admin.view'
import { Role } from '../components/auth/role'
import { CheckoutView } from '../components/cart/checkout/checkout.view'
import { ScrollToTop } from '../components/general/sroll-to-top'
import { SigningThankYouPage } from '../components/static-pages/signing-thank-you.page'
import { CompanyImport } from '../components/import/company-import'
import { FreshChatWidget } from '../components/auth/fresh-chat-widget'
import { HistoricPropertyView } from '../components/property/historic-property/historic-property.view'
import { SingleInvoicePage } from '../components/invoicing/single-invoice.page'
import { ThemeProvider } from '@mui/material'
import { SingleBillingPage } from '../components/invoicing/single-billing.page'
import { useGetSettingsQuery } from '../components/settings/settings.service'
import HomeLayout from '../components/home/home-layout'
import { ActiveUserNotifications } from 'components/general/userNotifications/ActiveUserNotifications'

initAppInsights()

export const App = () => {
    const productsAccess = useCanAccessProducts()
    const searchAccess = useCanAccessSearch()
    const propertyAccess = useCanAccessProperty()
    const companyAdminAccess = useCanAccessCompanyAdmin()
    const isAuthenticated = useIsAuthenticated()
    const role = useAuthContextRole()

    useGetSettingsQuery(undefined, {
        skip: !productsAccess,
    })

    return (
        <ThemeProvider theme={appTheme}>
            <title> KOTI </title>
            <BrowserRouter>
                <ScrollToTop />
                {isAuthenticated && <FreshChatWidget />}
                {isAuthenticated && !productsAccess && <ActiveUserNotifications />}
                <Routes>
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/auth-company" element={<AuthCompanyPage />} />
                    <Route path="/auth-dev" element={<AuthDevPage />} />
                    <Route path="/dev-auth" element={<AuthDevPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/thank-you" element={<SigningThankYouPage />} />
                    <Route path="/tenant-sso/:tenant" element={<SsoAuthPage />} />
                    <Route path="/importer" element={<CompanyImport />} />

                    {isAuthenticated ? <Route path="/terms" element={<Terms />} /> : null}
                    {isAuthenticated ? (
                        <Route path={ROUTE_FINISH_SIGN_UP} element={<FinishCompanyAdminSignup />} />
                    ) : null}

                    {isAuthenticated ? <Route path={ROUTE_ORDERS} element={<OrdersView />} /> : null}
                    {isAuthenticated ? <Route path={ROUTE_CART} element={<CartView />} /> : null}
                    {isAuthenticated ? <Route path={ROUTE_CHECKOUT} element={<CheckoutView />} /> : null}

                    {isAuthenticated ? <Route path={ROUTE_SIGN_UP} element={<CompanyRegister />} /> : null}
                    {productsAccess ? <Route path={ROUTE_PRODUCTS} element={<AdminProductsPage />} /> : null}
                    {productsAccess ? <Route path={ROUTE_ADMIN} element={<CubitAdminPage />} /> : null}
                    {productsAccess || companyAdminAccess ? (
                        <Route path={ROUTE_COMPANY} element={<CompanyView />} />
                    ) : null}
                    {productsAccess || companyAdminAccess ? (
                        <Route path={ROUTE_INVOICE} element={<SingleInvoicePage />} />
                    ) : null}
                    {productsAccess || companyAdminAccess ? (
                        <Route path={ROUTE_BILLING_DASHBOARD} element={<SingleBillingPage />} />
                    ) : null}
                    {role === Role.OsloUser ? <Route path={ROUTE_INE_ADMIN} element={<IneAdminPage />} /> : null}

                    {companyAdminAccess ? <Route path={ROUTE_COMPANY_ADMIN} element={<CompanyAdminPage />} /> : null}

                    {searchAccess ? <Route path={ROUTE_SEARCH} element={<Search />} /> : null}

                    {propertyAccess ? <Route path={ROUTE_PROPERTY} element={<PropertyView />} /> : null}
                    {propertyAccess ? (
                        <Route path={ROUTE_HISTORIC_PROPERTY} element={<HistoricPropertyView />} />
                    ) : null}

                    {isAuthenticated ? (
                        <Route path="/" element={<Navigate to={getAuthRedirect(role || Role.User)} replace={true} />} />
                    ) : (
                        <Route path="/" element={<HomeLayout />} />
                    )}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}
