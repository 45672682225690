/** @jsxImportSource @emotion/react */
import React from 'react'
import { useGetCurrentPeriodDataQuery } from '../invoicing.service'
import { LinearProgress, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../../utils/number-utils'
import { css } from '@emotion/react'
import { CurrentPeriod } from './invoice-period'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useNavigate } from 'react-router-dom'
import { getBillingRoute } from '../../../utils/routes'
import { CurrentPeriodData } from '../types/current-period-data.type'
import StyledTypography from '../../styled/styled-typography'

const styles = {
    paper: css`
        padding: 16px 16px 0 16px;
        margin-bottom: 16px;
        cursor: pointer;
        :hover,
        :focus {
            background: #efefef;
        }
    `,
    contentPaper: css`
        padding: 16px 16px 0 16px;
        margin-bottom: 16px;
        margin-top: 16px;
    `,
    wrapper: css`
        display: flex;
        flex-wrap: wrap;
    `,
    block: css`
        margin-right: 40px;
        margin-bottom: 16px;
    `,
    label: css`
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
    `,
    arrow: css`
        flex-grow: 1;
        text-align: right;
        align-self: center;
    `,
    heading: css`
        font-size: 16px;
        margin: 16px;
        text-transform: uppercase;
    `,
    value: css`
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        padding-right: 20px;
    `,
}
type Props = {
    companyId: string
}
const Content: React.FC<{ data?: CurrentPeriodData; isClickable?: boolean }> = ({ data, isClickable = true }) => {
    const { t } = useTranslation()
    return (
        <div css={styles.wrapper}>
            <div css={styles.block}>
                <div css={styles.label}>{t('invoice_period', 'Faktureringsperiode')}</div>
                <div css={styles.value}>
                    <CurrentPeriod />
                </div>
            </div>
            <div css={styles.block}>
                <div css={styles.label}>{t('item_count', 'Items count')}</div>
                <div css={styles.value}>
                    <div css={styles.value}>{data?.itemCount}</div>
                </div>
            </div>
            <div css={styles.block}>
                <div css={styles.label}>{t('TEXT_TOTAL_SUM', 'Totalsum')}</div>
                <div css={styles.value}>{formatNumber(data?.total ?? 0)}</div>
            </div>
            {isClickable && (
                <div css={styles.arrow}>
                    <KeyboardArrowRightIcon />
                </div>
            )}
        </div>
    )
}
export const CurrentPeriodContent: React.FC<Props> = ({ companyId }) => {
    const { data, isLoading } = useGetCurrentPeriodDataQuery({ companyId, params: {} })
    return (
        <div>
            {isLoading && <LinearProgress />}
            <Paper tabIndex={0} elevation={0} css={styles.contentPaper}>
                <Content data={data} isClickable={false} />
            </Paper>
        </div>
    )
}
export const CurrentPeriodLine: React.FC<Props> = ({ companyId }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { data, isLoading } = useGetCurrentPeriodDataQuery({ companyId, params: {} })
    const handleClick = () => {
        navigate(getBillingRoute(companyId))
    }
    return (
        <div>
            {isLoading && <LinearProgress />}
            <StyledTypography variant="h6" css={styles.heading}>
                {t('uninvoicedOrders', 'ikke-fakturerte oppslag')}
            </StyledTypography>
            <Paper
                tabIndex={0}
                elevation={0}
                css={styles.paper}
                onKeyDown={(event) => (event.key === 'Enter' && handleClick ? handleClick() : null)}
                onClick={handleClick}
            >
                <Content data={data} />
            </Paper>
        </div>
    )
}
