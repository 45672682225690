/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Agreement } from '../models/ine-data.type'
import React, { useEffect } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import { formatCurrencyWithCode, getAllYears, getTerms, getYearTotal } from './ine-product-helper'
import { ineProductStyles } from './ine-product-styles'
import { IneProductDetailedReportTable } from './ine-product-detailed-report-table'
import { formatDate } from '../../../utils/date-utils'

type Props = {
    agreement: Agreement
}
export const IneProductDetailedReport: React.FC<Props> = ({ agreement }) => {
    const { t } = useTranslation()
    const invoices = agreement.invoices
    const allYears = getAllYears(invoices, false)
    const [selectedYear, setSelectedYear] = React.useState<string>(allYears[0])
    useEffect(() => {
        setSelectedYear(allYears[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const terms = getTerms(selectedYear, invoices)
    const handleChangeYear = (event: SelectChangeEvent) => {
        setSelectedYear(event.target.value as string)
    }
    return (
        <>
            <FormControl margin="normal" css={ineProductStyles.formControl}>
                <InputLabel css={ineProductStyles.inputLabel} id="ine-year-select-label">
                    {t('TEXT_SELECT_YEAR', 'Velg år')}
                </InputLabel>
                <Select
                    labelId="ine-year-select-label"
                    id="ine-year-select"
                    value={selectedYear}
                    onChange={handleChangeYear}
                    variant="standard"
                >
                    {allYears.map((year: any) => (
                        <MenuItem key={year} value={year}>
                            {year === 'all' ? t('TEXT_ALL_YEARS', 'Alle år') : year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div css={ineProductStyles.totalYearSum}>
                <div css={ineProductStyles.totalYearText}>{`${t('TEXT_TOTAL_YEAR', 'Totalt for hele året:')}`}</div>
                <div css={ineProductStyles.totalYearAmount}>{`${formatCurrencyWithCode(
                    getYearTotal(invoices, selectedYear),
                )}`}</div>
            </div>
            {terms.map((term: any) => (
                <div key={term} css={ineProductStyles.termContainer}>
                    <div>
                        <div css={ineProductStyles.termHeader}>{`${t('TEXT_YEAR_TERM', 'Termin')} ${term}`}</div>
                    </div>
                    {invoices
                        .filter((i) => {
                            return (
                                (selectedYear === 'all' || i.year.toString() === selectedYear.toString()) &&
                                i.term === term
                            )
                        })
                        .map((i) => (
                            <>
                                <span style={{ display: 'block', fontSize: 12, marginTop: 10 }}>
                                    {formatDate(i.date)}
                                </span>
                                {i.kid ? (
                                    <div css={ineProductStyles.invoiceNumber}>{`${t(
                                        'TEXT_KID_NUMBER',
                                        'KID-nummer:',
                                    )} ${i.kid}`}</div>
                                ) : (
                                    <div>
                                        {t(
                                            'ESTIMATED_INVOICE',
                                            'Estimerte avgifter, denne terminen er ikke fakturert ennå',
                                        )}
                                    </div>
                                )}
                                <IneProductDetailedReportTable invoice={i} />
                            </>
                        ))}
                </div>
            ))}
        </>
    )
}
