import { GetMultiChannelDispatchResponse } from 'types/multi-channels/multi-channel-dispatch'
import { appApi } from '../api'

const multiChannelsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getDispatches: builder.query<GetMultiChannelDispatchResponse, { customerId: string; invoiceNumber: number }>({
            query: (payload) => ({
                url: `/v1/multiChannels/dispatches`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['MultiChannels'],
        }),
    }),
})

export const { useGetDispatchesQuery } = multiChannelsApi
