import dayjs, { QUnitType, extend } from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'

extend(calendar)
extend(utc)

export const formatDateTime = (date: any) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm')
}
export const getDateObject = (date: any) => {
    return dayjs(date)
}
export const formatDate = (date: any) => {
    if (!date) return ''
    return dayjs(date).format('DD.MM.YYYY')
}
export const getDifferenceFromNow = (date: any, type: QUnitType) => {
    return dayjs(date).diff(dayjs(), type)
}
export const getCurrentDate = () => dayjs().toISOString()

export const isValidDate = (date: any) => dayjs(date).isValid()

export const isDateNeverExpires = (date: string | Date) => {
    const compareDate = dayjs(date)

    const expiresNeverDate = dayjs()
        .set('year', 3000)
        .set('month', 0)
        .set('date', 1)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)

    return compareDate >= expiresNeverDate
}
