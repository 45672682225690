/** @jsxImportSource @emotion/react */
import { Invoice } from '../models/ine-data.type'

type Props = {
    invoices: Invoice[]
    group?: string
    year: number
}
export const SimulatedValue: React.FC<Props> = ({ invoices, group, year }) => {
    const filteredByYear = invoices.filter((invoice: Invoice) => invoice.year.toString() === year.toString())
    const simulatedInvoices = filteredByYear.some((invoice: Invoice) => invoice.estimated === true)
    const propertyTax = group === 'Eiendomsskatt' ? true : false
    return <>{simulatedInvoices ? propertyTax ? <sup>**</sup> : <sup>*</sup> : null}</>
}
