/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { css } from '@emotion/react'
import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../utils/date-utils'
import { useGetHistoryQuery } from './history.service'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import MessageIcon from '@mui/icons-material/Message'
import { getTypePrefix, historyStrings } from './history-helper'
import TablePagination from '@mui/material/TablePagination'
import { HistoryEntityType } from './models/history-entry'

type Props = {
    entityType: HistoryEntityType
    relatedEntityId?: string
}

export const ProductsHistoryView: React.FC<Props> = ({ entityType, relatedEntityId }) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(0)
    const itemsPerPage = 5
    const { data } = useGetHistoryQuery({
        page: pageNumber,
        itemsPerPage: itemsPerPage,
        entityType,
        relatedEntityId,
    })
    const styles = {
        card: css`
            padding: 10px;
            display: flex;
        `,
        cardIcon: css`
            padding: 10px 6px 10px 0;
        `,
        cardHeader: css`
            display: flex;
            justify-content: space-between;
            font-weight: bold;
        `,
        cardContent: css`
            width: 100%;
            font-size: 12px;
        `,
        change: css`
            display: flex;
        `,
        changeDetails: css`
            display: flex;
            align-items: center;
            margin: 0 5px 0 0;
        `,
        entityName: css`
            margin: 0 0 10px;
        `,
    }
    return (
        <Box>
            <Stack direction="column" gap={'4px'}>
                <Card square={true} elevation={0} css={styles.card}>
                    <div>{t('TEXT_CHANGE_LOG', 'Endringslogg')}</div>
                </Card>
                {data?.data.map((historyEntry) => (
                    <Card square={true} elevation={0} css={styles.card} key={historyEntry.id}>
                        <div css={styles.cardIcon}>
                            <MessageIcon />
                        </div>
                        <div css={styles.cardContent}>
                            <div css={styles.cardHeader}>
                                <p>{historyEntry.userName}</p>
                                <p>{formatDateTime(historyEntry.created)}</p>
                            </div>
                            <p css={styles.entityName}>{historyEntry.entityName}</p>
                            {getTypePrefix(t, historyEntry.type) && (
                                <p css={styles.entityName}>{getTypePrefix(t, historyEntry.type)}</p>
                            )}
                            {historyEntry.meta['tenantName'] && (
                                <p css={styles.entityName}>{historyEntry.meta['tenantName']}</p>
                            )}

                            {historyEntry.changes.map((change, i) => (
                                <React.Fragment key={i}>
                                    <p css={styles.changeDetails} style={{ fontWeight: 'bold' }}>
                                        {historyStrings(t, change.property)}
                                    </p>
                                    <div css={styles.change} key={i}>
                                        <p css={styles.changeDetails}>{historyStrings(t, change.oldValue)}</p>
                                        <ArrowRightAltIcon css={styles.changeDetails} />
                                        <p css={styles.changeDetails}>{historyStrings(t, change.newValue)}</p>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </Card>
                ))}
            </Stack>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                onPageChange={(event, newPage) => setPageNumber(newPage)}
            />
        </Box>
    )
}
