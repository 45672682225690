import { getStandardRightActions, TopBar } from '../../topbar/top-bar'
import { KotiContainer } from '../../general/layout/koti-container'
import { Grid, Paper } from '@mui/material'
import { ProductsHistoryView } from '../../history/products-history.view'
import { BottomPageNavigation } from '../../bottom-navigation/bottom-navigation'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CompanyInfo } from './company-info'
import { UsersView } from '../../users/users.view'
import { useLazyGetCompanyQuery } from '../../../redux/apis/companies-api'
import React, { useEffect, useState } from 'react'
import { TopPageTabs } from '../../general/tabs/top-page-tabs'
import { useTranslation } from 'react-i18next'
import { HistoryEntityType } from '../../history/models/history-entry'
import { KotiBackButton } from '../../general/buttons/back-button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useAuthContextRole } from '../../auth/auth.slice'
import { Role } from '../../auth/role'
import { CompanyAdminDepartmentsView } from '../../company-admin-page/company-admin-departments.view'
import { MyCompanyOrders } from '../../orders/my-company-orders'
import { CompanyAdminInvoiceView } from '../../company-admin-page/company-admin-invoice.view'
import { DocumentsView } from '../documents/documents.view'
import { CompanyAdminConfigView } from 'components/company-admin-page/company-admin-config.view'

export enum CompanyTabs {
    General = 'general',
    Invoice = 'invoice',
    Users = 'users',
    Departments = 'departments',
    AllOrders = 'allOrders',
    Documents = 'documents',
    Config = 'config',
}

export const CompanyView: React.FC = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useAuthContextRole()

    const [tab, setTab] = useState<string>()
    const [searchParams, setSearchParams] = useSearchParams({})

    let tabs = [
        { value: CompanyTabs.General, label: t('TEXT_REALTOR_COMPANY', 'Generell informasjon') },

        { value: CompanyTabs.Users, label: t('TEXT_USERS', 'Brukere') },
    ]

    if (role === Role.CubitAdmin) {
        tabs.push({ value: CompanyTabs.AllOrders, label: t('TEXT_ORDERED_COMPANY_REPORTS', 'Bestilte rapporter') })
    }
    //

    const [trigger, { data }] = useLazyGetCompanyQuery()

    useEffect(() => {
        if (id) {
            trigger(id)
        }
    }, [trigger, id])

    if (role === Role.CubitAdmin && data && !data.parentId) {
        tabs = [
            ...tabs,
            { value: CompanyTabs.Departments, label: t('TEXT_DEPARTMENTS', 'Avdelinger') },
            { value: CompanyTabs.Invoice, label: t('TEXT_INVOICE', 'Faktura') },
            { value: CompanyTabs.Documents, label: t('TEXT_DOCUMENTS', 'Documents') },
            { value: CompanyTabs.Config, label: t('TEXT_COMPANY_CONFIG', 'Config') },
        ]
    }

    useEffect(() => {
        const tabFromQuery = searchParams.get('tab') || CompanyTabs.Users
        setTab(tabFromQuery)
    }, [searchParams])

    const setTabs = (tabName: string) => {
        setTab(tabName)
        setSearchParams({ tab: tabName }, { replace: true })
    }

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                {data && tab ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <KotiBackButton
                                startIcon={<ArrowBackIcon />}
                                text={data?.name}
                                onClick={() => navigate(-1)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TopPageTabs tabs={tabs} tab={tab} setTab={setTabs} title={data.name ?? 'Company name'} />
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Paper elevation={0} square={true}>
                                <Grid item xs={12}>
                                    {tab === CompanyTabs.General && <CompanyInfo company={data} />}
                                    {tab === CompanyTabs.Invoice && <CompanyAdminInvoiceView company={data} />}
                                    {tab === CompanyTabs.Users && <UsersView companyId={data.id} />}
                                    {tab === CompanyTabs.Departments && data && !data.parentId && (
                                        <CompanyAdminDepartmentsView companyId={data.id} />
                                    )}
                                    {tab === CompanyTabs.AllOrders && (
                                        <MyCompanyOrders reload={1} companyId={data.id} />
                                    )}
                                    {tab === CompanyTabs.Documents && <DocumentsView companyId={data.id} />}
                                    {tab === CompanyTabs.Config && <CompanyAdminConfigView company={data} />}
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            {role === Role.CubitAdmin && (
                                <ProductsHistoryView entityType={HistoryEntityType.Company} relatedEntityId={id} />
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    'loading'
                )}
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
