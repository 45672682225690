import React from 'react'
import { Box, Grid, Paper, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PropertyResponseItem } from 'components/search/models/property-search-response'
import { convertFromZone32to33 } from 'components/general/map/map-helper'
import { IframeMap } from 'components/general/map/i-frame-map'
import { formatCadastreNumber, getAllAddresses, getStreetNames } from 'utils/cadastre-utils'

type PropertyOverviewProps = {
    data: PropertyResponseItem[]
    isPropertySectioned: boolean
    sanitizedMainPropertyCadastreNr: string
    allOwners: string[]
    mainProperty?: PropertyResponseItem
}

export const PropertyOverview: React.FC<PropertyOverviewProps> = React.memo(
    ({ data, mainProperty, isPropertySectioned, sanitizedMainPropertyCadastreNr, allOwners }) => {
        const { t } = useTranslation()

        const coords = convertFromZone32to33(mainProperty?.coordinateX ?? 0, mainProperty?.coordinateY ?? 0)
        const Map = React.useMemo(() => (coords.lat && coords.long ? <IframeMap coords={coords} /> : <></>), [coords])

        return (
            <Grid container spacing={0}>
                <Grid item xs={12} lg={5}>
                    <Paper elevation={0} sx={{ height: '100%' }}>
                        <Box padding={2.5}>
                            <Label variant="body1">{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</Label>
                            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '24px', marginBottom: '24px' }}>
                                {formatCadastreNumber(sanitizedMainPropertyCadastreNr)}
                            </Typography>

                            <Box display="flex">
                                <Box mr={4}>
                                    <Label variant="body1">{t('PROPERTY_ADDRESSES', 'Tilknyttede adresser')}</Label>
                                    {getStreetNames(getAllAddresses(data)).map((a, index) => (
                                        <Box key={index} mb={1}>
                                            {a}
                                        </Box>
                                    ))}
                                </Box>
                                {!isPropertySectioned && (
                                    <Box sx={{ whiteSpace: 'nowrap' }}>
                                        <Label>{t('TEXT_OWNER', 'Eier')}</Label>
                                        {allOwners.map((a, index) => (
                                            <div key={index}>{a}</div>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={7}>
                    {Map}
                </Grid>
            </Grid>
        )
    },
)

PropertyOverview.displayName = 'Property overview'

const Label = styled(Typography)({
    color: '#858585',
    fontStyle: 'italic',
    fontSize: '14px',
})
