import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'

export const topNavigationStyles = {
    topNavigation: css`
        display: flex;
        @media ${getMobileMediaQuery()} {
            flex-direction: column;
        }
    `,
    item: css`
        margin-right: 10px;
        @media ${getMobileMediaQuery()} {
            margin: 16px 0;
        }
    `,
    box: css`
        width: 50px;
    `,
}

export const mobileNavigationDrawerStyles = {
    item: css`
        margin: 16px 0;
    `,
    box: css`
        display: flex;
    `,
}
