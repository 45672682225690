/** @jsxImportSource @emotion/react */
import { Edit } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dispatchSubmit } from '../../utils/forms-utils'
import { Product } from './product'
import { ProductForm } from './product-form'
import {
    ProductCreateUpdateRequest,
    useGetTenantsQuery,
    useGetVendorsQuery,
    useUpdateProductMutation,
} from './products.service'
import { useAppSelector } from '../../redux/hooks'
import { selectProductTypeOptions } from '../settings/settings.selectors'
import StyledContainedButton from '../styled/styled-contained-button'
type Props = {
    product: Product
}
export const EditProduct: React.FC<Props> = ({ product }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)

    const productTypeOptions = useAppSelector(selectProductTypeOptions)
    const { data: tenants = [] } = useGetTenantsQuery()
    const { data: vendors = [] } = useGetVendorsQuery()
    const [update, { isLoading }] = useUpdateProductMutation()

    const onSubmit = (values: any) => {
        const newProduct = values as ProductCreateUpdateRequest
        if (!isLoading) {
            update({
                id: product.id,
                update: newProduct,
            })
                .unwrap()
                .then(() => setOpen(false))
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Edit />
            </IconButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{t('TEXT_EDIT_PRODUCT_TITLE', 'Rediger produkt')}</DialogTitle>
                <DialogContent>
                    <ProductForm
                        id={product.id}
                        initialValues={product}
                        onSubmit={onSubmit}
                        tenants={tenants}
                        vendors={vendors}
                        productTypeOptions={productTypeOptions}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(product.id)}
                    >
                        {t('TEXT_SAVE_PRODUCT', 'LAGRE PRODUKT')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
