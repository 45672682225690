import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import HomeAccordion from './home-accordion'
import HomeCard from './home-card'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import StyledTypography from '../styled/styled-typography'
import { navigateToLogin } from '../auth/auth.service'

const HomeView: React.FC = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(getMobileMediaQuery())

    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const [expandedAccordion, setExpandedAccordion] = useState<number | false>(false)
    const [currentImageUrl, setCurrentImageUrl] = useState<string>(
        'https://cubitblobstorage.blob.core.windows.net/public-images/accordion 1.png',
    )

    const cardContent = [
        {
            header: t('home_card_1_header', 'Who are we?'),
            body: t(
                'home_card_1_body',
                'Koti is owned by Cubit, a small software-company in Bergen. \n\n Our audit solution is actively used by most of the Norwegian electricity authorities (DLE) and many big and small fire departments in Norway.',
            ),
            linkText: t('home_card_1_link', 'Read more on our home page'),
            linkUrl: 'https://www.cubit.no/',
        },
        {
            header: `${t('home_card_2_header', 'What is the goal of Koti?')}`,
            body: t(
                'home_card_2_body',
                'Our goal is to be able to provide all audit data that we possess within our audit solution. \n\n We currently offer municipal fees and property tax for housing units in Oslo, in addition to fire reports for large parts of Norway.',
            ),
        },
        {
            header: t('home_card_3_header', 'Who do we cooperate with?'),
            body: t(
                'home_card_3_body',
                'We have cooperated closely with the biggest electricity authorities and fire departments in Norway to create the audit solution they use daily.  \n\n Out of this work, Koti became a platform where audit information is made available.',
            ),
        },
    ]
    const accordionContent = [
        {
            title: t('home_accordion_1_title', 'Give your company access to Koti.'),
            header: `${t('home_accordion_1_header', 'Contact us. We fix you a profile free of charge.')}`,
            body: t(
                'home_accordion_1_body',
                'We set up your company in Koti for free. All we need your company to do is to sign a contract.',
            ),
            imageUrl: 'https://cubitblobstorage.blob.core.windows.net/public-images/accordion 1.png',
        },
        {
            title: t('home_accordion_2_title', 'Add your whole company.'),
            header: t('home_accordion_2_header', 'Easy user administration.'),
            body: t(
                'home_accordion_2_body',
                'You can add all the realtors within your company as users. They will also be able to buy reports.',
            ),
            imageUrl: 'https://cubitblobstorage.blob.core.windows.net/public-images/accordion 2.png',
        },
        {
            title: t('home_accordion_3_title', 'Search and find the house you’re selling.'),
            header: t('home_accordion_3_header', 'Search by matrikkel or address.'),
            body: t('home_accordion_3_body', 'You will quickly find the housing unit you’re selling.'),
            imageUrl: 'https://cubitblobstorage.blob.core.windows.net/public-images/accordion 3.png',
        },
        {
            title: t('home_accordion_4_title', 'Order reports and download them instantly.'),
            header: t('home_accordion_4_header', 'Easy checkout process.'),
            body: t(
                'home_accordion_4_body',
                'Add your reports to basket and checkout without entering credit card information. Your company receives an invoice once a month. Find your reports immediately in your order list.',
            ),
            imageUrl: 'https://cubitblobstorage.blob.core.windows.net/public-images/accordion 4.png',
        },
    ]

    const handleChange = (index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? index : false)
        if (isExpanded) {
            setCurrentImageUrl(accordionContent[index].imageUrl)
        }
    }
    return (
        <Box>
            <Grid sx={{ minHeight: isMobile ? theme.spacing(20) : theme.spacing(25) }} container>
                <Grid item xs />
                <Grid sx={{ p: isMobile ? theme.spacing(0, 3) : 0 }} alignSelf="center" item xs={12} sm={8}>
                    <StyledTypography bold variant="h2">
                        Koti
                    </StyledTypography>
                    <StyledTypography variant="h6">
                        {t('home_header', 'Realtor reports quick and easy.')}
                    </StyledTypography>
                </Grid>
                <Grid item xs />
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    p: isMobile ? theme.spacing(0, 3) : 0,
                }}
                container
            >
                <Grid item xs />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    justifyContent="end"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Grid sx={{ p: { xs: theme.spacing(0, 0, 10), md: 0 } }} item xs={12}>
                        <Button
                            onClick={() => navigateToLogin(`/auth`)}
                            sx={{
                                marginRight: theme.spacing(2),
                                textTransform: 'none',
                                bgcolor: theme.palette.accent.main,
                                color: theme.palette.accent.contrastText,
                                '&:hover': {
                                    bgcolor: `${theme.palette.accent.dark}`,
                                },
                            }}
                            variant="contained"
                        >
                            <StyledTypography> {t('Log_in', 'Log in')}</StyledTypography>
                        </Button>
                    </Grid>
                    <Box
                        justifySelf="end"
                        component="img"
                        sx={{
                            maxWidth: '100%',
                            width: { xs: 500, sm: 450, md: 500, lg: 700 },
                            mr: { sm: theme.spacing(-10), lg: theme.spacing(-18) },
                            mb: theme.spacing(-0.5),
                        }}
                        alt="City skyline"
                        src={'https://cubitblobstorage.blob.core.windows.net/public-images/oslo skyline.svg'}
                    />
                </Grid>
                <Grid item xs />
            </Grid>
            <Grid
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    alignItems: 'center',
                    p: isMobile ? theme.spacing(3, 3) : theme.spacing(11, 0),
                }}
                container
            >
                <Grid item xs />
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        px: { sm: theme.spacing(5), md: theme.spacing(15), lg: 0 },
                    }}
                    item
                    xs={12}
                    sm={10}
                >
                    <Stack sx={{ width: '100%' }} direction={{ xs: 'column', lg: 'row' }} spacing={isMobile ? 3 : 6}>
                        {cardContent.map((card, i) => (
                            <HomeCard
                                key={i}
                                header={card.header}
                                body={card.body}
                                linkText={card.linkText}
                                linkUrl={card.linkUrl}
                            />
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs />
            </Grid>
            <Grid container sx={{ p: isMobile ? theme.spacing(1, 0) : theme.spacing(9, 0) }}>
                <Grid item xs />
                <Grid
                    sx={{ display: 'flex', justifyContent: 'space-between', p: isMobile ? theme.spacing(3) : 0 }}
                    item
                    xs={12}
                    sm={10}
                >
                    <Grid xs={12} item>
                        <StyledTypography sx={{ pb: isMobile ? theme.spacing(2) : theme.spacing(8) }} variant="h4">
                            {t('home_accordion_section_header', 'How to use Koti?')}
                        </StyledTypography>
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid xs={12} item md={6}>
                                {accordionContent.map((accordion, index) => (
                                    <HomeAccordion
                                        key={index}
                                        index={index}
                                        title={accordion.title}
                                        header={accordion.header}
                                        expandedAccordion={expandedAccordion}
                                        handleChangeAccordion={handleChange(index)}
                                        currentImageUrl={accordion.imageUrl}
                                    >
                                        {accordion.body}
                                    </HomeAccordion>
                                ))}
                            </Grid>

                            {isMd && (
                                <Grid item xs={12} sm={5}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '100%',
                                            maxHeight: { xs: 233, md: 350 },
                                        }}
                                        alt="Future relative image"
                                        src={currentImageUrl}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
        </Box>
    )
}

export default HomeView
