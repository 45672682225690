import React from 'react'
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { useNavigate, useParams } from 'react-router-dom'
import { KotiContainer } from '../general/layout/koti-container'
import { useGetInvoiceQuery } from './invoicing.service'
import { Grid } from '@mui/material'
import { KotiBackButton } from '../general/buttons/back-button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import { SingleInvoiceTop } from './components/single-invoice-top'
import { LinesTable } from './components/lines-table'

export const SingleInvoicePage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { companyId, id } = useParams()
    const { data: invoice } = useGetInvoiceQuery({ companyId: companyId ?? '', invoiceId: id ?? '' })
    if (!invoice || !companyId) return null
    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Grid item xs={12}>
                    <KotiBackButton
                        startIcon={<ArrowBackIcon />}
                        text={t('invoice_overview', 'Til fakturaoversikt')}
                        onClick={() => navigate(-1)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleInvoiceTop invoice={invoice} companyId={companyId} />
                </Grid>
                <Grid item xs={12}>
                    <LinesTable lines={invoice.lines ?? []} />
                </Grid>
            </KotiContainer>
        </div>
    )
}
