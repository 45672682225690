import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../../app/material-ui/material-ui.config'
export const infoBlockStyles = {
    block: css`
        padding: 16px 16px;
    `,
    blockTitle: css`
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
    `,
    children: css`
        padding-left: 24px;
        @media ${getMobileMediaQuery()} {
            padding-left: 0px;
        }
    `,
    line: css`
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        @media ${getMobileMediaQuery()} {
            flex-direction: column;
            align-items: initial;
        }
    `,
    label: css`
        font-size: 14px;
        width: 250px;
        color: rgba(0, 0, 0, 0.55);
    `,
    value: css`
        font-size: 14px;
        color: #333333;
    `,
}
