import { useHasRole, useIsAuthenticated, useIsCompanyAdmin } from '../components/auth/auth.slice'
import { Role } from '../components/auth/role'

export const useCanAccessProducts = (): boolean => {
    return useHasRole(Role.CubitAdmin)
}

export const useCanAccessSearch = (): boolean => {
    return useIsAuthenticated()
}

export const useCanAccessProperty = (): boolean => {
    return useIsAuthenticated()
}

export const useCanAccessCompanyAdmin = (): boolean => {
    return useIsCompanyAdmin()
}
