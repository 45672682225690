import React from 'react'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'

export const CubitRadioGroupAdapter: React.FC<any> = (props: any) => {
    const {
        name,
        options,
        direction = 'column',
        justify = 'flex-start',
        label,
        input,
        labelPlacement,
        ...custom
    } = props

    const radioButtons = options.map((option: any, index: number) => {
        return (
            <Grid key={index} item>
                <FormControlLabel
                    label={option.label}
                    labelPlacement={labelPlacement ? labelPlacement : 'end'}
                    value={option.value}
                    style={option.style}
                    control={<Radio color="primary" {...custom} />}
                />
            </Grid>
        )
    })

    const val = input.value === true ? 'true' : input.value === false ? 'false' : input.value

    const handleOnChange = (event: any) => {
        const v = event.target.value
        const changedValue = v === 'true' ? true : v === 'false' ? false : v

        input.onChange(changedValue)
    }

    return (
        <FormControl component="fieldset">
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup name={name} value={val} onChange={handleOnChange}>
                <Grid container direction={direction} justifyContent={justify}>
                    {radioButtons}
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}
