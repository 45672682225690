import Checkbox from '@mui/material/Checkbox'
type Props = {
    checked?: boolean | undefined
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
}
export const KotiCheckbox: React.FC<Props> = ({ checked, onChange, disabled }) => {
    return (
        <Checkbox
            sx={{ '.MuiSvgIcon-root': { fill: '#000' } }}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    )
}
