import { Company } from '../companies/models/company'
import React from 'react'
import { InvoicesTable } from './invoicing/invoices-table'
import { CurrentPeriodLine } from '../invoicing/components/current-period-line'

type Props = {
    company?: Company
}

export const CompanyAdminInvoiceView: React.FC<Props> = ({ company }) => {
    if (!company) return null
    return (
        <div>
            <CurrentPeriodLine companyId={company.id} />
            <InvoicesTable companyId={company.id} filters={{}} />
        </div>
    )
}
