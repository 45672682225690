import { createSelector } from '@reduxjs/toolkit'
import { settingsApi } from './settings.service'
import i18next from 'i18next'
import { SelectOption } from '../../types/util'

export const selectSettings = settingsApi.endpoints.getSettings.select()

export const selectProductTypeOptions = createSelector(
    selectSettings,
    (state) =>
        state.data?.productTypes.map(
            (type): SelectOption<string> => ({
                // t('Taxes')
                // t('Fire')
                // t('Electricity')
                label: i18next.t(type) || type,
                value: type,
            }),
        ) || [],
)
