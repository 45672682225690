import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { usersApi } from '../users/users.service'
import { AuthContext } from './auth-context.type'
import { LoginResponse } from './auth.service'
import { Role } from './role'

type AuthState = {
    accessToken: string | null
    refreshToken: string | null
    roles: Role[] | null
    id: string | null
    authContext: AuthContext | null
}

const slice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: null,
        refreshToken: null,
        id: null,
        roles: null,
        authContext: null,
    } as AuthState,
    reducers: {
        logout: (state) => {
            state.accessToken = null
            state.refreshToken = null
            state.id = null
            state.roles = null
            state.authContext = null
        },
        setCredentials: (state, { payload }: PayloadAction<LoginResponse>) => {
            state.accessToken = payload.accessToken
            state.refreshToken = payload.refreshToken
            state.id = payload.userId
            state.roles = payload.roles
            state.authContext = payload.authContext
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(usersApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
            state.authContext = payload.authContext
        })
    },
})

export default slice.reducer

export const authActions = slice.actions

export const selectId = (state: RootState) => state.auth.id
export const selectRole = (state: RootState) => state.auth.authContext?.role
export const selectAuthContext = (state: RootState) => state.auth.authContext
export const selectAccessToken = (state: RootState) => state.auth.accessToken
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken

export const useAuthContext = () => useAppSelector(selectAuthContext)
export const useAccessToken = () => useAppSelector(selectAccessToken)
export const useRefreshToken = () => useAppSelector(selectRefreshToken)
export const useAuthContextRole = () => useAppSelector(selectRole)

export const useHasRole = (role: Role): boolean => {
    const context = useAuthContext()
    return context?.role === role
}

export const useIsAuthenticated = (): boolean => {
    return useAppSelector(selectId) != null
}

export const useIsAdmin = (): boolean => {
    return useHasRole(Role.CubitAdmin)
}

export const useIsCompanyAdmin = (): boolean => {
    return useHasRole(Role.RealtorAdmin)
}

export const useIsCompanyGuy = (): boolean => {
    const isAdmin = useHasRole(Role.RealtorAdmin)
    const isRealtor = useHasRole(Role.RealtorUser)
    return isAdmin || isRealtor
}

export const useIsOsloGuy = (): boolean => {
    const osloUser = useHasRole(Role.OsloUser)
    const osloReadOnly = useHasRole(Role.OsloReadOnly)
    const osloReadOnlyLimited = useHasRole(Role.OsloReadOnlyLimited)
    return osloUser || osloReadOnly || osloReadOnlyLimited
}

export const useIsOsloLimited = (): boolean => {
    return useHasRole(Role.OsloReadOnlyLimited)
}
export const useIsOsloReadOnly = () => useHasRole(Role.OsloReadOnly)
