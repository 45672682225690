/** @jsxImportSource @emotion/react */
import { useAppDispatch } from '../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { authActions, useIsOsloGuy, useRefreshToken } from '../auth/auth.slice'
import { Logout } from '@mui/icons-material'
import { KotiButton1 } from '../general/buttons/koti-button-1'
import { useLazyLogoutQuery } from '../auth/auth.service'
import { useMediaQuery, useTheme } from '@mui/material'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'

export const LogoutButton: React.FC = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const isOsloGuy = useIsOsloGuy()
    const refreshToken = useRefreshToken()
    const theme = useTheme()
    const [logout] = useLazyLogoutQuery()
    const isMobile = useMediaQuery(getMobileMediaQuery())
    const color = isMobile ? theme.palette.primary.main : ''

    const onLogout = () => {
        logout(refreshToken ?? '')
        dispatch(authActions.logout())
        if (isOsloGuy) {
            const url = `https://login.microsoftonline.com/e6795081-6391-442e-9ab4-5e9ef74f18ea/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`
            window.location.replace(url)
        }
    }
    return <KotiButton1 color={color} startIcon={<Logout />} onClick={onLogout} text={t('TEXT_LOGOUT', 'Logg ut')} />
}
