import { CubitCompany } from './models/cubit-company.type'
import { TFunction } from 'i18next'
import { CompanyStatus } from './models/company'

export const formatOrgNumber = (organizationNumber: string) => {
    if (!organizationNumber) return organizationNumber
    return organizationNumber.slice(0, 3) + ' ' + organizationNumber.slice(3, 6) + ' ' + organizationNumber.slice(6)
}

export const getPostalCode = (company: CubitCompany): string => {
    if (company.visitingAddress.postalCode) {
        return company.visitingAddress.postalCode
    }
    if (company.postalAddress.postalCode) {
        return company.postalAddress.postalCode
    }
    return 'Unknown'
}

export const getCompanyStatusText = (t: TFunction, status: CompanyStatus): string => {
    switch (status) {
        case CompanyStatus.Active:
            return t('TEXT_ACTIVE', 'Aktiv')
        case CompanyStatus.Inactive:
            return t('TEXT_INACTIVE', 'Inaktiv')
        case CompanyStatus.WaitingForSigning:
            return t('TEXT_WAITING_FOR_SIGNING', 'Contract sent')
        default:
            return t('TEXT_UNKNOWN', 'Unknown')
    }
}
export const getCompanyInvoiceInterval = (t: TFunction) => {
    return [
        { label: t('TEXT_AMOUNT_OF_DAYS', '{{count}} dager', { count: 14 }), value: 14 },
        { label: t('TEXT_AMOUNT_OF_DAYS', '{{count}} dager', { count: 30 }), value: 30 },
    ]
}
