import { Button, styled } from '@mui/material'

const StyledContainedButton = styled(Button)(({ theme }) => ({
    color: `${theme.palette.common.white}`,
    backgroundColor: `${theme.palette.accent.main}`,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: `${theme.palette.accent.dark}`,
    },
}))

export default StyledContainedButton
