import { TFunction } from 'i18next'
import { TableStructure } from '../../general/table/table-structure.type'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortDirection } from '../../general/models/sort-direction'
import { Paper } from '@mui/material'
import { KotiTable } from '../../general/table/koti-table'
import { RealtorUsersFilters } from './realtor-users-filters'
import { useLazyGetRealtorListQuery } from '../../users/users.service'
import { CompanyUserWithPid } from '../../users/types/company-user'
import { getUserTypeText } from '../../users/user-helper'

const getRealtorUsersTableStructure = (t: TFunction): TableStructure<CompanyUserWithPid>[] => [
    {
        label: t('TEXT_NAME', 'Navn'),
        columnName: 'name',
        sortFunction: (object) => object.name,
        value: (object) => object.name,
    },
    {
        label: t('TEXT_REALTOR_COMPANY', 'Meglerkontor'),
        columnName: 'companyName',
        sortFunction: (object) => object.companyName,
        value: (object) => object.companyName,
    },
    {
        label: t('TEXT_ORG_NR', 'Org nr'),
        columnName: 'orgNr',
        sortFunction: (object) => object.orgNr,
        value: (object) => object.orgNr,
    },
    {
        label: t('TEXT_ROLE', 'Role'),
        columnName: 'role',
        sortFunction: (object) => object.role,
        value: (object) => getUserTypeText(t, object.role),
    },
    {
        label: t('email', 'Email'),
        columnName: 'email',
        sortFunction: (object) => object.email,
        value: (object) => object.email,
    },
]
export const RealtorUsersTable: React.FC<{ filters: RealtorUsersFilters }> = ({ filters }) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(50)
    const [sortBy, setSortBy] = useState('name')
    const [direction, setDirection] = useState<SortDirection>('desc')

    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    const [trigger, { data }] = useLazyGetRealtorListQuery()
    const onSubmit = () => trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters })
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, filters])
    if (!data || !data.data) {
        return null
    }
    return (
        <Paper elevation={0}>
            <KotiTable
                pagination={true}
                rowsPerPageOptions={[50, 100, 200]}
                sortable={false}
                selectable={false}
                tableStructure={getRealtorUsersTableStructure(t)}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                rows={data?.data}
                total={data?.total ?? 0}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
        </Paper>
    )
}
