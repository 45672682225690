/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Add } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { dispatchSubmit } from '../../utils/forms-utils'
import { ProductForm } from './product-form'
import {
    ProductCreateUpdateRequest,
    useCreateProductMutation,
    useGetTenantsQuery,
    useGetVendorsQuery,
} from './products.service'
import StyledContainedButton from '../styled/styled-contained-button'
import { useAppSelector } from '../../redux/hooks'
import { selectProductTypeOptions } from '../settings/settings.selectors'

const styles = {
    addButton: css`
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
        @media ${getMobileMediaQuery()} {
            margin-left: 16px;
        }
    `,
}

export const AddProduct: React.FC = () => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const FORM_ID = 'ADD_NEW_PRODUCT'

    const productTypeOptions = useAppSelector(selectProductTypeOptions)
    const { data: tenants = [] } = useGetTenantsQuery()
    const { data: vendors = [] } = useGetVendorsQuery()
    const [create, { isLoading }] = useCreateProductMutation()

    const onSubmit = (values: any) => {
        const newProduct = values as ProductCreateUpdateRequest
        if (!isLoading) {
            create(newProduct)
                .unwrap()
                .then(() => setOpen(false))
        }
    }

    return (
        <>
            <StyledContainedButton
                disableElevation
                css={styles.addButton}
                startIcon={<Add />}
                color={'secondary'}
                variant={'contained'}
                onClick={() => setOpen(true)}
            >
                {t('TEXT_ADD_PRODUCT', 'Legg til nytt produkt')}
            </StyledContainedButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    {t('TEXT_ADD_PRODUCT_TITLE', 'Legg til nytt produkt')}
                </DialogTitle>
                <DialogContent>
                    <div>{t('TEXT_ADD_PRODUCT_DETAILS', 'Legg inn detaljer for nytt produkt.')}</div>
                    <ProductForm
                        id={FORM_ID}
                        onSubmit={onSubmit}
                        tenants={tenants}
                        vendors={vendors}
                        productTypeOptions={productTypeOptions}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '20px' }}>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        disableElevation
                        style={{ boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, 0.15)' }}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(FORM_ID)}
                    >
                        {t('TEXT_SAVE_PRODUCT', 'LAGRE PRODUKT')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
