import * as React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
} from '@mui/material'
import { Option } from './controlled-mui-select'

export type ControlledMultiCheckboxFieldProps<T extends FieldValues> = {
    name: Path<T>
    control: Control<T>
    label?: string
    options: Array<Option>
} & Omit<CheckboxProps, 'name' | 'onChange'>

export const ControlledMultiCheckboxField = <T extends FieldValues>({
    name,
    control,
    label,
    options,
}: ControlledMultiCheckboxFieldProps<T>) => {
    const { t } = useTranslation()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <div>
                        <FormControl component="fieldset" variant="standard" fullWidth error={!!error?.message}>
                            <FormLabel component="legend" sx={{ mb: 2 }}>
                                {label}
                            </FormLabel>
                            <FormGroup row sx={{ gap: 2 }}>
                                {options.map((opt) => (
                                    <FormControlLabel
                                        {...field}
                                        key={opt.value}
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    if (!field.value.includes(opt.value)) {
                                                        field.onChange([...field.value, opt.value])
                                                        return
                                                    }
                                                    const newTopics = field.value.filter(
                                                        (v: string | number) => v !== opt.value,
                                                    )
                                                    field.onChange(newTopics)
                                                }}
                                            />
                                        }
                                        label={opt.label}
                                    />
                                ))}
                            </FormGroup>
                            {error?.message && <FormHelperText error>{t(error.message)}</FormHelperText>}
                        </FormControl>
                    </div>
                )
            }}
        />
    )
}
