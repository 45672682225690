import { PropertyResponseItem, PropertyType, PropertyTypes } from '../search/models/property-search-response'
import { useIsOsloGuy } from '../auth/auth.slice'
import { HousingCommunityPropertyView } from './propertyViews/housing-community/HousingCommunityPropertyView'
import { OsloUserPropertyView } from './propertyViews/oslo/OsloUserPropertyView'
import { MainPropertyView } from './propertyViews/main-property/MainPropertyView'
import { SectionedPropertyView } from './propertyViews/sectioned/SectionedPropertyView'

type PropertyViewBuilderProps = {
    units: PropertyResponseItem[]
    type: PropertyType
}

export const PropertyViewBuilder: React.FC<PropertyViewBuilderProps> = ({ units, type }) => {
    const isOsloUser = useIsOsloGuy()

    if (isOsloUser) return <OsloUserPropertyView units={units} />

    switch (type) {
        case PropertyTypes.HousingCommunity:
            return <HousingCommunityPropertyView units={units} />
        case PropertyTypes.MainProperty:
        case PropertyTypes.LeasedProperty:
            return <MainPropertyView units={units} />
        case PropertyTypes.SectionedProperty:
        case PropertyTypes.LeasedSectionedProperty:
            return <SectionedPropertyView units={units} type={type} />
        default:
            return <OsloUserPropertyView units={units} />
    }
}
