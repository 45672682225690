import { Invoice } from '../types/invoice.type'
import { OutgoingInvoiceStatus } from '../invoice-status.enum'
import { useTranslation } from 'react-i18next'

type Props = {
    invoice: Invoice
}
export const InvoiceStatus: React.FC<Props> = ({ invoice }) => {
    const { t } = useTranslation()
    const getStatusColor = (status: OutgoingInvoiceStatus) => {
        switch (status) {
            case OutgoingInvoiceStatus.Draft:
                return { color: 'gray', label: t('invoice.status.draft', 'Draft') }
            case OutgoingInvoiceStatus.Approved:
                return { color: 'blue', label: t('invoice.status.approved', 'Approved') }
            case OutgoingInvoiceStatus.Sent:
                return { color: 'gold', label: t('invoice.status.sent', 'Sent') }
            case OutgoingInvoiceStatus.Paid:
                return { color: 'green', label: t('invoice.status.paid', 'Paid') }
            case OutgoingInvoiceStatus.Archived:
                return { color: 'orange', label: t('invoice.status.archived', 'Archived') }
            default:
                return { color: 'black', label: t('invoice.status.unknown', 'Unknown') }
        }
    }

    const { color, label } = getStatusColor(invoice.status)

    return (
        <div>
            <div
                style={{
                    display: 'inline-block',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: color,
                }}
            />
            <span style={{ marginLeft: '8px' }}>{label}</span>
        </div>
    )
}
