import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Invoice } from '../../models/ine-data.type'
import { IneProductDetailedReportTable } from '../ine-product-detailed-report-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../utils/date-utils'
import { IneProductChannelDispatchesTable } from '../ine-product-channel-dispatches-table'
import { useGetDispatchesQuery } from 'redux/apis/multi-channels-api'

type Props = {
    setOpen: () => void
    invoice: Invoice
}
export interface DialogTitleProps {
    children?: React.ReactNode
    onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: 16 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}
export const InvoicePopup: React.FC<Props> = ({ setOpen, invoice }) => {
    const { t } = useTranslation()
    const customerId = invoice.kid.substring(invoice.kid.length - 9, invoice.kid.length - 1)

    const { data, isSuccess, isFetching } = useGetDispatchesQuery({ customerId, invoiceNumber: invoice.number })

    return (
        <Dialog open={true} onClose={() => setOpen()} fullWidth={true} maxWidth={'lg'}>
            <BootstrapDialogTitle onClose={() => setOpen()}>
                <span>
                    {invoice.year} {t('TEXT_TERM', 'Termin')} {invoice.term}{' '}
                    <span style={{ display: 'block' }}>{formatDate(invoice.date)}</span>
                </span>
                <div>
                    {invoice.kid ? (
                        <>
                            {t('TEXT_KID_NUMBER', 'KID-nummer:')} {invoice.kid}
                        </>
                    ) : (
                        <> {t('ESTIMATED_INVOICE', 'Estimerte avgifter, denne terminen er ikke fakturert ennå')}</>
                    )}
                </div>
                <div>{`${t('TEXT_INVOICE_NR')} ${invoice.number}`}</div>

                {isSuccess && (
                    <Box mt={4}>
                        {`${t('TEXT_OSLO_DISPATCHES_REGISTERED_AS_DIRECT_DEBIT', 'Registrert med avtalegiro')} ${
                            data.registeredAsDirectDebit ? t('TEXT_YES') : t('TEXT_NO')
                        }`}
                    </Box>
                )}
            </BootstrapDialogTitle>
            <DialogContent>
                <IneProductDetailedReportTable invoice={invoice} />
                <Box sx={{ width: '100%', height: 2, mt: 4, mb: 0.75, border: '1px solid #a9a9a9' }} />
                <IneProductChannelDispatchesTable
                    isLoading={isFetching}
                    isSuccess={isSuccess}
                    dispatches={data?.dispatches}
                />
            </DialogContent>
        </Dialog>
    )
}
