/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { Add, Close } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { dispatchSubmit } from '../../../utils/forms-utils'
import { css } from '@emotion/react'
import { getMobileMediaQuery } from '../../../app/material-ui/material-ui.config'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { ValidatorRequired } from '../../../utils/validators/validator-required'
import { FieldArray } from 'react-final-form-arrays'
import { CubitTextFieldAdapter } from '../../general/forms/cubit-text-field-adapter'
import arrayMutators from 'final-form-arrays'
import { useSendContractMutation } from '../../../redux/apis/companies-api'
import { Signatory } from '../types/signatory.type'
import StyledContainedButton from '../../styled/styled-contained-button'

const Signatories: React.FC = () => {
    const { t } = useTranslation()
    return (
        <>
            <FieldArray name="signatories">
                {({ fields }) => (
                    <div>
                        {fields.map((name, index) => (
                            <div key={name}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: 16 }}>
                                        <Field
                                            style={{ width: 400 }}
                                            name={`${name}.name`}
                                            component={CubitTextFieldAdapter}
                                            label={t('name', 'Name')}
                                            validate={(value) =>
                                                ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                                            }
                                            variant="standard"
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            style={{ width: 400 }}
                                            name={`${name}.email`}
                                            component={CubitTextFieldAdapter}
                                            label={t('email', 'Email')}
                                            validate={(value) =>
                                                ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                                            }
                                            variant="standard"
                                        />
                                    </div>
                                    <div>
                                        <IconButton size={'small'} onClick={() => fields.remove(index)}>
                                            <Close />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button
                            style={{ marginTop: 20 }}
                            endIcon={<Add />}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            {t('addSignatory', 'Add signatory')}
                        </Button>
                    </div>
                )}
            </FieldArray>
        </>
    )
}

type Props = {
    companyId: string
    onDone: () => void
}
const styles = {
    addButton: css`
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
        @media ${getMobileMediaQuery()} {
            margin-left: 16px;
        }
    `,
}
export const SendContractDialog: React.FC<Props> = ({ companyId, onDone }) => {
    const [isOpen, setOpen] = useState(false)
    const FORM_ID = 'SEND_CONTRACT'
    const { t } = useTranslation()
    const [create, { isLoading }] = useSendContractMutation()
    const onSubmit = (values: any) => {
        const signatories = values.signatories as Signatory[]
        setOpen(false)
        if (!isLoading) {
            create({ companyId, signatories })
                .unwrap()

                .then(() => onDone())
        }
    }
    return (
        <>
            <StyledContainedButton
                disableElevation
                css={styles.addButton}
                startIcon={<Add />}
                color={'secondary'}
                variant={'contained'}
                onClick={() => setOpen(true)}
            >
                {t('contract.sendContract', 'Send contract')}
            </StyledContainedButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>{t('contract.sendContract', 'Send contract')}</DialogTitle>
                <DialogContent>
                    <div>
                        {t(
                            'contract.sendContractDetails',
                            'Lets get serious. This will send emails to real people and generate contracts.',
                        )}
                    </div>
                    <Form
                        mutators={{
                            ...arrayMutators,
                        }}
                        onSubmit={onSubmit}
                        initialValues={{
                            signatories: [
                                {
                                    name: 'Arild Soldal',
                                    email: 'soldal@cubit.no',
                                },
                                {
                                    name: 'Odd Gurvin',
                                    email: 'gurvin@cubit.no',
                                },
                                {
                                    name: undefined,
                                    email: undefined,
                                },
                            ],
                        }}
                        render={({ handleSubmit }) => (
                            <form id={FORM_ID} onSubmit={handleSubmit}>
                                <div>
                                    <Signatories />
                                </div>
                            </form>
                        )}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '20px' }}>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        disableElevation
                        style={{ boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, 0.15)' }}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(FORM_ID)}
                    >
                        {t('contract.send', 'Send')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
