import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { Config } from './config'
import { RootState } from './store'
import { LoginResponse } from '../components/auth/auth.service'
import { authActions } from '../components/auth/auth.slice'

export const prepareAuthHeaders = (
    headers: Headers,
    { getState }: { getState: () => unknown },
): MaybePromise<Headers> => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.accessToken
    if (token) {
        headers.set('authorization', `Bearer ${token}`)
    }
    return headers
}

// create a new mutex
const mutex = new Mutex()

const baseQuery = fetchBaseQuery({ baseUrl: Config.backendUrl, prepareHeaders: prepareAuthHeaders })

export const baseQueryWithReauth: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    const isAuthorized = result.meta?.request.headers.has('authorization')
    if (isAuthorized && result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const state = api.getState() as RootState
                const refreshResult = await baseQuery(
                    `v1/auth/refresh/${state.auth.id}?refreshToken=${state.auth.refreshToken}`,
                    api,
                    extraOptions,
                )
                if (refreshResult.data) {
                    api.dispatch(authActions.setCredentials(refreshResult.data as LoginResponse))
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(authActions.logout())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}
