import { Order, OrderTableItem } from './models/order'
import { formatCadastreNumber } from '../../utils/cadastre-utils'
import { getUniqueList } from '../../utils/array-utils'

export const getMatrikkelNumbers = (order: Order) => {
    const matrikkelNumbers = order.items.map((i) => formatCadastreNumber(i.cadastreNr))
    return getUniqueList(matrikkelNumbers)
}

export const allPdfsGenerated = (order: Order): boolean => {
    return order.items.every((x) => x.pdf != null)
}
export const getProductList = (orders: Order[]): OrderTableItem[] => {
    const orderTableItems: OrderTableItem[] = []

    for (const order of orders) {
        let index = 0
        for (const item of order.items) {
            orderTableItems.push({
                created: order.created,
                index: index,
                userId: order.userId,
                orderId: order.id,
                orderNumber: order.orderId,
                ...item,
                invoiceId: order.invoiceId,
            })
            index++
        }
    }

    return orderTableItems
}
