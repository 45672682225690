import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from './product'
import { RootState } from '../../redux/store'
export enum ProductFilterType {
    q = 'q',
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    vendorId = 'vendorId',
}
export type ProductFilters = {
    q?: string
    dateFrom?: string
    dateTo?: Date
    vendorId?: string
}
type ProductsState = {
    selectedProducts: Product[]
    productFilters: ProductFilters
}

const slice = createSlice({
    name: 'products',
    initialState: { selectedProducts: [], productFilters: { vendorId: '', dateFrom: '01.01.2000' } } as ProductsState,
    reducers: {
        setSelectedProducts: (state, { payload }: PayloadAction<Product[]>) => {
            state.selectedProducts = payload
        },
        setProductFilter: (state, { payload }: PayloadAction<{ filter: ProductFilterType; value: any }>) => {
            state.productFilters[payload.filter] = payload.value
        },
    },
})

export const selectSelectedProducts = (state: RootState) => state.products.selectedProducts
export const selectProductFilters = (state: RootState) => state.products.productFilters

export default slice.reducer

export const productsActions = slice.actions
