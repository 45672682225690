import { styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}))

export const SearchRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}))

export const SearchFieldContainer = styled('div')(({ theme }) => ({
    width: '60%',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}))
