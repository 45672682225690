import { appApi } from '../../redux/api'
import { Invoice } from './types/invoice.type'
import { PaginationParams } from '../general/models/pagination-params'
import { InvoiceFilters } from './invoice-filters.type'
import { PaginatedResults } from '../general/models/paginated-results'
import { CurrentPeriodData } from './types/current-period-data.type'
import { CurrentPeriodParams } from './types/current-period-params.type'

const invoicing = appApi.injectEndpoints({
    endpoints: (builder) => ({
        createInvoice: builder.mutation<void, string[]>({
            query: (params) => ({
                url: `/v1/invoicing/create-for-companies`,
                method: 'POST',
                body: { ids: params },
            }),
            invalidatesTags: [],
        }),
        getInvoices: builder.query<
            PaginatedResults<Invoice>,
            { companyId: string; paginationParams: PaginationParams<Invoice, InvoiceFilters> }
        >({
            query: (params) => ({
                url: `/v1/invoicing/get-invoices/${params.companyId}`,
                method: 'GET',
                params: params.paginationParams,
            }),
            providesTags: ['Invoices'],
        }),
        getInvoice: builder.query<Invoice, { companyId: string; invoiceId: string }>({
            query: (params) => ({
                url: `/v1/invoicing/${params.companyId}/${params.invoiceId}`,
                method: 'GET',
            }),
        }),
        getCurrentPeriodData: builder.query<CurrentPeriodData, { companyId: string; params: CurrentPeriodParams }>({
            keepUnusedDataFor: 180,
            query: (params) => ({
                url: `/v1/invoicing/current-period-data/${params.companyId}`,
                method: 'GET',
                params: params.params,
            }),

            providesTags: ['Invoices'],
        }),
    }),
})

export const { useCreateInvoiceMutation, useGetInvoiceQuery, useLazyGetInvoicesQuery, useGetCurrentPeriodDataQuery } =
    invoicing
