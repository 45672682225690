/** @jsxImportSource @emotion/react */
import { KotiTable } from '../../general/table/koti-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortDirection } from '../../general/models/sort-direction'
import { TFunction } from 'i18next'
import { TableStructure } from '../../general/table/table-structure.type'
import { formatDate } from '../../../utils/date-utils'
import { Paper } from '@mui/material'
import { Invoice } from '../../invoicing/types/invoice.type'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useLazyGetInvoicesQuery } from '../../invoicing/invoicing.service'
import { InvoiceFilters } from '../../invoicing/invoice-filters.type'
import { InvoiceStatus } from '../../invoicing/components/invoice-status'
import { InvoicePeriod } from '../../invoicing/components/invoice-period'
import { getInvoiceRoute } from '../../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'
import StyledTypography from '../../styled/styled-typography'

const styles = {
    heading: css`
        font-size: 16px;
        margin: 16px;
        text-transform: uppercase;
    `,
}
const getInvoicesTableStructure = (t: TFunction): TableStructure<Invoice>[] => [
    {
        label: t('invoice_number', 'Fakturanr.'),
        columnName: 'invoiceNumber',
        sortFunction: (object) => object.invoiceNumber,
        value: (object) => object.invoiceNumber,
    },
    {
        label: t('invoice_period', 'Faktureringsperiode'),
        columnName: 'invoice_period',
        value: (object) => <InvoicePeriod invoice={object} />,
    },
    {
        label: t('invoice_date', 'Fakturert dato'),
        columnName: 'sentDate',
        sortFunction: (object) => object.sentDate,
        value: (object) => formatDate(object.sentDate),
    },

    {
        label: t('due_date', 'Forfallsdato'),
        columnName: 'dueDate',
        sortFunction: (object) => object.dueDate,
        value: (object) => formatDate(object.dueDate),
    },
    {
        label: t('TEXT_TOTAL_SUM', 'Totalsum'),
        columnName: 'totalAmount',
        sortFunction: (object) => object.totalAmount,
        value: (object) => object.totalAmount,
    },
    {
        label: t('TEXT_STATUS', 'Status'),
        columnName: 'status',
        sortFunction: (object) => object.status,
        value: (object) => <InvoiceStatus invoice={object} />,
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (property) => property.id,
        value: () => (
            <div style={{ textAlign: 'right' }}>
                <KeyboardArrowRightIcon />
            </div>
        ),
    },
]
export const InvoicesTable: React.FC<{ filters: InvoiceFilters; companyId: string }> = ({ filters, companyId }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('createdDate')
    const [direction, setDirection] = useState<SortDirection>('desc')
    const [trigger, { data, isFetching }] = useLazyGetInvoicesQuery()
    const onSubmit = () =>
        trigger({
            companyId: companyId,
            paginationParams: { page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...filters },
        })
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, itemsPerPage, sortBy, direction, filters])
    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    return (
        <div>
            <StyledTypography variant="h6" css={styles.heading}>
                {t('invoicedOrders', 'fakturerte oppslag')}
            </StyledTypography>
            <Paper elevation={0}>
                <KotiTable
                    isFetching={isFetching}
                    sortable={false}
                    pagination={true}
                    onRowClick={(invoice) => {
                        navigate(getInvoiceRoute(companyId, invoice))
                    }}
                    selectable={false}
                    tableStructure={getInvoicesTableStructure(t)}
                    rowsPerPage={itemsPerPage}
                    page={pageNumber}
                    rows={data?.data}
                    total={data?.total ?? 0}
                    onSortChanged={handleOnSortChange}
                    handleChangePage={setPageNumber}
                    handleChangeRowsPerPage={setItemsPerPage}
                />
            </Paper>
        </div>
    )
}
