/** @jsxImportSource @emotion/react */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Paper } from '@mui/material'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formatCadastreNumber } from '../../utils/cadastre-utils'
import { getHistoricPropertyRoute, getPropertyRoute } from '../../utils/routes'
import { KotiTable } from '../general/table/koti-table'
import { TableStructure } from '../general/table/table-structure.type'
import { AddressSearchResponse, HistoricSearchEntity } from './models/address-search-response'
import HistoryIcon from '@mui/icons-material/History'

const getSearchTableStructure = (t: TFunction): TableStructure<any>[] => [
    {
        label: t('TEXT_ADDRESS', 'Adresse'),
        columnName: 'address',
        sortFunction: (property) => property.addressText,
        value: (property) => property.addressText,
    },
    {
        label: t('TEXT_POST_NUMBER', 'Postnummer'),
        columnName: 'postalCode',
        sortFunction: (property) => property.postalCode,
        value: (property) =>
            `${property.postalCode ? property.postalCode : ''} ${property.postalName ? property.postalName : ''}`,
    },
    {
        label: t('TEXT_CADASTRE_NR', 'Matrikkelnr.'),
        columnName: 'cadastreNumber',
        sortFunction: (property) => formatCadastreNumber(property.cadastreNumber),
        value: (property) => formatCadastreNumber(property.cadastreNumber),
    },
    {
        label: t('TEXT_MUNICIPALITY', 'Kommune'),
        columnName: 'municipalityName',
        sortFunction: (property) => property.municipalityName,
        value: (property) => property.municipalityName,
        mobileDisplay: 'none',
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (property) => property.name,
        value: (object) => {
            return (
                <span>
                    {object.isHistoric && <HistoryIcon />}
                    <KeyboardArrowRightIcon />
                </span>
            )
        },
        mobileDisplay: 'none',
    },
]

type SearchResultsTableProps = {
    addressSearchResults: AddressSearchResponse[]
    historicResults: HistoricSearchEntity[]
    isLoading: boolean
}

export const SearchResultsTable: React.FC<SearchResultsTableProps> = ({
    addressSearchResults,
    historicResults,
    isLoading,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(20)

    const tableStructure = getSearchTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            <KotiTable
                onRowClick={(item: AddressSearchResponse | any) => {
                    if (item.isHistoric) {
                        navigate(getHistoricPropertyRoute(item))
                    } else {
                        if (item.cadastreNumber) {
                            navigate(getPropertyRoute(item))
                        }
                    }
                }}
                tableStructure={tableStructure}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                rows={addressSearchResults.length > 0 ? addressSearchResults : historicResults}
                selectable={false}
                pagination={false}
                total={addressSearchResults?.length || 0}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
                isFetching={isLoading}
            />
        </Paper>
    )
}
