import { Container, styled } from '@mui/material'
import * as React from 'react'

type Props = {
    children?: React.ReactNode
}

export const KotiContainer: React.FC<Props> = ({ children }) => {
    return <StyledContainer maxWidth="xl">{children}</StyledContainer>
}

const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2, 0, 0, 0),

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0, 3.75),
    },
}))
