/** @jsxImportSource @emotion/react */
import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { AuthContextSwitcher } from '../auth/auth-context-switcher'
import { mobileNavigationDrawerStyles } from './top-bar-styles'

export const MobileNavigationDrawer = (rightActions: any) => {
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box css={mobileNavigationDrawerStyles.box}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
                {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Drawer
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        height: 'calc(100% - 120px)',
                        top: '60px',
                        bottom: '60px',
                        padding: '50px',
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div>
                    <div css={mobileNavigationDrawerStyles.item}>
                        <AuthContextSwitcher primary />
                    </div>
                    {rightActions?.rightActions.map((a: any, index: any) => (
                        <div
                            onClick={handleDrawerClose}
                            css={mobileNavigationDrawerStyles.item}
                            key={`action_${index}`}
                        >
                            {a}
                        </div>
                    ))}
                </div>
            </Drawer>
        </Box>
    )
}
