import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Box, Checkbox, CircularProgress, TableSortLabel } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import * as React from 'react'
import { TableStructure } from './table-structure.type'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsAdmin } from '../../auth/auth.slice'

type KotiTableProps<T> = {
    tableStructure: TableStructure<T>[]
    rowsPerPage: number
    page: number
    total: number
    rows: T[] | undefined
    pagination?: boolean
    isFetching?: boolean
    selectedKey?: string
    selectable?: boolean
    sortable?: boolean
    selectedItems?: T[]
    defaultOrder?: Order
    orderBy?: string
    rowsPerPageOptions?: number[]
    onRowClick?: (item: T) => any
    onSelectChange?: (items: T[]) => any
    handleChangePage?: (newPage: number) => any
    onSortChanged?: (orderBy: string, order: Order) => any
    handleChangeRowsPerPage?: (itemsPerPage: any) => any
}
type Order = 'asc' | 'desc'
export const KotiTable: React.FC<KotiTableProps<any>> = ({
    tableStructure,
    rowsPerPage,
    isFetching,
    page,
    pagination,
    total,
    sortable = true,
    selectedKey = 'id',
    defaultOrder = 'desc',
    selectable = true,
    onSortChanged,
    rows = [],
    rowsPerPageOptions = [10, 25, 100],
    onRowClick,
    handleChangePage,
    selectedItems = [],
    onSelectChange,
    handleChangeRowsPerPage,
}) => {
    const [order, setOrder] = useState<Order>(defaultOrder)
    const [orderBy, setOrderBy] = useState<string>(defaultOrder)
    const { t } = useTranslation()
    const isAdmin = useIsAdmin()
    if (isAdmin) {
        rowsPerPageOptions = [10, 25, 100, 9999]
    }
    const onChangeOrder = (columnName?: string) => {
        if (!columnName) return
        let newOrder = defaultOrder
        if (orderBy === columnName) {
            newOrder = order === 'asc' ? 'desc' : 'asc'
        }
        setOrder(newOrder)
        setOrderBy(columnName)
        if (onSortChanged) {
            onSortChanged(columnName, newOrder)
        }
    }

    const setItems = (items: any) => {
        if (selectable && onSelectChange) {
            onSelectChange(items)
        }
    }
    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            //filter before spreading to add only unique items
            setItems([
                ...selectedItems.filter(
                    (selectedItem) => !rows.find((row) => row[selectedKey] === selectedItem[selectedKey]),
                ),
                ...rows,
            ])
        } else {
            setItems(
                selectedItems.filter(
                    (selectedItem) => !rows.find((row) => row[selectedKey] === selectedItem[selectedKey]),
                ),
            )
        }
    }
    const handleSelectSingle = (item: any, checked: boolean) => {
        if (checked) {
            setItems([...selectedItems, item])
        } else {
            setItems(selectedItems.filter((selectedItem) => selectedItem[selectedKey] !== item[selectedKey]))
        }
    }
    const isSelected = (item: any): boolean => {
        return selectedItems.find((selectedItem) => selectedItem[selectedKey] === item[selectedKey]) !== undefined
    }
    const isAllPageSelected = (): boolean => {
        if (!rows) return false
        return rows.every((rowInPage) => isSelected(rowInPage))
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: '100%',
                            '.MuiTableCell-head ': {
                                fontWeight: 'bold',
                                fontStretch: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                minHeight: '24px',
                                padding: `8px 16px`,
                                textAlign: 'left',
                                color: '#000',
                                borderBottom: '4px solid #f8f8f8',
                                fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
                            },
                            '.MuiTableSortLabel-root': {
                                padding: '8px',
                                ':focus': {
                                    background: 'rgba(0, 0, 0, 0.1)',
                                },
                            },

                            '.MuiTableCell-body ': {
                                textAlign: 'left',
                                fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
                                borderBottom: {
                                    xs: '4px solid #f8f8f8',
                                    sm: '4px solid #f8f8f8',
                                    md: '1px solid #f8f8f8',
                                    lg: '1px solid #f8f8f8',
                                    xl: '1px solid #f8f8f8',
                                },
                            },
                        }}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead
                            sx={{
                                '.MuiTableRow-head ': {
                                    borderBottom: 'solid 2px #000',
                                },
                            }}
                        >
                            <TableRow>
                                {selectable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            checked={isAllPageSelected()}
                                            onChange={(event) => handleSelectAll(event.target.checked)}
                                        />
                                    </TableCell>
                                )}
                                {tableStructure.map((ts) => (
                                    <TableCell
                                        sx={{
                                            display: {
                                                xs: ts.mobileDisplay || 'table-cell',
                                                sm: ts.mobileDisplay || 'table-cell',
                                                md: 'table-cell',
                                                lg: 'table-cell',
                                                xl: 'table-cell',
                                            },
                                            fontFamily: 'Tomato Grotesk Bold',
                                        }}
                                        key={ts.columnName}
                                        sortDirection={orderBy === ts.columnName ? order : false}
                                    >
                                        {sortable && ts.columnName ? (
                                            <TableSortLabel
                                                aria-label={ts.label ?? 'no-label'}
                                                active={orderBy === ts.columnName}
                                                direction={orderBy === ts.columnName ? order : defaultOrder}
                                                onClick={() => onChangeOrder(ts.columnName)}
                                            >
                                                {ts.label}
                                            </TableSortLabel>
                                        ) : (
                                            <>{ts.label}</>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.map((row, tableRowIndex) => {
                                    return (
                                        <TableRow
                                            onClick={() => (onRowClick ? onRowClick(row) : null)}
                                            onKeyDown={(event) =>
                                                event.key === 'Enter' && onRowClick ? onRowClick(row) : null
                                            }
                                            sx={
                                                onRowClick
                                                    ? {
                                                          cursor: 'pointer',
                                                          '&:focus': {
                                                              backgroundColor: '  rgba(0, 0, 0, 0.04);',
                                                              outline: 'none',
                                                          },
                                                      }
                                                    : null
                                            }
                                            hover
                                            tabIndex={0}
                                            key={tableRowIndex}
                                        >
                                            {selectable && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(e) => e.stopPropagation()}
                                                        onKeyDown={(event) => {
                                                            event.stopPropagation()
                                                            event.key === 'Enter' && // @ts-ignore
                                                                handleSelectSingle(row, !event.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        checked={isSelected(row)}
                                                        onChange={(event) =>
                                                            handleSelectSingle(row, event.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                            )}
                                            {tableStructure.map((ts, index) => (
                                                <TableCell
                                                    sx={{
                                                        display: {
                                                            xs: ts.mobileDisplay || 'table-cell',
                                                            sm: ts.mobileDisplay || 'table-cell',
                                                            md: 'table-cell',
                                                            lg: 'table-cell',
                                                            xl: 'table-cell',
                                                        },
                                                    }}
                                                    key={`${ts.columnName}_${index}`}
                                                >
                                                    {ts.value(row)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isFetching && (
                    <Box
                        sx={{
                            position: 'absolute',
                            zIndex: 100,
                            left: 0,
                            top: 0,
                            height: 'calc(100% - 55px)',
                            width: '100%',
                            marginTop: '55px',
                            maxHeight: (rows.length * 60) / 2 + 15,
                        }}
                    >
                        <Box sx={{ height: '100%', width: '100%' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'rgba(255, 255, 255, 0.38)',
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Box>
                        </Box>
                    </Box>
                )}
            </div>
            {pagination && (
                <TablePagination
                    labelRowsPerPage={<span>{t('ROWS_PER_PAGE', 'Rader per side')}</span>}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => handleChangePage && handleChangePage(newPage)}
                    onRowsPerPageChange={(event) =>
                        handleChangeRowsPerPage && handleChangeRowsPerPage(event.target.value)
                    }
                />
            )}
        </>
    )
}
