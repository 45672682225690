/** @jsxImportSource @emotion/react */
import { Grid, Paper } from '@mui/material'
import { AddUser } from './add-user'
import { UsersTable } from './users-table'
import { css } from '@emotion/react'
type Props = {
    companyId: string
}
export const UsersView: React.FC<Props> = ({ companyId }) => {
    const styles = {
        wrapper: css`
            padding: 16px;
        `,
    }
    return (
        <div>
            <Paper css={styles.wrapper} elevation={0} square={true}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AddUser companyId={companyId} />
                    </Grid>
                    <Grid item xs={12}>
                        <UsersTable companyId={companyId} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
