import { CompanyTable } from '../companies/company-table'
import { AddCompany } from '../companies/add-company'
import { Grid } from '@mui/material'

export const CubitAdminCompaniesView: React.FC = () => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AddCompany />
                </Grid>
                <Grid item xs={12}>
                    <CompanyTable />
                </Grid>
            </Grid>
        </div>
    )
}
