/** @jsxImportSource @emotion/react */
import FormControlLabel from '@mui/material/FormControlLabel'
import { ProductWithAttributes, InvoiceReceiver } from '../product'
import { formatCadastreNumber, isMainProperty } from '../../../utils/cadastre-utils'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { KotiCheckbox } from '../../general/inputs/koti-checkbox'
import { useState } from 'react'

type Props = {
    product: ProductWithAttributes
    productAttributes: Record<string, InvoiceReceiver[]>
    toggleAttribute: (cadastreNr: string, invoiceReceiver: InvoiceReceiver, insert: boolean) => void
}

const styles = {
    grid: css`
        display: flex;
        margin-bottom: 20px;
    `,
    noGrid: css`
        display: grid;
        grid-template-columns: auto;
    `,
    cadastreNumber: css`
        font-weight: 600;
        margin-bottom: 10px;
    `,
    helpText: css`
        margin-bottom: 16px;
    `,
    column1: css`
        width: 200px;
    `,
    divider: css`
        margin-bottom: 16px;
    `,
}

const attributeSelected = (attributes: InvoiceReceiver[] | undefined, agreementNumber: number) => {
    const found = attributes?.find((x) => x.agreementNumber === agreementNumber)
    return found == null ? false : true
}

export const IneProductRealtors: React.FC<Props> = ({ product, productAttributes, toggleAttribute }) => {
    const { t } = useTranslation()
    const [toggleAllChecked, setToggleAllChecked] = useState(true)
    return (
        <div>
            <div css={styles.helpText}>
                {t('INE_PRODUCT_HELP_TEXT', 'Velg om du vil ha kommunale avgifter fra både seksjon og grunneiendom.')}
            </div>
            {product.attributes.map((attribute) => (
                <div key={attribute.matrikeklNr} css={styles.grid}>
                    <div css={styles.column1}>
                        {isMainProperty(attribute.matrikeklNr)
                            ? t('MAIN_PROPERTY', 'Grunneiendom')
                            : t('TEXT_SECTION', 'Seksjon')}{' '}
                        <div css={styles.cadastreNumber}>{formatCadastreNumber(attribute.matrikeklNr)}</div>
                    </div>
                    <div>
                        {attribute.invoiceReceivers.length > 9 && (
                            <div style={{ marginBottom: 10 }}>
                                <FormControlLabel
                                    control={
                                        <KotiCheckbox
                                            checked={toggleAllChecked}
                                            onChange={(e) => {
                                                attribute.invoiceReceivers.forEach((receiver) => {
                                                    toggleAttribute(attribute.matrikeklNr, receiver, e.target.checked)
                                                })
                                                setToggleAllChecked(e.target.checked)
                                            }}
                                        />
                                    }
                                    label={t('TOGGLE_ALL', 'Marker alle')}
                                />
                            </div>
                        )}
                        {attribute.invoiceReceivers.map((agreement) => (
                            <div key={agreement.agreementNumber}>
                                <FormControlLabel
                                    control={
                                        <KotiCheckbox
                                            checked={attributeSelected(
                                                productAttributes[attribute.matrikeklNr],
                                                agreement.agreementNumber,
                                            )}
                                            onChange={(e) =>
                                                toggleAttribute(attribute.matrikeklNr, agreement, e.target.checked)
                                            }
                                        />
                                    }
                                    label={`${agreement.name}  (${t('PART_NR', 'Delnr.')} ${agreement.partNumber})`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <Divider css={styles.divider} />
        </div>
    )
}
