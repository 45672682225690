import React from 'react'
import { ProductWithAttributes } from '../../products/product'
import { FireProduct } from './FireProduct'
import { ElectricityProduct } from './ElectricityProduct'
import { TaxesProduct } from './TaxesProduct'
import { AddToCartRequest, Cart } from 'components/cart/models/cart'
import { CartProperty } from 'components/search/models/property-search-response'
import { useAddToCartMutation, useRemoveFromCartMutation } from 'components/cart/cart.service'

export type PropertyProductViewPropsBase = {
    product: ProductWithAttributes
    onCartButtonClick: (
        selectedProductAttributes?: AddToCartRequest['attributes'] | AddToCartRequest['electricityAttributes'],
    ) => void
    isInCart: boolean
    isLoading: boolean
}

export type TaxesProductViewProps = PropertyProductViewPropsBase

export type ElectricityProductViewProps = {
    selectedAttributesFromCart: AddToCartRequest['electricityAttributes']
} & PropertyProductViewPropsBase

type ProductDisplayProps = {
    product: ProductWithAttributes
    cart: Cart
    property: CartProperty
}

export const ProductDisplay: React.FC<ProductDisplayProps> = React.memo(({ product, cart, property }) => {
    const [addToCart, { isLoading: isAdding }] = useAddToCartMutation()
    const [removeFromCart, { isLoading: isRemoving }] = useRemoveFromCartMutation()

    const relatedCartItem = cart.items.find(
        (item) =>
            item.productId === product.id &&
            (item.cadastreId === property.cadastreId || !item.cadastreId) &&
            item.cadastreNr === property?.cadastreNumber,
    )

    const handleCartButtonClick = async (
        selectedAttributes?: AddToCartRequest['attributes'] | AddToCartRequest['electricityAttributes'],
    ) => {
        if (relatedCartItem) {
            await removeFromCart({
                productId: product.id,
                cadastreNr: property.cadastreNumber,
                cadastreId: property.cadastreId,
            }).unwrap()

            return
        }

        await addToCart({
            productId: product.id,
            cadastreId: property.cadastreId,
            cadastreNumber: property.cadastreNumber,
            attributes: selectedAttributes && !Array.isArray(selectedAttributes) ? selectedAttributes : {},
            electricityAttributes: Array.isArray(selectedAttributes) ? selectedAttributes : undefined,
        }).unwrap()
    }

    switch (product.type) {
        case 'Taxes':
            return (
                <TaxesProduct
                    product={product}
                    onCartButtonClick={handleCartButtonClick}
                    isInCart={Boolean(relatedCartItem)}
                    isLoading={isAdding || isRemoving}
                />
            )
        case 'Fire':
            return (
                <FireProduct
                    product={product}
                    onCartButtonClick={handleCartButtonClick}
                    isInCart={Boolean(relatedCartItem)}
                    isLoading={isAdding || isRemoving}
                />
            )
        case 'Electricity':
            return (
                <ElectricityProduct
                    product={product}
                    onCartButtonClick={handleCartButtonClick}
                    isInCart={Boolean(relatedCartItem)}
                    isLoading={isAdding || isRemoving}
                    selectedAttributesFromCart={relatedCartItem ? relatedCartItem.electricityAttributes : []}
                />
            )
        default:
            return null
    }
})

ProductDisplay.displayName = 'ProductDisplay'
