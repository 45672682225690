/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LinearProgress, Paper } from '@mui/material'
import { OrderStats } from '../models/order-stats.type'
import { useGetOrderStatsQuery } from '../orders.service'
import { OrderFilters } from '../order.slice'

const styles = {
    paper: css`
        padding: 16px 16px 0 16px;
        margin-bottom: 16px;
        cursor: pointer;
        :hover,
        :focus {
            background: #efefef;
        }
    `,
    contentPaper: css`
        padding: 16px 16px 0 16px;
        margin-bottom: 16px;
        margin-top: 16px;
    `,
    wrapper: css`
        display: flex;
        flex-wrap: wrap;
    `,
    block: css`
        margin-right: 40px;
        margin-bottom: 16px;
    `,
    label: css`
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
    `,
    arrow: css`
        flex-grow: 1;
        text-align: right;
        align-self: center;
    `,
    heading: css`
        font-size: 16px;
        margin: 16px;
        text-transform: uppercase;
    `,
    value: css`
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        padding-right: 20px;
    `,
}
type Props = {
    filters: OrderFilters
}

const Content: React.FC<{ data?: OrderStats }> = ({ data }) => {
    const { t } = useTranslation()
    return (
        <div css={styles.wrapper}>
            <div css={styles.block}>
                <div css={styles.label}>{t('total_billable_reports', 'Total billable count')}</div>
                <div css={styles.value}>
                    <div css={styles.value}>{data?.itemsCount}</div>
                </div>
            </div>
            <div css={styles.block}>
                <div css={styles.label}>{t('TEXT_SAME_DAY_EXEMPTIONS', 'Same day exemptions')}</div>
                <div css={styles.value}>{data?.sameDayExemptions ?? 0}</div>
            </div>
        </div>
    )
}
export const AdminOrdersOverview: React.FC<Props> = ({ filters }) => {
    const { data, isLoading } = useGetOrderStatsQuery({ page: 0, ...filters })
    return (
        <div>
            {isLoading && <LinearProgress />}
            <Paper tabIndex={0} elevation={0} css={styles.contentPaper}>
                <Content data={data} />
            </Paper>
        </div>
    )
}
