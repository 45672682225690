import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import ModalsWrapper from 'components/general/dialogs/dialog-wrapper'

type TokenKeyViewDialogProps = {
    isOpen: boolean
    onClose: () => void
    createdKey: string | null
}

export const TokenKeyViewDialog: React.FC<TokenKeyViewDialogProps> = ({ isOpen, onClose, createdKey }) => {
    const { t } = useTranslation()

    return (
        <ModalsWrapper
            open={isOpen}
            title={t('TOKEN_KEY_VIEW_DIALOG_TITLE', 'Your created key')}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="md"
            body={
                <>
                    <Typography variant="body1">
                        {t(
                            'TEXT_ACCESS_TOKEN_CREATED_DIALOG_HELPER_TEXT',
                            'Your access token has been created please save it as you will only be able to see it once',
                        )}
                    </Typography>

                    <KeyContainer>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {createdKey}
                        </Typography>
                    </KeyContainer>
                </>
            }
        />
    )
}

const KeyContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    paddingLeft: 0,
    marginTop: theme.spacing(4),
    background: '#FFF',
    overflowX: 'auto',
    height: 70,
}))
