import Table from '@mui/material/Table'
import { TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Agreement, Invoice } from '../../models/ine-data.type'
import { filterInvoicesByYear, formatCurrencyWithoutCode } from '../ine-product-helper'
import { formatDate, getDateObject } from '../../../../utils/date-utils'
import TablePagination from '@mui/material/TablePagination'
import * as React from 'react'
import { Nullable } from '../../../../utils/types/nullable.type'
import { InvoicePopup } from './invoice-popup'
import { formatNumber } from '../../../../utils/number-utils'
import { Info } from '@mui/icons-material'
import { useIsOsloLimited } from '../../../auth/auth.slice'

type Props = {
    year: string
    agreement: Agreement
}
export const OverviewList: React.FC<Props> = ({ agreement, year }) => {
    const { t } = useTranslation()
    const [page, setPage] = React.useState(0)
    const [opened, setOpened] = React.useState<Nullable<Invoice>>(null)
    const rows = 20
    const isLimitedUser = useIsOsloLimited()
    const invoices = filterInvoicesByYear(year, agreement)
    return (
        <div>
            <div style={{ overflowX: 'scroll' }}>
                {opened && <InvoicePopup setOpen={() => setOpened(null)} invoice={opened} />}
                <Table sx={{ minWidth: 650, overflowX: 'scroll' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('TEXT_LEDGER_YEAR', 'Resk.År')}</TableCell>
                            <TableCell>{t('TEXT_YEAR', 'År')}</TableCell>
                            <TableCell>{t('TEXT_TERM', 'Termin')}</TableCell>
                            <TableCell>{t('TEXT_INVOICE_DATE', 'Faktdato')}</TableCell>
                            <TableCell align={'right'} style={{ width: '120px' }}>
                                {t('TEXT_INVOICE_NR', 'Fakturanummer')}
                            </TableCell>
                            <TableCell align={'right'}>{t('TEXT_DUE_DATE', 'Frist')}</TableCell>
                            <TableCell style={{ width: '200px' }}>
                                {t('INVOICE_CUSTOMER_NAME', 'Kundenavn faktura')}
                            </TableCell>
                            <TableCell align={'right'} style={{ width: '200px' }}>
                                {t('TEXT_INVOICE_AMOUNT', 'Fakturert beløp')}
                            </TableCell>
                            <TableCell align={'right'}>
                                {t('TEXT_BALANCE', 'Saldo')}{' '}
                                <sup>
                                    <Tooltip
                                        title={t(
                                            'balance_tooltip',
                                            'Innsynsløsning er til intern bruk. Saldoopplysninger skal ikke oppgis av FAG. Saldo kan være feil dersom det for eksempel finnes en innfordrings-sak på kunde',
                                        )}
                                        placement={'top-start'}
                                    >
                                        <Info fontSize={'small'} />
                                    </Tooltip>
                                </sup>
                            </TableCell>
                            <TableCell>{t('TEXT_KID_NR', 'KID-nummer')}</TableCell>
                            {!isLimitedUser && (
                                <>
                                    <TableCell>{t('REMINDER', 'Purret ')}</TableCell>
                                    <TableCell>{t('STOPPED', 'Stoppet')}</TableCell>
                                    <TableCell>{t('SENT_FOR_COLLECTION', 'Sendt til innfordring')}</TableCell>
                                </>
                            )}
                            <TableCell>{t('DISTRIBUTION_DESCRIPTION', 'Distribusjonsbeskrivelse')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices
                            .sort((a, b) => {
                                if (!a.date || !b.date) {
                                    return 0
                                }
                                return getDateObject(b.date).diff(getDateObject(a.date))
                            })
                            .slice(page * rows, page * rows + rows)
                            .map((invoice, index) => {
                                return (
                                    <TableRow
                                        style={{ cursor: 'pointer' }}
                                        key={index}
                                        onClick={() => {
                                            setOpened(invoice)
                                        }}
                                    >
                                        <TableCell>{invoice.ledgerYear > 0 ? invoice.ledgerYear : ''}</TableCell>
                                        <TableCell>{invoice.year}</TableCell>
                                        <TableCell>{invoice.term}</TableCell>
                                        <TableCell>{formatDate(invoice.date)}</TableCell>
                                        <TableCell>
                                            {invoice.number > 0 ? (
                                                invoice.number
                                            ) : (
                                                <i>{t('NO_INVOICE_NR', 'Ikke fakturert ennå')}</i>
                                            )}
                                        </TableCell>
                                        <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                                        <TableCell>
                                            {invoice.invoiceRecipient ? invoice.invoiceRecipient.name : '-'}
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            {formatCurrencyWithoutCode(invoice.amount)}
                                        </TableCell>
                                        <TableCell align={'right'}>{formatNumber(invoice.balance)}</TableCell>
                                        <TableCell>{invoice.kid}</TableCell>

                                        {!isLimitedUser && (
                                            <>
                                                <TableCell>{invoice.reminderSent ? t('TEXT_YES', 'Ja') : ''}</TableCell>
                                                <TableCell>
                                                    {invoice.collectionStopped ? t('TEXT_YES', 'Ja') : ''}
                                                </TableCell>
                                                <TableCell>{formatDate(invoice.sentToCollectionDate)}</TableCell>
                                            </>
                                        )}

                                        <TableCell>{invoice.distributionRecipeDescription ?? ''}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={invoices.length}
                rowsPerPage={rows}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
            />
        </div>
    )
}
