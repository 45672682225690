import { TFunction } from 'i18next'
import { HistoryType } from './models/history-entry'

export const historyStrings = (t: TFunction, fieldName: string) => {
    switch (fieldName) {
        case 'Name':
            return t('TEXT_NAME', 'Navn')
        case 'TenantName':
            return t('TEXT_PRODUCT_TENANT', 'Tenant')
        case 'VendorName':
            return t('TEXT_PRODUCT_VENDOR', 'Produktleverandør')
        case 'Price':
            return t('TEXT_FINAL_PRICE', 'Pris til kunden')
        case 'CubitPrice':
            return t('TEXT_CUBIT_CUT', 'Cubit påslag')
        case 'CubitPriceType':
            return t('TEXT_PERCENT_NOK', '% / NOK')
        case 'percentage':
            return '%'
        case 'flat':
            return 'NOK'
        default:
            return fieldName
    }
}

export const getTypePrefix = (t: TFunction, type: HistoryType): string => {
    switch (type) {
        case HistoryType.Created:
            return t('TEXT_CREATED_NEW_ENTRY', 'Created new entry')
        case HistoryType.Deleted:
            return t('TEXT_DELETED_ENTRY', 'Deleted entry')
        default:
            return ''
    }
}
