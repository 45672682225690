import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { StyledIneTable } from './styles'
import { formatDate } from 'utils/date-utils'
import { MultiChannelDispatch } from 'types/multi-channels/multi-channel-dispatch'

const getReminderInvoiceTranslationKey = (invoiceNumber: string) => {
    if (invoiceNumber.startsWith('PU1')) return 'TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_FIRST_REMINDER'
    if (invoiceNumber.startsWith('PU2')) return 'TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_SECOND_REMINDER'

    return 'TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_THIRD_REMINDER'
}

type Props = {
    dispatches?: MultiChannelDispatch[]
    isLoading: boolean
    isSuccess: boolean
}

export const IneProductChannelDispatchesTable: React.FC<Props> = ({ dispatches, isLoading, isSuccess }) => {
    const { t } = useTranslation()

    return (
        <>
            <Typography sx={{ mb: 1 }} variant="body1">
                {t('TEXT_OSLO_DISPATCHES_TABLE_TITLE', 'Envoys')}
            </Typography>
            <TableContainer>
                <StyledIneTable aria-label="channel-dispatches-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('TEXT_OSLO_DISPATCHES_ENVOY_TYPE', 'Type utsending')}</TableCell>
                            <TableCell>{t('TEXT_OSLO_DISPATCHES_DATE', 'Utsendelsesdato')}</TableCell>
                            <TableCell>{t('TEXT_OSLO_DISPATCHES_DISPATCH_CHANNEL', 'Utsendelseskanal')}</TableCell>
                            <TableCell>{t('TEXT_OSLO_DISPATCHES_RECEIVER', 'Mottaker')}</TableCell>
                            <TableCell>PDF</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow sx={{ height: 200 }}>
                                <TableCell colSpan={5} sx={{ textAlign: 'center', borderBottom: 'none' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {isSuccess && !dispatches?.length && (
                            <TableRow>
                                <TableCell colSpan={5} sx={{ textAlign: 'center', borderBottom: 'none' }}>
                                    <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                                        {t('TEXT_OSLO_DISPATCHES_NO_DISPATCHES')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {isSuccess &&
                            dispatches?.length !== 0 &&
                            dispatches?.map((channel) => (
                                <TableRow key={channel.invoiceNumber}>
                                    <TableCell>
                                        {channel.invoiceType === '371'
                                            ? t(
                                                  getReminderInvoiceTranslationKey(channel.invoiceNumber),
                                                  channel.invoiceType,
                                              )
                                            : 
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_THIRD_REMINDER')
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_FIRST_REMINDER')
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_371_SECOND_REMINDER')
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_380')
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_381')
                                              // t('TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_1400')
                                              t(
                                                  `TEXT_OSLO_DISPATCHES_CHANNEL_TYPE_${channel.invoiceType}`,
                                                  channel.invoiceType,
                                              )}
                                    </TableCell>
                                    <TableCell>{formatDate(channel.date)}</TableCell>
                                    <TableCell>{channel.channel}</TableCell>
                                    <TableCell>{channel.customerName}</TableCell>
                                    <TableCell>
                                        <a href={channel.url} download target="_blank" rel="noreferrer">
                                            {t('TEXT_DOWNLOAD', 'Last ned')}
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </StyledIneTable>
            </TableContainer>
        </>
    )
}
