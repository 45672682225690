import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { KotiContainer } from '../general/layout/koti-container'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { useGetCartQuery } from './cart.service'
import { FullWidthCartItem } from './full-width-cart-item'
import { Box, Divider, Paper, styled, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    getAddressFromCartItems,
    getPostalInfo,
    getTotalForItems,
    groupCartItems,
    projectNumbersValid,
} from './cart-helper'
import { ArrowRightAlt, KeyboardArrowDown } from '@mui/icons-material'
import React, { useState } from 'react'
import { MultiStringViewer } from '../general/multi-viewer/multi-string-viewer'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CHECKOUT, ROUTE_SEARCH } from '../../utils/routes'
import { cartActions, selectProjectNumbers } from './cart.slice'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from '../../utils/number-utils'
import StyledContainedButton from '../styled/styled-contained-button'
import StyledTypography from '../styled/styled-typography'

const EmptyCart: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <EmptyCartTextContainer>
                {t('TEXT_EMPTY_CART', 'Ingenting lagt i handlekurven ennå.')}
            </EmptyCartTextContainer>
            <Box display="flex" alignItems="center" justifyContent="center">
                <StyledContainedButton
                    onClick={() => navigate(ROUTE_SEARCH)}
                    variant={'contained'}
                    endIcon={<ArrowRightAlt />}
                >
                    {t('GO_TO_SEARCH', 'Gå til søk')}
                </StyledContainedButton>
            </Box>
        </>
    )
}

export const CartView: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cart = useGetCartQuery()
    const projectNumbers = useSelector(selectProjectNumbers)

    const [opened, setOpened] = useState<string[]>([])

    const handleOpened = (cadastreId: string) => {
        if (opened.includes(cadastreId)) {
            setOpened(opened.filter((o) => o !== cadastreId))
        } else {
            setOpened([...opened, cadastreId])
        }
    }

    const onProjectNumberChange = (key: string, value: string) => {
        dispatch(cartActions.setProjectNumbers({ ...projectNumbers, [key]: value }))
    }

    if (!cart.data) {
        return null
    }

    const isCartEmpty = cart.data.items.length === 0
    const grouped = groupCartItems(cart.data.items)

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Paper elevation={0}>
                    <PaperSubContainer>
                        <StyledTypography
                            variant="h6"
                            sx={(theme) => ({
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '1.375rem',
                                fontWeight: 600,
                                marginBottom: theme.spacing(4.5),
                            })}
                        >
                            {t('TEXT_CART', 'Handlekurv')}
                        </StyledTypography>
                        <Divider />
                        <div style={{ padding: '0 80px', marginTop: 40 }}>
                            {isCartEmpty && <EmptyCart />}
                            {Object.keys(grouped).map((key) => {
                                const isOpened = !opened.includes(key)
                                return (
                                    <div key={key}>
                                        <PropertyTitle onClick={() => handleOpened(key)}>
                                            <Box
                                                sx={{
                                                    mr: 2.5,
                                                    height: '24px',
                                                    transform: `rotate(${isOpened ? 180 : 0}deg)`,
                                                }}
                                            >
                                                <KeyboardArrowDown />
                                            </Box>

                                            <MatrikkelContainer>
                                                <Label>{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</Label>
                                                <ValueText>{key}</ValueText>
                                            </MatrikkelContainer>

                                            <Box mr={5}>
                                                <Label>{t('TEXT_ADDRESS', 'Adresse')}</Label>
                                                <ValueText>
                                                    {getAddressFromCartItems(grouped[key])?.[0] ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <MultiStringViewer
                                                                items={getAddressFromCartItems(grouped[key])}
                                                                owners={false}
                                                            />
                                                            <div style={{ marginLeft: 10 }}>
                                                                {getPostalInfo(grouped[key])}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>-</div>
                                                    )}
                                                </ValueText>
                                            </Box>

                                            <Box sx={{ fontSize: '22px', mr: 'auto' }}>
                                                {formatNumber(getTotalForItems(grouped[key]))}
                                            </Box>
                                        </PropertyTitle>
                                        {isOpened && (
                                            <div>
                                                <Box sx={{ margin: '22px 0 16px' }}>
                                                    <InnerItemContainer>
                                                        <TextField
                                                            defaultValue={projectNumbers[key]}
                                                            onChange={(event) =>
                                                                onProjectNumberChange(key, event.target.value)
                                                            }
                                                            color={'info'}
                                                            required={true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size={'small'}
                                                            label={t('TEXT_PROJECT_NUMBER', 'Oppdragsnummer')}
                                                        />
                                                    </InnerItemContainer>
                                                </Box>

                                                <Divider sx={{ mb: 2 }} />

                                                <InnerItemContainer>
                                                    {grouped[key].map((cartItem, index) => {
                                                        return <FullWidthCartItem key={index} cartItem={cartItem} />
                                                    })}
                                                </InnerItemContainer>

                                                <Divider sx={{ my: 2 }} />
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                            {!isCartEmpty && (
                                <>
                                    <InnerItemContainer>
                                        <CartTotalContainer>
                                            <div>{t('TEXT_TOTAL_SUM', 'Totalsum')}</div>
                                            <div>{formatNumber(getTotalForItems(cart.data.items))}</div>
                                        </CartTotalContainer>
                                    </InnerItemContainer>

                                    <InnerItemContainer>
                                        <CheckoutContainer>
                                            <StyledContainedButton
                                                disabled={!projectNumbersValid(cart.data, projectNumbers)}
                                                variant={'contained'}
                                                color={'secondary'}
                                                sx={{
                                                    padding: '14px 24px',
                                                    fontSize: '20px',
                                                }}
                                                onClick={() => navigate(ROUTE_CHECKOUT)}
                                            >
                                                {t('GOTO_CHECKOUT', 'Gå videre til utsjekk')}
                                            </StyledContainedButton>
                                        </CheckoutContainer>
                                    </InnerItemContainer>
                                </>
                            )}
                        </div>
                    </PaperSubContainer>
                </Paper>
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}

const PaperSubContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.5),
}))

const CheckoutContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
}))

const PropertyTitle = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    background: '#bedef1',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
}))

const MatrikkelContainer = styled('div')(({ theme }) => ({
    width: '200px',
    marginRight: theme.spacing(5),
}))

const Label = styled('div')({
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.55)',
})

const ValueText = styled('div')({
    fontSize: '1.25rem',
    color: '#000',
})

const CartTotalContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(5, 0, 10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '22px',
}))

const InnerItemContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 4),
}))

const EmptyCartTextContainer = styled('div')({
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
})
