/** @jsxImportSource @emotion/react */
import { Divider } from '@mui/material'
import { Nullable } from '../../../utils/types/nullable.type'
import { infoBlockStyles } from './info-block-styles'
import StyledTypography from '../../styled/styled-typography'

type Props = {
    title: Nullable<string>
    children: any
}
export const InfoBlock: React.FC<Props> = ({ title, children }) => {
    return (
        <>
            <div css={infoBlockStyles.block}>
                <StyledTypography variant="h6" css={infoBlockStyles.blockTitle}>
                    {title}
                </StyledTypography>
                <div css={infoBlockStyles.children}>{children}</div>
            </div>
            <Divider />
        </>
    )
}
