import { Box, Chip, Paper } from '@mui/material'
import { PropertyTableTitle } from './PropertyTableTitle'
import { PropertySearchBar } from './PropertySearchBar'

type PropertyTableFiltersProps = {
    title: string
    onSearch: (query: string) => void
    queryAddress: string | null
    onDeleteQueryAddress: () => void
}

export const PropertyTableFilters: React.FC<PropertyTableFiltersProps> = ({
    title,
    onSearch,
    onDeleteQueryAddress,
    queryAddress,
}) => {
    return (
        <Paper elevation={0} square>
            <PropertyTableTitle title={title} />
            <PropertySearchBar onSearch={onSearch} />
            {queryAddress && (
                <Box pl={1.25} pb={1.25}>
                    <Chip label={queryAddress} onDelete={onDeleteQueryAddress} color="primary" />
                </Box>
            )}
        </Paper>
    )
}
