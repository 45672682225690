import { Grid } from '@mui/material'
import { AccessLogsTable } from '../access-logs/access-logs-table'
import { AccessLogFilters, AccessLogsFilters } from '../access-logs/access-logs-filters'
import { useState } from 'react'
import dayjs from 'dayjs'

export const AccessLogsView: React.FC = () => {
    const [filters, setFilters] = useState<AccessLogFilters>({})
    const onSubmit = (values: any) => {
        setFilters({
            from: values.from ? dayjs(values.from).toISOString() : undefined,
            to: values.to ? dayjs(values.to).add(1, 'day').toISOString() : undefined,
            q: values.q,
        })
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccessLogsFilters onSubmit={onSubmit} />
                </Grid>
                <Grid item xs={12}>
                    <AccessLogsTable filters={filters} />
                </Grid>
            </Grid>
        </div>
    )
}
