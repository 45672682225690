import React, { useEffect } from 'react'
import * as signalR from '@microsoft/signalr'
import { useAppSelector } from '../../redux/hooks'
import { selectId } from '../auth/auth.slice'
import { Config } from '../../redux/config'

const HUB_ENDPOINT = '/notifications'
const METHOD_JOIN_GROUP = 'JoinGroup'
const PDF_EVENT = 'pdfGenerated'
export const SignalR: React.FC<{ handleNotification: any }> = ({ handleNotification }) => {
    const userId = useAppSelector(selectId)
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Config.backendUrl + HUB_ENDPOINT, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build()

        connection
            .start()
            .then(() => {
                connection.send(METHOD_JOIN_GROUP, userId)
            })
            .catch((err) => console.error('Error connecting to SignalR Hub:', err))

        connection.on(PDF_EVENT, (notification) => {
            handleNotification(notification)
        })
        return () => {
            connection.stop().then(() => console.log('Disconnected from SignalR Hub'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}
