/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Paper } from '@mui/material'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SortDirection } from '../general/models/sort-direction'
import { SearchField } from '../general/search-field/search-field'
import { KotiTable } from '../general/table/koti-table'
import { KotiTableHeader } from '../general/table/koti-table-header'
import { TableStructure } from '../general/table/table-structure.type'
import { usePrevious } from '../general/use-previous'
import { EditProduct } from './edit-product'
import { Product } from './product'
import { ProductFilterType, productsActions, selectProductFilters, selectSelectedProducts } from './product.slice'
import { ProductsFilters } from './products-filters'
import { useLazyGetProductsQuery } from './products.service'

const getProductTableStructure = (t: TFunction): TableStructure<Product>[] => [
    {
        label: t('TEXT_PRODUCT', 'Produkt'),
        columnName: 'name',
        sortFunction: (product) => product.name,
        value: (product) => product.name,
    },
    {
        label: t('TEXT_PRODUCT_VENDOR', 'Produktleverandør'),
        columnName: 'vendor',
        sortFunction: (product) => product.vendorName,
        value: (product) => product.vendorName,
    },
    {
        label: t('TEXT_TENANT', 'Tenant'),
        columnName: 'tenant',
        sortFunction: (product) => product.tenantName,
        value: (product) => product.tenantName,
    },
    {
        label: t('TEXT_PRODUCT_TYPE', 'Produkttype'),
        columnName: 'type',
        sortFunction: (product) => product.type,
        // translations in settings.selector
        value: (product) => t(product.type),
    },
    {
        label: t('TEXT_FINAL_PRICE', 'Pris til kunden'),
        columnName: 'price',
        sortFunction: (product) => product.price,
        value: (product) => product.price,
    },
    {
        label: t('TEXT_VAT', 'MVA'),
        columnName: 'vat',
        sortFunction: (product) => product.vat,
        value: (product) => product.vat,
    },
    {
        label: t('TEXT_CUBIT_CUT', 'Cubit påslag'),
        columnName: 'cubitPrice',
        sortFunction: (product) => product.cubitPrice,
        value: (product) => product.cubitPrice,
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (product) => product.name,
        value: (product) => <EditProduct product={product} />,
    },
]

type Props = {
    onSubmit: () => void
}

const TableTop: React.FC<Props> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const styles = {
        paper: css`
            padding: 16px;
        `,
        productFilters: css`
            margin: 16px 0 16px 0;
        `,
        searchRow: css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media ${getMobileMediaQuery()} {
                flex-direction: column;
                align-items: flex-start;
            }
        `,
        searchField: css`
            width: 60%;
            @media ${getMobileMediaQuery()} {
                width: 100%;
                margin-bottom: 16px;
            }
        `,
    }
    return (
        <div css={styles.paper}>
            <KotiTableHeader text={t('TEXT_PRODUCT_LIST', 'Produktliste')} />
            <div css={styles.searchRow}>
                <div css={styles.searchField}>
                    <SearchField
                        onSubmit={onSubmit}
                        label={t('TEXT_SEARCH_PRODUCTS', 'Søk...')}
                        onTextChanged={(value) =>
                            dispatch(productsActions.setProductFilter({ filter: ProductFilterType.q, value }))
                        }
                    />
                </div>
            </div>
            <div css={styles.productFilters}>
                <ProductsFilters onSubmit={onSubmit} />
            </div>
        </div>
    )
}
export const ProductsAdminTable: React.FC = () => {
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('id')
    const [direction, setDirection] = useState<SortDirection>('desc')
    const selectedProducts = useAppSelector(selectSelectedProducts)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const productFilters = useAppSelector(selectProductFilters)
    const prevFilters = usePrevious(productFilters)
    const handleOnSelectChange = (selectedItems: Product[]) => {
        dispatch(productsActions.setSelectedProducts(selectedItems))
    }
    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    const [trigger, { data }] = useLazyGetProductsQuery()
    const onSubmit = () =>
        trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...productFilters })
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (prevFilters === productFilters) {
            trigger({ page: pageNumber, itemsPerPage: itemsPerPage, sortBy, direction, ...productFilters })
        }
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, productFilters, prevFilters])

    const tableStructure = getProductTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            <TableTop onSubmit={onSubmit} />
            <KotiTable
                selectedItems={selectedProducts}
                tableStructure={tableStructure}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                rows={data?.data}
                pagination={true}
                onSelectChange={handleOnSelectChange}
                total={data?.total ?? 0}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
        </Paper>
    )
}
