import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { KotiContainer } from '../general/layout/koti-container'
import { Grid } from '@mui/material'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { TopPageTabs } from '../general/tabs/top-page-tabs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RealtorReportView } from './tabs/realtor-report.view'
import { AccessLogsView } from './tabs/access-logs.view'
import { IneInvoiceView } from './tabs/ine-invoice.view'
import { IneCompaniesView } from './tabs/ine-companies.view'
import { RealtorUsersView } from './tabs/realtors-users.view'

export enum AdminTabs {
    Invoice = 'invoice',
    Companies = 'companies',
    RealtorReport = 'realtorReport',
    AccessLogs = 'accessLogs',
    RealtorsList = 'realtorsList',
}
export const IneAdminPage: React.FC = () => {
    const [tab, setTab] = useState(AdminTabs.RealtorReport)
    const { t } = useTranslation()
    const tabs = [
        // { value: AdminTabs.Invoice, label: t('TEXT_INVOICE', 'Fakturagrunnlag') },
        // { value: AdminTabs.Companies, label: t('TEXT_REALTOR_COMPANY', 'Meglerkontor') },
        { value: AdminTabs.RealtorReport, label: t('TEXT_REALTOR_REPORT', 'Megleroppslag') },
        { value: AdminTabs.AccessLogs, label: t('TEXT_ACCESS_LOGS', 'Innsynsløsning') },
        { value: AdminTabs.RealtorsList, label: t('TEXT_REALTORS', 'Eiendomsmeglere') },
    ]
    return (
        <>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TopPageTabs tabs={tabs} tab={tab} setTab={setTab} title={t('TEXT_ADMIN', 'Admin')} />
                    </Grid>
                    <Grid item xs={12}>
                        {tab === AdminTabs.Invoice && <IneInvoiceView />}
                        {tab === AdminTabs.Companies && <IneCompaniesView />}
                        {tab === AdminTabs.RealtorReport && <RealtorReportView />}
                        {tab === AdminTabs.AccessLogs && <AccessLogsView />}
                        {tab === AdminTabs.RealtorsList && <RealtorUsersView />}
                    </Grid>
                </Grid>
            </KotiContainer>
            <BottomPageNavigation />
        </>
    )
}
