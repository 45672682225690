import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { Order } from './models/order'

export enum OrderFilterType {
    q = 'q',
    invoiced = 'invoiced',
}
export type OrderFilters = {
    q?: string
    invoiced?: boolean
    dateFrom?: string
    dateTo?: string
    company?: string
}
type OrdersState = {
    selectedItems: Order[]
    filters: OrderFilters
}

const slice = createSlice({
    name: 'orders',
    initialState: { selectedItems: [], filters: {} } as OrdersState,
    reducers: {
        setSelectedItems: (state, { payload }: PayloadAction<Order[]>) => {
            state.selectedItems = payload
        },
        setFilters: (state, { payload }: PayloadAction<{ filter: OrderFilterType; value: any }>) => {
            state.filters[payload.filter] = payload.value
        },
    },
})

export const selectSelectedOrders = (state: RootState) => state.orders.selectedItems
export const selectOrdersFilters = (state: RootState) => state.orders.filters

export default slice.reducer

export const ordersActions = slice.actions
