import { Path, FieldValues, Controller, Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, TextFieldProps, FormControl, Box } from '@mui/material'

export type FormInputProps<T extends FieldValues> = {
    name: Path<T>
    control: Control<T>
    inputPrefix?: React.ReactNode
    inputSuffix?: React.ReactNode
} & Omit<TextFieldProps, 'name' | 'error'>

export const ControlledMuiTextField = <T extends FieldValues>({
    name,
    control,
    fullWidth,
    inputPrefix,
    inputSuffix,
    ...rest
}: FormInputProps<T>) => {
    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            {inputPrefix}
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, value, ...restField }, fieldState: { error } }) => (
                    <FormControl fullWidth={fullWidth} error={!!error?.message}>
                        <TextField
                            {...rest}
                            {...restField}
                            error={!!error?.message}
                            helperText={error?.message ? t(error.message) : undefined}
                            inputProps={{ ref }}
                            value={value ?? ''}
                        />
                    </FormControl>
                )}
            />
            {inputSuffix}
        </Box>
    )
}
