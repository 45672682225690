/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Agreement } from '../models/ine-data.type'
import React from 'react'
import {
    formatCurrencyWithoutCode,
    getAllPreviousYearsItemsGroups,
    getPreviousYears,
    getYearTotal,
    getYearTotalByGroup,
} from './ine-product-helper'
import { ineProductStyles } from './ine-product-styles'
import { SimulatedValue } from './simulated-value'
import { IneExplanations } from './ine-explanations'
import { StyledIneTable } from './styles'

type Props = {
    agreement: Agreement
}

export const IneProductYearlyDevelopmentTable: React.FC<Props> = ({ agreement }) => {
    const { t } = useTranslation()
    const invoices = agreement.invoices
    return (
        <>
            <div css={ineProductStyles.priceDevelopment}>
                <div>{`${t('TEXT_PRICE_CHANGE_OVER_PREVIOUS_YEARS', 'Utvikling over tidligere år')}`}</div>
            </div>
            <TableContainer>
                <StyledIneTable aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {getPreviousYears(invoices, 6).map((year: number) => (
                                <TableCell key={year} css={ineProductStyles.tableCellAlignRight}>
                                    {year}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getAllPreviousYearsItemsGroups(invoices, 6).map((group: string) => (
                            <>
                                <TableRow key={group}>
                                    <TableCell>{group}</TableCell>

                                    {getPreviousYears(invoices, 6).map((year: number) => (
                                        <TableCell key={year} css={ineProductStyles.tableCellAlignRight}>
                                            {formatCurrencyWithoutCode(getYearTotalByGroup(invoices, group, year))}
                                            <SimulatedValue invoices={invoices} year={year} group={group} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </>
                        ))}
                        <TableRow>
                            <TableCell css={ineProductStyles.totalRow}>
                                {t('TEXT_TOTAL_FEE', 'Totale avgifter')}
                            </TableCell>
                            {getPreviousYears(invoices, 6).map((year: number) => (
                                <TableCell
                                    key={year}
                                    css={[ineProductStyles.tableCellAlignRight, ineProductStyles.totalRow]}
                                >
                                    {formatCurrencyWithoutCode(getYearTotal(invoices, year))}
                                    <SimulatedValue invoices={invoices} year={year} />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </StyledIneTable>
            </TableContainer>
            <IneExplanations />
        </>
    )
}
