import { Role } from '../components/auth/role'
import { Company } from '../components/companies/models/company'
import { AddressSearchResponse } from '../components/search/models/address-search-response'
import { Order } from '../components/orders/models/order'
import { Invoice } from '../components/invoicing/types/invoice.type'

export const ROUTE_PROFILE = '/profile'
export const ROUTE_PRODUCTS = '/products'
export const ROUTE_ADMIN = '/admin'
export const ROUTE_COMPANY_ADMIN = '/company-admin'
export const ROUTE_INE_ADMIN = '/ine-admin'
export const ROUTE_SEARCH = '/search'
export const ROUTE_SIGN_UP = '/sign-up'
export const ROUTE_FINISH_SIGN_UP = '/finish-sign-up'
export const ROUTE_ORDERS = '/orders'
export const ROUTE_ORDER = '/orders/:id'
export const ROUTE_CART = '/cart'
export const ROUTE_TERMS = '/terms'
export const ROUTE_COMPANY = '/company/:id'
export const ROUTE_INVOICE = '/invoice/:companyId/:id'
export const ROUTE_BILLING_DASHBOARD = '/billing/:companyId'
export const ROUTE_CADASTRE = '/cadastre/:id'
export const ROUTE_PROPERTY = '/property'
export const ROUTE_HISTORIC_PROPERTY = '/historic-property'
export const ROUTE_CHECKOUT = '/checkout'

export const getAuthRedirect = (roles: Role, redirect?: string | null): string => {
    if (redirect != null) {
        return redirect
    }

    if (roles === Role.CubitAdmin) {
        return ROUTE_ADMIN
    }
    if (roles === Role.RealtorAdmin) {
        return ROUTE_COMPANY_ADMIN
    }

    return ROUTE_SEARCH
}

export const getCompanyRoute = (item: Company): string => {
    return `/company/${item.id}`
}
export const getInvoiceRoute = (companyId: string, item: Invoice): string => {
    return `/invoice/${companyId}/${item.id}`
}

export const getBillingRoute = (companyId: string): string => {
    return `/billing/${companyId}`
}
export const getOrderRoute = (item: Order): string => {
    return `/orders/${item.id}`
}

export const getCadastreRoute = (item: AddressSearchResponse): string => {
    return `/cadastre/${item.cadastreId}`
}

export const getPropertyRoute = (item: AddressSearchResponse): string => {
    return `/property?cadastreNumber=${item.cadastreNumber}&address=${item.addressText}`
}
export const getHistoricPropertyRoute = (item: AddressSearchResponse): string => {
    return `/historic-property?cadastreNumber=${item.cadastreNumber}`
}
