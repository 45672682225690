import { Button } from '@mui/material'

type Props = {
    text: string
    onClick?: () => void
    startIcon?: React.ReactNode
}
export const KotiButtonSecondary: React.FC<Props> = ({ text, onClick, startIcon }) => {
    return (
        <Button
            sx={{
                color: '#ffffff',
                borderColor: '#ffffff',
                '.MuiSvgIcon-root': {
                    fill: '#ffffff',
                },
                '&:hover': {
                    backgroundColor: '#ffffff',
                    color: '#000',
                },
            }}
            variant={'outlined'}
            startIcon={startIcon}
            onClick={onClick}
        >
            {text.toUpperCase()}
        </Button>
    )
}
