import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Nullable } from '../../../utils/types/nullable.type'

type Props = {
    children: any
    title: Nullable<string>
    content?: any
    onConfirm: () => void
    confirmText?: string
}

export const ConfirmDialog: React.FC<Props> = ({ children, title, content, onConfirm, confirmText }) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    return (
        <>
            {React.cloneElement(children, { onClick: () => setOpen(true) })}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'sm'}>
                {title && <DialogTitle>{title}</DialogTitle>}
                {content && <DialogContent>{content}</DialogContent>}
                {onConfirm && (
                    <DialogActions>
                        <Button color="primary" onClick={() => setOpen(false)}>
                            {t('TEXT_CANCEL', 'AVBRYT')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                onConfirm()
                                setOpen(false)
                            }}
                        >
                            {confirmText ? confirmText : t('TEXT_YES', 'JA')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    )
}
