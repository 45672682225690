import { KotiTable } from '../../general/table/koti-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { TableStructure } from '../../general/table/table-structure.type'
import { Paper } from '@mui/material'
import { OutgoingInvoiceLine } from '../types/invoice.type'

const getInvoiceLinesTableStructure = (t: TFunction): TableStructure<OutgoingInvoiceLine>[] => [
    {
        label: t('TEXT_DESCRIPTION', 'Beskrivelse'),
        columnName: 'invoiceNumber',
        sortFunction: (object) => object.description,
        value: (object) => object.description,
    },
    {
        label: t('price', 'Pris'),
        columnName: 'unitPrice',
        sortFunction: (object) => object.unitPrice,
        value: (object) => object.unitPrice,
    },
    {
        label: t('TEXT_VAT', 'MVA'),
        columnName: 'vatRate',
        sortFunction: (object) => object.vatRate,
        value: (object) => `${(object.vatRate || 0) * 100}%`,
    },
    {
        label: t('total_price', 'total pris'),
        columnName: 'totalAmount',
        sortFunction: (object) => object.totalAmount,
        value: (object) => object.totalAmount,
    },
]
export const LinesTable: React.FC<{ lines: OutgoingInvoiceLine[] }> = ({ lines }) => {
    const { t } = useTranslation()
    const itemsPerPage = 9990
    const pageNumber = 0

    return (
        <Paper elevation={0} style={{ marginTop: 16 }}>
            <KotiTable
                sortable={false}
                pagination={false}
                selectable={false}
                tableStructure={getInvoiceLinesTableStructure(t)}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                rows={lines}
                total={lines.length}
            />
        </Paper>
    )
}
