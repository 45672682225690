import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { User } from './user'

type UsersState = {
    selectedUsers: User[]
}

const slice = createSlice({
    name: 'users',
    initialState: { selectedUsers: [] } as UsersState,
    reducers: {
        setSelectedUsers: (state, { payload }: PayloadAction<User[]>) => {
            state.selectedUsers = payload
        },
    },
})

export const selectSelectedUsers = (state: RootState) => state.users.selectedUsers

export default slice.reducer

export const usersActions = slice.actions
