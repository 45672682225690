type ValidatorFn = (value: any) => string | undefined

export const composeValidators = (...validators: ValidatorFn[]) => {
    return (value: any) => {
        return validators.reduce((error: string | undefined, validator: ValidatorFn) => {
            return error || validator(value)
        }, undefined)
    }
}

export const ValidatorRequired = (value: any, message: string) => {
    let valueExists = value !== undefined && value !== null

    if (valueExists && Array.isArray(value)) {
        valueExists = value.length !== 0
    }

    return valueExists ? undefined : message
}

export const ValidatorChecked = (value: any, message: string) => {
    const valid = value === true
    return valid ? undefined : message
}

const email_re =
    /^(([^<>()[].,;:\s@"]+(\.[^<>()[].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const ValidatorEmail = (value: any, message: string) => {
    const valid = email_re.test(String(value).toLowerCase())
    return valid ? undefined : message
}

const phone_re = /^(0047|\+47|47)?[2-9]\d{7}$/
export const ValidatorPhone = (value: any, message: string) => {
    const valid = phone_re.test(String(value).toLowerCase())
    return valid ? undefined : message
}

export const ValidatorMaxLength = (value: string | undefined, maxLength: number, message: string) => {
    return (value?.length || 0) > maxLength ? message : undefined
}

export const ValidatorMinValue = (value: number | string, min: number, message: string) => {
    return (Number(value) || 0) > min ? undefined : message
}

export const ValidatorMaxValue = (value: number | string, max: number, message: string) => {
    return (Number(value) || 0) < max ? undefined : message
}

export const ValidatorInBetweenValue = (value: number | string, min: number, max: number, message: string) => {
    const parsedValue = Number(value)
    if (min >= max || max <= min)
        throw new Error('invalid arguments passed, min cant be more than max, or max more than min')
    return parsedValue > min && parsedValue < max ? undefined : message
}
