import React from 'react'
import { ProductWithAttributes, InvoiceReceiver } from 'components/products/product'
import { TaxesProductViewProps } from './ProductDisplay'
import { useTranslation } from 'react-i18next'
import { ProductContainer, ExpandDetailsContainer } from './common/shared'
import { ProductMainInfo } from './common/ProductMainInfo'
import { Box, Collapse, FormControlLabel, Typography } from '@mui/material'
import { formatCadastreNumber, isMainProperty } from 'utils/cadastre-utils'
import { KotiCheckbox } from 'components/general/inputs/koti-checkbox'
import { ProductSecondaryInfo } from './common/ProductSecondaryInfo'

const checkAllProductAttributes = (product: ProductWithAttributes) => {
    const productAttributesToUpdate: Record<string, InvoiceReceiver[]> = {}

    product.attributes.forEach((attr) => {
        attr.invoiceReceivers.forEach((receiver) => {
            const cadastreNr = attr.matrikeklNr

            if (
                !productAttributesToUpdate[cadastreNr] ||
                (productAttributesToUpdate[cadastreNr] &&
                    !productAttributesToUpdate[cadastreNr].find((x) => x.agreementNumber === receiver.agreementNumber))
            ) {
                const newAttributes = [...(productAttributesToUpdate[cadastreNr] ?? []), receiver]
                productAttributesToUpdate[cadastreNr] = newAttributes
            }
        })
    })

    return productAttributesToUpdate
}

const isAttributeSelected = (attributes: InvoiceReceiver[] | undefined, agreementNumber: number) => {
    const found = attributes?.find((x) => x.agreementNumber === agreementNumber)
    return found == null ? false : true
}

export const OsloTaxesProductForRealtor: React.FC<Omit<TaxesProductViewProps, 'isOsloUser' | 'ineData'>> = ({
    onCartButtonClick,
    product,
    isInCart,
    isLoading,
}) => {
    const { t } = useTranslation()

    const [isExpanded, setIsExpanded] = React.useState(false)
    const [toggleAllChecked, setToggleAllChecked] = React.useState(true)
    const [productAttributes, setProductAttributes] = React.useState<Record<string, InvoiceReceiver[]>>(
        checkAllProductAttributes(product),
    )

    const toggleAttribute = (cadastreNr: string, invoiceReceiver: InvoiceReceiver, insert: boolean) => {
        setProductAttributes((prevState) => {
            if (insert) {
                //check to not add same values multiple times
                if (
                    !prevState[cadastreNr] ||
                    (prevState[cadastreNr] &&
                        !prevState[cadastreNr].find((x) => x.agreementNumber === invoiceReceiver.agreementNumber))
                ) {
                    const newAttributes = [...(prevState[cadastreNr] ?? []), invoiceReceiver]
                    return { ...prevState, [cadastreNr]: newAttributes }
                } else {
                    return { ...prevState }
                }
            } else {
                const newAttributes = prevState[cadastreNr].filter(
                    (x) => x.agreementNumber !== invoiceReceiver.agreementNumber,
                )
                return { ...prevState, [cadastreNr]: newAttributes }
            }
        })
    }

    const hasNonEmptyArray = (): boolean => {
        for (const key in productAttributes) {
            if (Array.isArray(productAttributes[key]) && productAttributes[key].length > 0) {
                return true
            }
        }
        return false
    }

    return (
        <ProductContainer className="product-container">
            <ProductMainInfo
                onCartButtonClick={() => onCartButtonClick(productAttributes)}
                product={product}
                isExpanded={isExpanded}
                onToggleExpand={() => setIsExpanded((prev) => !prev)}
                isInCart={isInCart}
                isCartButtonDisabled={isLoading || (!isInCart && !hasNonEmptyArray())}
            />

            <Collapse in={isExpanded}>
                <ExpandDetailsContainer>
                    <div>
                        <Box mb={2}>
                            <Typography variant="body1">
                                {t(
                                    'INE_PRODUCT_HELP_TEXT',
                                    'Velg om du vil ha kommunale avgifter fra både seksjon og grunneiendom.',
                                )}
                            </Typography>
                        </Box>

                        {product.attributes.map((attribute) => (
                            <Box key={attribute.matrikeklNr} display="flex" mb={2.5}>
                                <Box width="200px">
                                    {isMainProperty(attribute.matrikeklNr)
                                        ? t('MAIN_PROPERTY', 'Grunneiendom')
                                        : t('TEXT_SECTION', 'Seksjon')}

                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                        {formatCadastreNumber(attribute.matrikeklNr)}
                                    </Typography>
                                </Box>

                                <div>
                                    {attribute.invoiceReceivers.length > 9 && (
                                        <Box mb={1.25}>
                                            <FormControlLabel
                                                control={
                                                    <KotiCheckbox
                                                        checked={toggleAllChecked}
                                                        onChange={(e) => {
                                                            attribute.invoiceReceivers.forEach((receiver) => {
                                                                toggleAttribute(
                                                                    attribute.matrikeklNr,
                                                                    receiver,
                                                                    e.target.checked,
                                                                )
                                                            })
                                                            setToggleAllChecked(e.target.checked)
                                                        }}
                                                    />
                                                }
                                                label={t('TOGGLE_ALL', 'Marker alle')}
                                            />
                                        </Box>
                                    )}

                                    {attribute.invoiceReceivers.map((agreement) => (
                                        <div key={agreement.agreementNumber}>
                                            <FormControlLabel
                                                control={
                                                    <KotiCheckbox
                                                        checked={isAttributeSelected(
                                                            productAttributes[attribute.matrikeklNr],
                                                            agreement.agreementNumber,
                                                        )}
                                                        onChange={(e) =>
                                                            toggleAttribute(
                                                                attribute.matrikeklNr,
                                                                agreement,
                                                                e.target.checked,
                                                            )
                                                        }
                                                    />
                                                }
                                                label={`${agreement.name}  (${t('PART_NR', 'Delnr.')} ${
                                                    agreement.partNumber
                                                })`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Box>
                        ))}
                    </div>

                    <ProductSecondaryInfo product={product} />
                </ExpandDetailsContainer>
            </Collapse>
        </ProductContainer>
    )
}
