/** @jsxImportSource @emotion/react */
import { Grid, Paper } from '@mui/material'
import { css } from '@emotion/react'
import {
    formatCadastreNumber,
    getAllAddresses,
    getAllOwners,
    getMainProperty,
    getMainPropertyCadastreNumber,
    getStreetNames,
    isPropertySectioned2,
} from '../../../../utils/cadastre-utils'
import { PropertyResponseItem } from '../../../search/models/property-search-response'
import { useTranslation } from 'react-i18next'
import { convertFromZone32to33 } from '../../../general/map/map-helper'
import { useMemo } from 'react'
import { IframeMap } from '../../../general/map/i-frame-map'

type Props = {
    data: PropertyResponseItem[]
}
const styles = {
    leftColumn: css`
        height: 100%;
    `,
    title: css`
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
    `,
    titleLabel: css`
        color: #858585;
        font-style: italic;
        font-size: 14px;
    `,
    textBlock: css`
        padding: 24px;
    `,
    block: css`
        margin-bottom: 24px;
    `,
    blockLabel: css`
        font-size: 14px;
        color: #8f8f8f;
    `,
    blockValue: css`
        font-size: 18px;
    `,
    rowBlocks: css`
        display: flex;
    `,
    rowBlock: css`
        margin-right: 34px;
    `,
    ownerColumn: css`
        white-space: nowrap;
    `,
    streetSection: css`
        margin-bottom: 8px;
    `,
}

// const getTitle = (result: CadastreResponse) => {
//     return `${result.addressText}, ${result.postalCode} ${result.postalName}`
// }
export const PropertyTop: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()
    const property = getMainProperty(data)

    const isSectioned = isPropertySectioned2(data)
    const coords = convertFromZone32to33(property?.coordinateX ?? 0, property?.coordinateY ?? 0)
    const Map = useMemo(() => (coords.lat && coords.long ? <IframeMap coords={coords} /> : <></>), [coords])
    const cadastreNumber = getMainPropertyCadastreNumber(data[0].cadastreNumber)

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={5}>
                <Paper elevation={0} css={styles.leftColumn}>
                    <div css={styles.textBlock}>
                        <div css={styles.titleLabel}>{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</div>
                        <div css={styles.title}>{formatCadastreNumber(cadastreNumber)}</div>

                        <div css={styles.rowBlocks}>
                            <div css={styles.rowBlock}>
                                <div css={styles.titleLabel}>{t('PROPERTY_ADDRESSES', 'Tilknyttede adresser')}</div>
                                {getStreetNames(getAllAddresses(data)).map((a, index) => (
                                    <div key={index} css={styles.streetSection}>
                                        {a}
                                    </div>
                                ))}
                            </div>
                            {!isSectioned && (
                                <div css={styles.ownerColumn}>
                                    <div css={styles.titleLabel}>{t('TEXT_OWNER', 'Eier')}</div>
                                    {getAllOwners(data).map((a, index) => (
                                        <div key={index}>{a}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={7}>
                {Map}
            </Grid>
        </Grid>
    )
}
