/** @jsxImportSource @emotion/react */
import { CubitTextFieldAdapter } from '../../general/forms/cubit-text-field-adapter'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { CubitDatePickerAdapter } from '../../general/forms/cubit-date-picker-adapter'
import { InputAdornment, Paper } from '@mui/material'
import { css } from '@emotion/react'
import { Search } from '@mui/icons-material'
import StyledContainedButton from '../../styled/styled-contained-button'

type Props = {
    onSubmit: any
}
export type AccessLogFilters = {
    from?: string
    to?: string
    q?: string
}
const styles = {
    paper: css`
        padding: 0 16px;
    `,
    filters: css`
        display: flex;
        align-items: center;
        justify-content: start;
    `,
    datePicker: css`
        width: 140px;
        margin-right: 32px;
    `,
    label: css`
        font-size: 16px;
        font-weight: 600;
        margin-right: 32px;
    `,
    search: css`
        width: 200px;
        margin-right: 32px;
    `,
    field: css`
        max-width: 100%;
        width: 100%;
        display: block;
        margin-bottom: 16px;
    `,
}
export const AccessLogsFilters: React.FC<Props> = ({ onSubmit }) => {
    const { t } = useTranslation()
    return (
        <Paper elevation={0} css={styles.paper}>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form id={'ACCESS_LOG_FILTERS'} onSubmit={handleSubmit}>
                        <div css={styles.filters}>
                            <div css={styles.label}>{t('TEXT_FILTERS', '')}</div>
                            <div css={styles.datePicker}>
                                <Field
                                    name="from"
                                    margin={'dense'}
                                    component={CubitDatePickerAdapter}
                                    label={t('TEXT_FROM', 'Fra')}
                                    variant="standard"
                                />
                            </div>
                            <div css={styles.datePicker}>
                                <Field
                                    name="to"
                                    component={CubitDatePickerAdapter}
                                    label={t('TEXT_TO', 'Til')}
                                    variant="standard"
                                />
                            </div>
                            <div css={styles.search}>
                                <Field
                                    name="q"
                                    margin={'dense'}
                                    component={CubitTextFieldAdapter}
                                    label={t('TEXT_SEARCH', 'Søk')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </div>
                            <div>
                                <StyledContainedButton type={'submit'} variant={'contained'} color={'secondary'}>
                                    {t('TEXT_SEARCH', 'Søk')}
                                </StyledContainedButton>
                            </div>
                        </div>
                    </form>
                )}
            />
        </Paper>
    )
}
