import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type Props = {
    color?: string
    text: string
    onClick: () => void
    startIcon?: React.ReactNode
}
export const KotiButton1: React.FC<Props> = ({ color, text, onClick, startIcon }) => {
    const theme = useTheme()
    return (
        <Button
            sx={{
                color: color ? color : theme.palette.primary.contrastText,
                '.MuiSvgIcon-root': {
                    fill: color ? color : theme.palette.primary.contrastText,
                },
                fontFamily: 'Tomato Grotesk',
            }}
            startIcon={startIcon}
            onClick={onClick}
        >
            {text}
        </Button>
    )
}
