import { createTheme } from '@mui/material'
import { nbNO } from '@mui/material/locale'
import { enUS } from '@mui/material/locale'
import { enUS as dateUsLocale } from '@mui/x-date-pickers/locales'
import { nbNO as dateNbLocale } from '@mui/x-date-pickers/locales'
import { getLang } from '../../translation/translation-helper'
import FONT from './font-families'

export const appTheme = createTheme(
    {
        palette: {
            primary: {
                light: '#2f4567',
                main: '#273a57',
                dark: '#233249',
                contrastText: '#c5dce5',
            },
            secondary: {
                light: '#c5dce5',
                main: '#9dbfcd',
                dark: '#84a5b4',
                contrastText: '#233249',
            },
            accent: {
                light: '#b75777',
                dark: '#7b2d48',
                main: '#a33a5d',
                contrastText: '#ffffff',
            },
            neutral: {
                main: '#ffffff',
                contrastText: '#000000',
            },
        },
        typography: {
            fontFamily: FONT.OPEN_SANS,
            h1: {
                fontFamily: FONT.TOMATO,
            },
            h2: {
                fontFamily: FONT.TOMATO,
            },
            h3: {
                fontFamily: FONT.TOMATO,
            },
            h4: {
                fontFamily: FONT.TOMATO,
            },
            h5: {
                fontFamily: FONT.TOMATO,
            },
            h6: {
                fontFamily: FONT.TOMATO,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '.MuiSvgIcon-root': { fill: '#000' },
                    },
                },
            },
            // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
            // @ts-ignore
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        color: '#000',
                        '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            outline: 'none !important',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-cell': {
                            outline: 'none',
                        },
                        // '& .MuiDataGrid-withBorderColor': {
                        //     borderColor: 'red',
                        // },
                    },
                    cellCheckbox: {
                        '& .Mui-checked > svg': {
                            fill: '#a33a5d', //accent main
                        },
                        '& .Mui-disabled > svg': {
                            opacity: 0.5,
                        },
                    },
                    columnHeaderCheckbox: {
                        '& .Mui-checked > svg': {
                            fill: '#a33a5d', //accent main
                        },
                    },
                },
            },
        },
    },
    getLang() === 'en' ? enUS : nbNO,
    getLang() === 'en' ? dateUsLocale : dateNbLocale,
)
export const MOBILE_MEDIA_QUERY = '(max-width:900px)'
export const getMobileMediaQuery = () => {
    return MOBILE_MEDIA_QUERY
}
declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary']
        accent: Palette['primary']
    }
    interface PaletteOptions {
        neutral: PaletteOptions['primary']
        accent: Palette['primary']
    }
}
