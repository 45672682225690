import { appApi } from '../../redux/api'
import { AppSettings } from './settings.type'

export const settingsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getSettings: builder.query<AppSettings, void>({
            query: () => ({
                url: `/v1/settings/app`,
                method: 'GET',
            }),
            providesTags: ['Settings'],
            // 12 hours
            keepUnusedDataFor: 43200,
        }),
    }),
})

export const { useGetSettingsQuery } = settingsApi
