import { Invoice } from '../types/invoice.type'
import { formatDate, getDateObject } from '../../../utils/date-utils'
import React from 'react'

type Props = {
    invoice: Invoice
}
export const InvoicePeriod: React.FC<Props> = ({ invoice }) => {
    const invoiceDate = getDateObject(invoice.createdDate)
    const lastMonthStartDate = invoiceDate.startOf('month').subtract(1, 'month')
    const lastMonthEndDate = lastMonthStartDate.endOf('month')

    const period = `${formatDate(lastMonthStartDate)} - ${formatDate(lastMonthEndDate)}`

    return <div>{period}</div>
}

export const CurrentPeriod: React.FC = () => {
    const invoiceDate = getDateObject(new Date())
    const monthStartDate = invoiceDate.startOf('month')
    const monthEndDate = monthStartDate.endOf('month')

    const period = `${formatDate(monthStartDate)} - ${formatDate(monthEndDate)}`

    return <div>{period}</div>
}
