import { Agreement } from '../models/ine-data.type'
import Table from '@mui/material/Table'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { formatDate } from '../../../utils/date-utils'
import { useTranslation } from 'react-i18next'
import {
    aggregateInvoiceTransactions,
    filterInvoicesByYear,
    formatCurrencyWithoutCode,
    getTransactionList,
} from './ine-product-helper'
import TablePagination from '@mui/material/TablePagination'
import * as React from 'react'

type Props = {
    year: string
    agreement: Agreement
}
export const TransactionList: React.FC<Props> = ({ agreement, year }) => {
    const { t } = useTranslation()
    const rows = 20
    const [page, setPage] = React.useState(0)
    const invoices = filterInvoicesByYear(year, agreement)
    const aggregated = aggregateInvoiceTransactions(invoices)
    const transactions = getTransactionList(aggregated)
    if (transactions.length === 0)
        return (
            <div style={{ fontSize: 18, margin: '16px 0' }}>
                {t('NO_TRANSACTIONS', 'Ingen transaksjoner gjort for dette året ennå')}
            </div>
        )
    return (
        <div>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '50px' }}>{t('TEXT_YEAR', 'År')}</TableCell>
                        <TableCell style={{ width: '50px' }}>{t('TEXT_TERM', 'Termin')}</TableCell>
                        <TableCell style={{ width: '120px' }}>{t('TEXT_DATE', 'Dato')}</TableCell>
                        <TableCell align={'right'} style={{ width: '120px' }}>
                            {t('TEXT_INVOICE_NR', 'Fakturanummer')}
                        </TableCell>
                        <TableCell style={{ width: '200px' }}>{t('TEXT_TYPE', 'Type')}</TableCell>
                        <TableCell align={'right'} style={{ width: '200px' }}>
                            {t('TEXT_AMOUNT', 'Beløp')}
                        </TableCell>
                        <TableCell>{t('TEXT_KID_NR', 'KID-nummer')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.slice(page * rows, page * rows + rows).map((transaction, index) => {
                        return (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{transaction.year}</TableCell>
                                <TableCell>{transaction.term}</TableCell>
                                <TableCell>{formatDate(transaction.date)}</TableCell>
                                <TableCell align={'right'}>{transaction.number}</TableCell>
                                <TableCell>{transaction.typeName}</TableCell>
                                <TableCell align={'right'}>{formatCurrencyWithoutCode(transaction.amount)}</TableCell>
                                <TableCell>{transaction.kid}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={transactions.length}
                rowsPerPage={rows}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
            />
        </div>
    )
}
