import { appApi } from '../../redux/api'
import { Config } from '../../redux/config'
import { AuthContext } from './auth-context.type'
import { Role } from './role'

export type LoginResponse = {
    accessToken: string
    refreshToken: string
    userId: string
    roles: Role[]
    authContext: AuthContext
}

export type LoginRequest = {
    accessToken: string
    secret?: string
}

export type FakeLoginRequest = {
    pid: string
    role: Role
    password: string
}

export type CubitLoginRequest = {
    sessionKey: string
}

export type PasswordlessLoginRequest = {
    userId: string
    token: string
}

export type OsloLoginRequest = {
    tenant: string
    code: string
}

const authApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (params) => ({
                url: '/v1/auth/auth',
                method: 'GET',
                params: { token: params.accessToken, secret: params.secret },
            }),
        }),
        logout: builder.query<void, string>({
            query: (params) => ({
                url: '/v1/auth/logout',
                method: 'GET',
                params: { refreshToken: params },
            }),
        }),
        fakeLogin: builder.mutation<LoginResponse, FakeLoginRequest>({
            query: (params) => ({
                url: '/v1/auth/fake-auth',
                method: 'POST',
                body: params,
            }),
        }),
        cubitLogin: builder.mutation<LoginResponse, CubitLoginRequest>({
            query: (params) => ({
                url: '/v1/auth/cubit-auth',
                method: 'POST',
                body: params,
            }),
        }),
        passwordlessLogin: builder.mutation<LoginResponse, PasswordlessLoginRequest>({
            query: (params) => ({
                url: '/v1/auth/passwordless-auth',
                method: 'POST',
                body: params,
            }),
        }),
        osloLogin: builder.mutation<LoginResponse, OsloLoginRequest>({
            query: (params) => ({
                url: '/v1/auth/oslo',
                method: 'POST',
                body: params,
            }),
        }),
    }),
})

export const {
    useLoginMutation,
    useFakeLoginMutation,
    useCubitLoginMutation,
    usePasswordlessLoginMutation,
    useOsloLoginMutation,
    useLazyLogoutQuery,
} = authApi

export const navigateToLogin = (redirectUrl: string) => {
    window.location.replace(`${Config.backendUrl}/v1/auth/login?redirectUrl=${window.location.origin}${redirectUrl}`)
}
