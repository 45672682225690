/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Agreement } from '../models/ine-data.type'
import React, { useState } from 'react'
import { IneProductDetailedReport } from './ine-product-detailed-report'
import { IneProductTransactions } from './ine-product-transactions'
import { IneProductsTabs } from './ine-products-tabs'
import { IneProductYearlyDevelopmentTable } from './ine-product-yearly-development-table'
import { IneOverview } from './overview/ine-overview'

type Props = {
    agreement: Agreement
}
export enum IneProductTabs {
    YearlyDevelopment = 'YearlyDevelopment',
    Overview = 'Overview',
    DetailedReport = 'detailedReport',
    Transactions = 'transactions',
}

export const IneProduct: React.FC<Props> = ({ agreement }) => {
    const { t } = useTranslation()
    const [tab, setTab] = useState(IneProductTabs.Overview)
    const tabs = [
        { value: IneProductTabs.Overview, label: t('TEXT_OVERVIEW', 'Oversikt') },
        { value: IneProductTabs.DetailedReport, label: t('TEXT_DETAILED_REPORT', 'detaljert rapport') },
        { value: IneProductTabs.Transactions, label: t('TEXT_TRANSACTIONS', 'transaksjoner') },
        { value: IneProductTabs.YearlyDevelopment, label: t('TEXT_DEVELOPMENT', 'utvikling') },
    ]
    return (
        <>
            <IneProductsTabs tabs={tabs} tab={tab} setTab={setTab} title={t('TEXT_ADMIN', 'Admin')} />
            <Grid item xs={12}>
                {tab === IneProductTabs.Overview && <IneOverview agreement={agreement} />}
                {tab === IneProductTabs.DetailedReport && <IneProductDetailedReport agreement={agreement} />}
                {tab === IneProductTabs.Transactions && <IneProductTransactions agreement={agreement} />}
                {tab === IneProductTabs.YearlyDevelopment && <IneProductYearlyDevelopmentTable agreement={agreement} />}
            </Grid>
        </>
    )
}
