import { Add } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dispatchSubmit } from '../../utils/forms-utils'
import { useCreateCompanyUserMutation } from '../../redux/apis/companies-api'
import { QueryStatusView } from '../general/query-status.view'
import { UserForm } from './user-form'
import StyledContainedButton from '../styled/styled-contained-button'

type Props = {
    companyId: string
}

export const AddUser: React.FC<Props> = ({ companyId }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const FORM_ID = 'ADD_NEW_USER'
    const [create, { isLoading, error, isError }] = useCreateCompanyUserMutation()
    const onSubmit = (values: any) => {
        if (!isLoading) {
            create({ ...values, companyId })
                .unwrap()
                .then(() => setOpen(false))
        }
    }

    return (
        <>
            <StyledContainedButton
                startIcon={<Add />}
                color={'primary'}
                variant={'contained'}
                onClick={() => setOpen(true)}
            >
                {t('TEXT_ADD_USER', 'Legg til ny bruker')}
            </StyledContainedButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{t('TEXT_ADD_USER_TITLE', 'Legg til ny bruker')}</DialogTitle>
                <DialogContent>
                    <UserForm id={FORM_ID} initialValues={{ sendInvite: true }} onSubmit={onSubmit} />
                    <QueryStatusView error={error} isError={isError} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        disabled={isLoading}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(FORM_ID)}
                    >
                        {t('TEXT_SAVE', 'LAGRE')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
