import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'

type bottomNavigationState = {
    current: any
}

const slice = createSlice({
    name: 'bottomNavigation',
    initialState: {
        current: null,
    } as bottomNavigationState,
    reducers: {
        setCurrentPage: (state, { payload }: PayloadAction<bottomNavigationState>) => {
            state.current = payload
        },
    },
})

export default slice.reducer

export const bottomNavigationActions = slice.actions

export const selectCurrent = (state: RootState) => state.bottomNavigation.current
