import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

const FooterTextButton = styled(LoadingButton)(({ theme }) => ({
    color: `${theme.palette.primary.contrastText}`,
    textTransform: 'none',
    textDecoration: 'underline',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
        color: `${theme.palette.secondary.dark}`,
        textDecoration: 'underline',
    },
    padding: 0,
}))
export default FooterTextButton
