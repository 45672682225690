/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, FormGroup } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'
import { TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { isValidDate } from '../../../utils/date-utils'

const CubitTimePickerAdapter: React.FC<any> = (props: any) => {
    const {
        label,
        input: { value, onChange },
    } = props

    return (
        <FormControl css={cubitFormStyles.field}>
            <FormGroup row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        ampm={false}
                        slotProps={{ textField: { variant: 'standard', margin: 'dense' } }}
                        label={label}
                        value={isValidDate(value) ? value : null}
                        onChange={(newValue) => {
                            onChange(newValue)
                        }}
                    />
                </LocalizationProvider>
            </FormGroup>
        </FormControl>
    )
}

export { CubitTimePickerAdapter }
