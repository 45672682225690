import { Table, styled } from '@mui/material'

export const StyledIneTable = styled(Table)(({ theme }) => ({
    '.MuiTableCell-head ': {
        fontSize: '12px',
        fontStyle: 'italic',
        padding: theme.spacing(0.75, 0),
        borderColor: '#a9a9a9',
    },
    '.MuiTableCell-body ': {
        fontSize: '12px',
        padding: theme.spacing(0.75, 0),
        borderColor: '#a9a9a9',
    },
    '.MuiTableRow-root > .MuiTableCell-root': {
        padding: theme.spacing(0.75, 0, 0.75),
    },
    minWidth: 650,
}))
