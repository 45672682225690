import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CubitCompany } from '../../components/companies/models/cubit-company.type'

export const companiesApi = createApi({
    reducerPath: 'companiesApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://companies.cubit.no/api/' }),
    endpoints: (builder) => ({
        searchCompanies: builder.query<CubitCompany[], string>({
            query: (search) => `brreg/${search}`,
        }),
    }),
})

export const { useLazySearchCompaniesQuery, useSearchCompaniesQuery } = companiesApi
