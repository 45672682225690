import { useTranslation } from 'react-i18next'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
// import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Button, Grid, Link, Stack, useMediaQuery, useTheme } from '@mui/material'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import FooterTextButton from '../styled/footer-text-button'
import { changeLanguage } from '../../translation/translation-helper'
import StyledFooterLink from '../styled/styled-footer-link'
import StyledTypography from '../styled/styled-typography'
const HomeFooter: React.FC = () => {
    const { t } = useTranslation()
    const isMobile = useMediaQuery(getMobileMediaQuery())
    const theme = useTheme()
    const handleBackToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop
        if (c > 0) {
            window.requestAnimationFrame(handleBackToTop)
            window.scrollTo(0, c - c / 8)
        }
    }
    return (
        <Grid
            sx={{
                mt: 'auto',
                py: { xs: 1.5, sm: 2 },
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'flex',
                justifyContent: 'center',
            }}
            container
        >
            <Grid item xs />
            <Grid sx={{ p: isMobile ? theme.spacing(0, 3) : 0 }} item xs={12} sm={10}>
                {!isMobile && (
                    <>
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: `solid 2px ${theme.palette.common.white}`,
                                paddingBottom: theme.spacing(1),
                            }}
                            item
                            xs={12}
                        >
                            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                <StyledTypography color="common.white"> {t('run_by', 'Run by')}</StyledTypography>
                                <Box
                                    component="img"
                                    alignSelf="center"
                                    sx={{
                                        maxWidth: theme.spacing(10),
                                        maxHeight: theme.spacing(5),
                                        pl: theme.spacing(1),
                                        color: 'fff',
                                        width: { xs: 500, sm: 300, md: 500, lg: 700 },
                                    }}
                                    alt="Cubit logo with company name"
                                    src={'https://cubitblobstorage.blob.core.windows.net/public-images/Cubit_logo.svg'}
                                />
                            </Grid>
                            <Grid>
                                {/* <Link sx={{ pr: theme.spacing(1) }} href="#">
                                    <FacebookIcon sx={{ color: theme.palette.common.white }} />
                                </Link> */}
                                <Link href="https://www.linkedin.com/company/cubit-as/mycompany/">
                                    <LinkedInIcon sx={{ color: theme.palette.common.white }} />
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                )}
                {isMobile && (
                    <Button
                        startIcon={<ArrowUpwardIcon />}
                        sx={{ color: theme.palette.common.white, padding: theme.spacing(1, 0), textTransform: 'none' }}
                        variant="outlined"
                        onClick={handleBackToTop}
                    >
                        <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }}>
                            {t('back_to_top', 'Back to top')}
                        </StyledTypography>
                    </Button>
                )}
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                    }}
                    item
                >
                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        spacing={{ xs: 3, sm: 5, md: 10 }}
                        sx={{
                            paddingTop: theme.spacing(2),
                            width: '100%',
                            display: 'flex',
                            justifyContent: { sm: 'space-between', lg: 'flex-start' },
                        }}
                    >
                        <Stack spacing={1}>
                            <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }} color="common.white">
                                Cubit
                            </StyledTypography>
                            <StyledFooterLink href="https://www.cubit.no/" underline="always">
                                {t('about_us', 'About us')}
                            </StyledFooterLink>
                            {/* <StyledFooterLink href="#" underline="always">
                                {t('contact_us', 'Contact us')}
                            </StyledFooterLink> */}
                        </Stack>
                        {/* <Stack>
                            <Stack spacing={1}>
                                <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }} color="common.white">
                                    {t('about_this_page', 'About this page')}
                                </StyledTypography>
                                <StyledFooterLink href="#" underline="always">
                                    {t('security_and_privacy', 'Securty & privacy')}
                                </StyledFooterLink>
                                <StyledFooterLink href="#" underline="always">
                                    {t('accessibility_statement', 'Accessibility statement')}
                                </StyledFooterLink>
                            </Stack>
                        </Stack> */}
                        <Stack spacing={1}>
                            <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }} color="common.white">
                                {t('language', 'Language')}
                            </StyledTypography>
                            <FooterTextButton
                                onClick={() => {
                                    changeLanguage('en')
                                }}
                            >
                                <StyledTypography>{t('language_en', 'English')}</StyledTypography>
                            </FooterTextButton>
                            <FooterTextButton
                                onClick={() => {
                                    changeLanguage('nb')
                                }}
                            >
                                <StyledTypography>{t('language_nb', 'Norwegian Bokmål')}</StyledTypography>
                            </FooterTextButton>
                        </Stack>
                    </Stack>
                    <Stack
                        spacing={1}
                        sx={{ paddingTop: { xs: theme.spacing(5), lg: theme.spacing(2) }, maxWidth: theme.spacing(40) }}
                    >
                        <StyledTypography sx={{ fontFamily: 'Tomato Grotesk' }} color="common.white">
                            {t('why_koti', 'Why “Koti”?')}
                        </StyledTypography>
                        <StyledTypography>{t('name_koti', 'Koti is the kvenish word for “house”.')}</StyledTypography>
                    </Stack>
                    {isMobile && (
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderTop: `solid 2px ${theme.palette.common.white}`,
                                paddingTop: theme.spacing(2),
                                marginTop: theme.spacing(2),
                            }}
                            item
                            xs={12}
                        >
                            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                <StyledTypography color="common.white" sx={{ textWrap: 'nowrap' }}>
                                    {t('run_by', 'Run by')}
                                </StyledTypography>
                                <Box
                                    component="img"
                                    alignSelf="center"
                                    sx={{
                                        maxWidth: theme.spacing(10),
                                        maxHeight: theme.spacing(5),
                                        pl: theme.spacing(1),
                                        color: 'fff',
                                        width: { xs: 500, sm: 300, md: 500, lg: 700 },
                                    }}
                                    alt="Cubit logo with company name"
                                    src={'https://cubitblobstorage.blob.core.windows.net/public-images/Cubit_logo.svg'}
                                />
                            </Grid>
                            <Grid>
                                {/* <Link sx={{ pr: theme.spacing(1) }} href="#">
                                    <FacebookIcon sx={{ color: theme.palette.common.white }} />
                                </Link> */}
                                <Link href="#">
                                    <LinkedInIcon sx={{ color: theme.palette.common.white }} />
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs />
        </Grid>
    )
}

export default HomeFooter
